import {
  useLocation,
  createRoutesFromChildren,
  useNavigationType,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import React from "react";
export const sentryInit = () =>
  Sentry.init({
    dsn: import.meta.env.VITE_VERCEL_SENTRY_DSN,
    /*  debug: true, */ // not recommended for production because it can expose sensitive data and for performance reasons
    environment: import.meta.env.VITE_VERCEL_ENVIRONMENT || "development",
    release: import.meta.env.VITE_VERCEL_RELEASE,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    beforeSend: (event, hint) => {
      // controller for 404 errors in axios requests to be excluded from being reported to Sentry
      if (
        hint &&
        hint.originalException &&
        hint.originalException.name === "AxiosError"
      ) {
        const axiosResponse = hint.originalException.response;
        // Check if the response status is 404
        if (
          (axiosResponse && axiosResponse.status === 404) ||
          axiosResponse.status === 403
        ) {
          // Exclude 404 errors from being reported to Sentry
          return null;
        }
      }

      return event;
    },

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  });
