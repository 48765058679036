import { Box, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useEditStatusTransaction from "./hooks/useEditStatusTransaction";

const statuses = [
  "Pending",
  "Approved",
  "Rejected",
  "Ongoing",
  "Completed",
  "Cancelled"
];

export const EditStatusTransaction = ({ transactionID, updateTransactionStatus }) => {
  const { anchorEl, open, handleClick, handleMenuItemClick, handleClose } = useEditStatusTransaction(transactionID, updateTransactionStatus);

  return (
    <Box>
      <MoreVertIcon
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {statuses.map((status) => (
          <MenuItem key={status} onClick={() => handleMenuItemClick(status)}>
            {status}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
