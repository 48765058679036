import { useState } from 'react';
import axiosInstance from '../../../../axios/axiosInstance';
import { useShowAlert } from "../../../../hooks/useShowAlert";
const useEditStatusTransaction = (transactionID, updateTransactionStatus) => {
  const { showAlert } = useShowAlert();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (status) => {
    axiosInstance.put(`/admin/transactions/${transactionID}/status`, { status })
      .then(() => {
        showAlert("success", "Status Updated Successfully");
        updateTransactionStatus(transactionID, status);// update status in the table after updating status without refreshing the page
      })
      .catch((error) => {
        showAlert("error", error.response.data.error);
      });
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    open,
    handleClick,
    handleMenuItemClick,
    handleClose,
  };
};

export default useEditStatusTransaction;
