import { Grid,  } from "@mui/material";
import { useEffect, useState } from "react";
import {  useParams } from "react-router-dom";
import { SkeletonLoadingOpiningListProducts } from "../general/loading/SkeletonLoadingOpiningListProducts";
import axiosInstance from "../../axios/axiosInstance";
import { ProductItem } from "../general/ProductItem";
const OpenListing = () => {
  const {userId} = useParams()
  const [openListing, setOpenListing] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);

  // fetch open listing products by user id
  useEffect(() => {
    setLoadingProducts(true);
    const getOpenListing = async () => {
      try {
        const response = await axiosInstance.get(
          `/products/user/${userId}/available`
        );
        setOpenListing(response.data.availableProducts);
      } catch (_) {/**/ } finally {
        setLoadingProducts(false);
      }
    };
    getOpenListing();
  }, [userId]);
  return (
    <>
      <Grid container spacing={2} sx={{ pt: 1 }}>
        {loadingProducts ? (
          <SkeletonLoadingOpiningListProducts number={3} />
        ) : (
          openListing.map((product) => {
            return (
             <Grid item xs={12} md={4} key={product._id}>
                <ProductItem product={product}  />
              </Grid>
             
            );
          })
        )}
      </Grid>
    </>
  );
};
export default OpenListing;