import { setOpenDialog } from "../../../redux/slices/userDetailsDialogSlice";
// Function to show dialog if user does not have required information
export const showPopup = (user, dispatch) => {
    if (user.role === "User") {
        // If user role is user, show dialog; if admin role is admin, don't show dialog
        if (
            !user?.firstName ||
            !user?.lastName ||
            !user?.phoneNumber ||
            !user.location
        ) {
            dispatch(setOpenDialog(true));
        }
    }
};
