import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const useVerifyAcountValidation = () => {
  const { t } = useTranslation("verifyAcount");
  const verifyAcountValidationSchema = Yup.object().shape({
    code: Yup.string().required(t("formErrors.code.required")),
  });
  return { verifyAcountValidationSchema };
};

