import { Outlet } from 'react-router-dom';
import MiniDrawer from '../components/general/MiniDrawer';
import { Box } from '@mui/material';
import { useScrollToTop } from '../hooks/useScrollToTop';

export const Admin = () => {
   // scroll to top when route changes
   useScrollToTop();
  return (
    <Box sx={{ display: 'flex', width: '100%',overflow:"hidden" }}>
      <MiniDrawer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          mt: "100px",
          width: '100%',
          overflow: 'hidden',
        }}

      >
        <Outlet />
      </Box>
    </Box>
  );
};
