import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../../redux/slices/AuthUser';
import { useTranslation } from 'react-i18next';
import { List } from './steps';


export const Referrals = () => {
    const { t } = useTranslation("home");
    // get user from redux store
    const authUser = useSelector(getUser);
    const navigate = useNavigate();
    // go to refer a friend page
    const goToReferAFriend = () => {
        if (authUser?.user) {
            navigate("/send-referral-code")
        } else {
            navigate("/login")
        }
    };
    return (
        <Box sx={{ backgroundColor: "text.main", py: 5 }}>
            <Container>
                <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "primary.main", fontSize: ["20px", "45px"], textAlign: "center" }}>
                    {t("Referrals.title")}
                </Typography>
                <Typography variant="body1" component="p" sx={{ color: "secondary.main", fontFamily: "Poppins", fontWeight: "500", fontSize: "20px", textAlign: "center", marginTop: "20px" }}>
                    {t("Referrals.description")}
                </Typography>

                <Grid container my={8} sx={{ alignItems: "center", justifyContent: "center" }}>
                    {List.map((item, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <img src={item.img}
                                    alt="listStyle"
                                    style={{ width: "140px", height: "140px" }} />
                            </Box>
                            <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "secondary.main", fontSize: ["20px",], textAlign: "center", marginTop: "20px" }}>
                                {t(item.title)}
                            </Typography>
                            <Typography variant="body1" component="p" sx={{ color: "secondary.main", fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", textAlign: "center", marginTop: "20px" }}>
                                {t(item.description)}
                            </Typography>

                        </Grid>
                    ))}
                </Grid>
                <Button variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", padding: "10px 30px", borderRadius: "10px", display: "block", margin: "auto", textTransform: "capitalize" }} onClick={goToReferAFriend}>
                    {t("Referrals.btn")}
                </Button>
            </Container>
        </Box>
    )
}
