import NotificationList from "../NotificationList"
export const WatchList = () => {
    return (
        <NotificationList
            type="product available"
            limit={10}
            noMoreText="watchlists.noMore"
            fetchUrl="/notifications"
        />
    )
}