import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosInstance';
import { useShowAlert } from '../../../hooks/useShowAlert';
const useSendTokenToContact = () => {
  const { showAlert } = useShowAlert();
  const navigate = useNavigate();

  // Send token to email
  const sendTokenToEmail = async (email) => {
    try {
      const response = await axiosInstance.post("/user/generate-email-token", { email });
      showAlert("success", response.data.message);
      navigate(`/verification/email`);
    } catch (err) {
      showAlert("error", err.response?.data?.message);
    }
  };

  // Send token to phone number
  const sendTokenToPhoneNumber = async (phoneNumber) => {
    try {
      const response = await axiosInstance.post("/user/generate-phone-token", { phoneNumber });
      showAlert("success", response.data.message);
      navigate("/verification/phone");
    } catch (err) {
      showAlert("error", err.response?.data?.message);
    }
  };

  return { sendTokenToEmail, sendTokenToPhoneNumber };
};

export default useSendTokenToContact;
