import { createSlice } from "@reduxjs/toolkit";

const filterSlice = createSlice({
  name: "filter",
  initialState: {
    filterKeys: {
      category: null,
      priceMin: null,
      priceMax: null,
      startDate: null,
      endDate: null,
      condition: "",
      name: null,
      location: null,
    },
  },
  reducers: {
    setActiveCategory: (state, { payload }) => {
      state.filterKeys.category = payload;
    },
    setFilterKeys: (state, { payload }) => {
      state.filterKeys = {
        ...state.filterKeys,
        priceMin: payload.priceMin,
        priceMax: payload.priceMax,
        condition: payload.condition,
        startDate: payload.startDate,
        endDate: payload.endDate,
        location: payload.location,
      };
    },
    setSearchByName: (state, { payload }) => {
      state.filterKeys.name = payload;
    },
  },
});

export const { setActiveCategory, setFilterKeys,setSearchByName } = filterSlice.actions;

export default filterSlice.reducer;
