import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../axios/axiosInstance";
import { setOpenDialog } from "../../../../redux/slices/userDetailsDialogSlice";
import { fetchUser, getUser } from "../../../../redux/slices/AuthUser";
import { useShowAlert } from "../../../../hooks/useShowAlert";

const useSubmitData = () => {
  const { showAlert } = useShowAlert();
  const authUser = useSelector(getUser);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    dispatch(setOpenDialog(false));
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    axiosInstance.put(`/user/${authUser?.id}`, values).then((res) => {
      showAlert("success", res.data.message);
      handleClose();
      dispatch(fetchUser());
    }).catch((err) => {
      showAlert("error", err.response?.data?.error || "An error occurred");
    }).finally(() => {
      setLoading(false);
    })
  };

  return {
    loading,
    handleSubmit,
    handleClose,
  };
};

export default useSubmitData;
