import { useDispatch } from "react-redux";
import { fetchUser } from "../../../redux/slices/AuthUser";
import axiosInstance from "../../../axios/axiosInstance"; 
import { useShowAlert } from "../../../hooks/useShowAlert";
import { useState } from "react";
import { useParams } from "react-router-dom";
export const useEditProfile = () => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { showAlert } = useShowAlert()
    const { userId } = useParams();
    const handleSubmit = (values) => {
        setLoading(true)
        axiosInstance.put(`/user/${userId}`, values
        ).then((res) => {
            showAlert("success", res.data.message)
            // fetch new user data after update
            dispatch(fetchUser());

        }).catch(err => {
            showAlert("error", err.response.data.error)

        }).finally(() => {
            setLoading(false)
        })

    };
    return { handleSubmit, loading }
}
