import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Box } from "@mui/material";
import { SliderSkeleton } from '../general/loading/SliderSkeleton';

export const Slider = ({ images }) => {
  return (
    <>
      {
        images?.length > 0 ? (
          <Box sx={{ boxShadow: " 0px 0px 1px 1px #9481812d", }}>
            {
              images.length === 1 ? <img src={images[0]} alt="item image" loading="lazy" style={{ width: "100%", height: "250px", objectFit: "contain" }} /> : (
                <Swiper
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                  loop={true}
                  navigation={true}
                  pagination={{ clickable: true }}
                  autoplay={{ delay: 2500, disableOnInteraction: true }}

                >

                  {
                    images.map((image, index) => {
                      return (
                        <SwiperSlide key={index} style={{ height: "250px" }}>
                          <img src={image} alt="client" loading="lazy" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                        </SwiperSlide>
                      );
                    })
                  }

                </Swiper>
              )
            }

          </Box>
        ) : (
          <SliderSkeleton />
        )
      }
    </>

  );
};