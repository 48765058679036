import {Divider, Stack } from '@mui/material'

import FacebookLoginButton from './facebook/LoginWithFacebook';
import GoogleLoginButton from './google/LoginWithGoogle';
import { useTranslation } from 'react-i18next';
export const LoginWithSocial = () => {
    const { t } = useTranslation("login");
    return (
        <div>
            <Divider sx={{
                mt: 3,
                color: "text.main",
                textTransform: "uppercase",
                fontWeight: "bold",
                "&::before": {
                    borderColor: "text.main",
                },
                "&::after": {
                    borderColor: "text.main",
                }
            }} >
                {t("or")}
            </Divider>
            <Stack spacing={1} sx={{ mt: 2, alignItems: "center", justifyContent: "center" }}>
                <GoogleLoginButton />
                <FacebookLoginButton />
            </Stack>
           
        </div>
    )
}
