import { Accordion, AccordionDetails, AccordionSummary, Container, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
const faqItems = [
    {
        id: 'panel1',
        summary: 'faqs.1.question',
        details: 'faqs.1.answer',
    },
    {
        id: 'panel2',
        summary: 'faqs.2.question',
        details: 'faqs.2.answer',
    },
    {
        id: 'panel3',
        summary: 'faqs.3.question',
        details: 'faqs.3.answer',
    },
    {
        id: 'panel4',
        summary: 'faqs.4.question',
        details: 'faqs.4.answer',

    },
    {
        id: 'panel5',
        summary: 'faqs.5.question',
        details: 'faqs.5.answer',

    }
];

export const Faq = () => {
    
    const { t } = useTranslation('faq');
    return (
        <Container maxWidth="md" sx={{ my: 8 }}>
            <Typography variant="h4" align="center" sx={{ color: 'primary.main', fontFamily: 'Poppins-Bold', fontSize: ["18px", "35px"] }}>
                {t('title')}
            </Typography>

            <Stack spacing={2} sx={{ my: 4 }} >
                {faqItems.map((item) => (
                    <Accordion key={item.id} defaultExpanded={item.defaultExpanded || false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                        >
                            {t(item.summary)}
                        </AccordionSummary>
                        <AccordionDetails>{t(item.details)}</AccordionDetails>
                    </Accordion>
                ))}
            </Stack>
        </Container>
    );
};
