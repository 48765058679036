import { useEffect, useState } from "react"
import axiosInstance from "../../../axios/axiosInstance"
import { Grid, Stack } from "@mui/material"
import { ProductCard } from "./ProductCard"
import { SkeletonLoadingProducts } from "../../general/loading/SkeletonLoadingProducts"
import { useTranslation } from "react-i18next"
export const FutureRents = () => {
    const { t } = useTranslation("profile")
    const [futureRents, setFutureRents] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const getYourRents = async () => {
            // get your rents
            const futureRents = await axiosInstance.get(`/transactions/user/future-rents`)
            setFutureRents(futureRents.data.futureTransactions)
            setLoading(false)
        }
        getYourRents()

    }, [])
    return (
        <Grid container spacing={2}>
            {

                loading ? ["", "", "", "", "", ""].map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index} >
                        <SkeletonLoadingProducts />
                    </Grid>
                )) : futureRents.length > 0 ? (
                    futureRents.map((rent) => (
                        <Grid item key={rent._id} xs={12} sm={12} md={6}>
                            <ProductCard transaction={rent} />
                        </Grid>
                    ))
                ) : (
                    <Stack direction="row" sx={{ justifyContent: "center", color: "primary.main", width: "100%" }} >
                        {t("not-found")}
                    </Stack>
                )
            }
        </Grid>
    )
}
