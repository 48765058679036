export const getStatusColor = (status) => {
    switch (status) {
        case "Pending":
            return "orange";
        case "Approved":
            return "green";
        case "Rejected":
            return "red";
        case "Ongoing":
            return "blue";
        case "Completed":
            return "gray";
        case "Cancelled":
            return "red";
        default:
            return "black";
    }
};
