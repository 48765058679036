import { createSlice } from "@reduxjs/toolkit";

const emailPopupSlice = createSlice({
  name: "emailPopup",
  initialState: {
    openPopup: false,
    userType: null
  },
  reducers: {
    setOpenPopup: (state, { payload }) => {
      state.openPopup = payload.openPopup;
      state.userType = payload.userType;
    },
    setClosePopup: (state) => {
      state.openPopup = false;
      state.userType = null;
    },
  },
});

export const { setOpenPopup, setClosePopup } = emailPopupSlice.actions;

export default emailPopupSlice.reducer;
