// hooks/useTokenExpiration.ts
import { useEffect } from "react";
import { useSelector } from "react-redux";
import dayjs from 'dayjs';
import { getUser } from "../redux/slices/AuthUser";
import useLogout from "./useLogOut";

const useTokenExpiration = () => {
  const logout = useLogout();
  const { exp, id } = useSelector(getUser);

  useEffect(() => {
    const currentTime = dayjs();
    const expTime = dayjs.unix(exp);

    if (id) {
      if (expTime.isBefore(currentTime)) {
        logout("The session expired, try login again", true);
      } else {
        // set timeout to logout user after token expired
        const timeout = expTime.diff(currentTime, 'second');
        setTimeout(() => {
          logout("The session expired, try login again", true);
        }, timeout * 1000);
      }
    }
  }, [exp, id, logout]);
};

export default useTokenExpiration;
