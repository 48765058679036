import { Stack, } from '@mui/material'
import { UserAvatar } from '../general/UserAvatar';
import { AboutUser } from './AboutUser';
import { getUser } from '../../redux/slices/AuthUser';
import { useSelector } from 'react-redux';
export const UserInfo = () => {
    const authUser = useSelector(getUser); // get user from redux 
    return (
        <Stack spacing={5}>
            <UserAvatar user={authUser?.user} />
            <AboutUser user={authUser?.user} />
        </Stack>
    )
}
