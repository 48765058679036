import axiosInstance from '../../../axios/axiosInstance';
import { useContext } from 'react';
import { NotificationContext } from '../../../context';
import { useShowAlert } from '../../../hooks/useShowAlert';

const useSetNotificationAsRead = () => {
    const { showAlert } = useShowAlert();
    const { handleUpdateNotifications } = useContext(NotificationContext);
    const setNotificationAsRead = (notificationId) => {
        axiosInstance
            .patch(`/notifications/markAsRead`, {
                ids: [notificationId]
            })
            .then((res) => {
                showAlert("success", res.data.message);
                handleUpdateNotifications(); // update notification list
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
            });
    };

    return setNotificationAsRead;
};

export default useSetNotificationAsRead;
