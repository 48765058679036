import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const usePaymentValidation = () => {
    const { t } = useTranslation("payment");
    const validationSchema = Yup.object().shape({
        paymentMethod: Yup.string().required(t("formErrors.paymentMethod")),
        acceptPrivacyPolicy: Yup.boolean().oneOf([true], t("formErrors.acceptPrivacyPolicy")),
    });
    return validationSchema;
}
