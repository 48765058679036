import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export const DontHaveAcount = () => {
    const { t } = useTranslation("login");
    const theme = useTheme();
  return (
   
    <Box sx={{ textAlign: "center", mt: 3, color: "white" }}>
    { /*eslint-disable-next-line react/no-unescaped-entities */}
   {t("dont-have")}{" "}
    <Link
        to="/signup"
        style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
    >
       {t("create-account")}
    </Link>
</Box>
  )
}
