import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useAddProductValidation = () => {
  const { t } = useTranslation("add-product");
  const addProductValidationSchema = yup.object({
    category: yup.string().required(t("formErrors.category.required")),
    name: yup.string().required(t("formErrors.name.required")),
    price: yup.number().required(t("formErrors.price.required")).positive(t("formErrors.price.positive")),
    per: yup.string().required(t("formErrors.per.required")),
    description: yup.string().required(t("formErrors.description.required")),
    condition: yup.string().required(t("formErrors.condition.required")),
    pictures: yup.array().min(1, t("formErrors.pictures.min")).test("mainImage", t("formErrors.pictures.mainImage"), (value) => value[0] !== null),
  });
  return { addProductValidationSchema };
};
