import { configureStore, combineReducers } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

import filterReducer from "./slices/filterSlice";
import openDialogReducer from "./slices/userDetailsDialogSlice";
import alertDetailsReducer from "./slices/AlertDetailsSlice"
import persistedAuthUserReducer from "./slices/AuthUser";
import emailPopupReducer from "./slices/EmailPopup";
const rootReducer = combineReducers({
  filter: filterReducer,
  openDialog: openDialogReducer,
  alertDetails: alertDetailsReducer,
  authUser: persistedAuthUserReducer,
  emailPopup: emailPopupReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
    enhancers: (defaultEnhancers) => {
      return [...defaultEnhancers, sentryReduxEnhancer];
    },
});

export const persistor = persistStore(store);
export default store;
