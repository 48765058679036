/* import { Testimonials } from "../components/Home/Testimonials/Testimonials" */
import { EarnMoney } from "../components/about/EarnMoney"
import { WhoWe } from "../components/about/WhoWe"

export const AboutUs = () => {
  return (
   <>
      <WhoWe/>
      <EarnMoney/>
      {/* <Testimonials/> */}

   </>
  )
}

