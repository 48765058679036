import { createSlice } from '@reduxjs/toolkit';
const alertDetails = createSlice({
  name: 'alertDetails',
  initialState: { type: '', message: '', },
  reducers: {
    setAlertDetails: (state, { payload }) => {
        state.type = payload.type;
        state.message = payload.message;
    },
  },
});

export const { setAlertDetails } = alertDetails.actions;
export default alertDetails.reducer;

