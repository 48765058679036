import { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
export const Reports = () => {
    const [loading, setLoading] = useState(true);
    const iframeRef = useRef(null);

    useEffect(() => {
        const handleLoad = () => {
            setLoading(false);
        };

        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', handleLoad);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handleLoad);
            }
        };
    }, []);

    return (
        <Box sx={{ height: "80vh", position: "relative" }} className="custom-scrollbar">
            {loading && (
                <Stack
                    direction="row"
                    spacing={5}
                    sx={{
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255)',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                    <Typography variant="h6"> Loading Reports ...</Typography>
                </Stack>
            )}
            <iframe
                ref={iframeRef}
                src="https://metabase.directrent.com/public/dashboard/bc8f8f18-cc47-49bf-91b6-afbab2d6c927"
                width="100%"
                height="100%"
                style={{ border: "none" }}
            ></iframe>
        </Box>
    );
};
