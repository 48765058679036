import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import bugImage from "./assets/images/bug.png";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const imageStyle = {
  width: "100%",
  height: "100%",
};

function ErrorFallback() {
  const { t } = useTranslation("error");
  return (
    <Box sx={{ width: "100%", height: "100vh", backgroundColor: "text.main" }}>
      <Container maxWidth="md" sx={{ textAlign: "center" }}>
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100vh", flexDirection: ["column", "row"], gap: ["30px", "80px"] }}>
          <Box>
            <Box sx={{ width: ["100%"], height: ["200px", "300px"], display: "flex", justifyContent: "center" }}>
              <img
                src={bugImage}
                alt="bug"
                loading="lazy"
                style={imageStyle}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="h4" sx={{ color: "primary.main", fontFamily: "Poppins-Bold", textTransform: "uppercase" }}>
              {t("error")}
            </Typography>
            <Typography variant="body1" sx={{ color: "text.secondary", py: 2 }}>
              {t("description")}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
              onClick={() => window.location.href = "/"}
            >
              {t("back")}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
    /*   onError={(error, errorInfo) => {
        console.log("Logging error:", error, errorInfo);
      }} */
    >
      {children}
    </ReactErrorBoundary>
  );
};


