import { Field } from 'formik';
import { InputAdornment } from '@mui/material';
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
 const CustomTextField = styled(TextField)({
    width:"100%",
    color: "secondary.main",
    '& .MuiOutlinedInput-root': { // To remove outline
        borderRadius: "10px",
        backgroundColor: "white",
        color: "secondary.main",
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
            fontSize: '12px',
        },
    },
    '& .MuiFormLabel-root': { // To change label color
        color: 'black', // Replace 'desiredColor' with the color you want
    },
});
const CustomFormikField = ({ name, label, type = 'text', icon, size = 'medium', rows = 1, ...props }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <CustomTextField
          {...field}
          placeholder={label}
          type={type}
          size={size}
          rows={rows}
          multiline={rows > 1}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          InputProps={{
            startAdornment: icon && (
              <InputAdornment position="start">
                {icon}
              </InputAdornment>
            ),
          }}
          {...props}
        />
      )}
    </Field>
  );
};

export default CustomFormikField;