import axios from "axios";
import i18next from "i18next";
const baseURL = import.meta.env.VITE_VERCEL_API_URL;
const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.defaults.headers.post["Content-Type"] = "application/json"; // set content type to json

axiosInstance.interceptors.request.use( // interceptor to add token and language to every request
  function (config) {
    const token = localStorage.getItem("token");
    config.headers.Authorization = token; // add token to headers
    // get language from i18next
    const language = i18next.language;
    // Add language to headers
    config.headers['Accept-Language'] = language;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
