import { Avatar, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { getUser } from "../../redux/slices/AuthUser";
import { useSelector } from "react-redux";
export const MessageItem = ({ message, theme }) => {
    const loggedUserId = useSelector(getUser)?.id;
    // Format the date
    const messageDate = new Date(message.createdAt).toLocaleString();

    return (
        <Stack
            key={message._id}
            sx={{
                backgroundColor: "text.main",
                borderRadius: "10px",
                p: 2,
                position: "relative",
            }}
        >
            {/* Header with sender info and date */}
            <Stack
                direction="row"
                sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%"
                }}
            >
                <Stack direction="row" sx={{ alignItems: "center" }}>
                    <Avatar
                        sx={{ textDecoration: "none", width: "30px", height: "30px", mr: 1 }}
                    >
                        {message.sender.profilePicture ? (
                            <img
                                src={message.sender.profilePicture}
                                alt={message?.sender?.displayName}
                                loading="lazy"
                                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                            />
                        ) : (
                            message?.sender?.displayName?.[0].toUpperCase()
                        )}
                    </Avatar>
                    {loggedUserId !== message.sender._id ? (
                        <Link
                            to={`/contactWithUser/${message.sender._id}/${message.sender.displayName}`}
                            style={{
                                textDecoration: "none",
                                color: theme.palette.mode === "dark" ? "white" : theme.palette.primary.main,
                                fontSize: "14px",
                                fontWeight: "bold"
                            }}
                        >
                            {message?.sender?.displayName}
                        </Link>
                    ) : "You"}
                </Stack>
                <Typography variant="caption" color="text.secondary">
                    {messageDate}
                </Typography>
            </Stack>

            {/* Message content */}
            <Stack sx={{ pl: 2, mt: 2 }}>
                <Typography variant="body1">{message.content}</Typography>
            </Stack>

            {/* Read status and receiver info */}
            <Stack
                direction="row"
                sx={{
                    justifyContent: "space-between",
                    mt: 1,
                    pt: 1,
                    borderTop: "1px solid rgba(0,0,0,0.1)"
                }}
            >
                <Typography variant="caption" color="text.secondary">
                    To:{"   "} {loggedUserId !== message?.receiver?._id ?
                        <Link
                            to={`/contactWithUser/${message.receiver._id}/${message.receiver.displayName}`}
                            style={{
                                textDecoration: "none",
                                color: theme.palette.mode === "dark" ? "white" : theme.palette.primary.main,
                                fontSize: "inherit",
                                fontWeight: "bold"
                            }}
                        >
                            {message.receiver.displayName}
                        </Link>
                        : "You"}
                </Typography>
             
            </Stack>
        </Stack>
    );
};
