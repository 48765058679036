import { Box, Button, Stack, FormLabel } from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

const RenderAdditionalImages = ({ handleImageChange, imageLoading, push, remove }) => {
  const { t } = useTranslation("add-product");
  const { setFieldValue, values } = useFormikContext(); // Access Formik context

  return (
    <Stack direction="row" sx={{ mt: 2, flexWrap: "wrap" }} spacing={1}>
      {values.pictures.slice(1).map((image, index) => (
        <Stack key={index} spacing={2} sx={{ mx: "3px" }}>
          <Box>
            <input
              type="file"
              id={`pictures.${index + 1}`}
              onChange={(event) => handleImageChange(event, index + 1, setFieldValue)}
              style={{ display: "none" }}
            />
            <FormLabel htmlFor={`pictures.${index + 1}`}>
              <Box sx={{
                display: "flex", alignItems: "center", justifyContent: "center",
                border: "1px dashed #ccc", borderRadius: 4, cursor: "pointer",
                width: "120px", height: "100px", overflow: "hidden"
              }}>
                {imageLoading[index + 1] ? (
                  t("loading")
                ) : (
                  image ? (
                    <img src={image} alt="additional" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                  ) : (
                    t("upload")
                  )
                )}
              </Box>
            </FormLabel>
          </Box>
          <Button variant="outlined" sx={{ color: "primary.main", width: "85px" }} onClick={() => remove(index + 1)}>
            {t("Remove")}
          </Button>
        </Stack>
      ))}
      {values.pictures.length < 6 && (
        <Box onClick={() => push(null)} sx={{
          display: "flex", alignItems: "center", justifyContent: "center",
          border: "1px dashed #ccc", borderRadius: 4, cursor: "pointer",
          width: "85px", height: "100px"
        }}>
          <AddPhotoAlternateIcon />
        </Box>
      )}
    </Stack>
  );
};

export default RenderAdditionalImages;
