import NotificationList from "../NotificationList"
export const Unread = () => {

  return(
    <NotificationList
      type="message"
      limit={10}
      noMoreText="unread.noMore"
      fetchUrl="/notifications"
    />
  )
  

}