// hooks/usePathHistory.ts
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePathHistory = () => {
  const location = useLocation();

  useEffect(() => {
    const pathHistory = JSON.parse(localStorage.getItem("pathHistory")) || [];
    const currentPath = location.pathname;
    if (pathHistory.length === 0 || currentPath !== pathHistory[pathHistory.length - 1]) {
      const newHistory = [...pathHistory, currentPath].slice(-2); // Keep only the last two paths
      localStorage.setItem("pathHistory", JSON.stringify(newHistory));
    }
  }, [location.pathname]);
};

export default usePathHistory;
