import { Box, Stack, Typography, FormHelperText } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import RenderMainImageInput from "./RenderMainImageInput";
import RenderAdditionalImages from "./RenderAdditionalImages";
import useProductForm from "../hook/useProductForm";

const UploadImages = () => {
  const { t } = useTranslation("add-product");
  const { errors, touched } = useFormikContext(); // Access Formik context
  const { handleImageChange, imageLoading } = useProductForm(); // Use custom hook

  return (
    <Box >
      <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center", position: "relative",backgroundColor: "rgb(247,247,247)", borderRadius: "20px", p: 2  }}>
        <RenderMainImageInput handleImageChange={handleImageChange} imageLoading={imageLoading} />
      </Stack>
      <Typography variant="h6" sx={{ mt: 3, color: "primary.main", fontFamily: "Poppins-Bold" }}>
        {t("formFields.uploadMore")}
      </Typography>
      <FieldArray name="pictures">
        {({ push, remove }) => (
          <>
            <RenderAdditionalImages
              handleImageChange={handleImageChange}
              imageLoading={imageLoading}
              push={push}
              remove={remove}
            />
            {touched.pictures && errors.pictures && (
              <FormHelperText sx={{ color: "red", pt: 1 }}>
                {errors.pictures}
              </FormHelperText>
            )}
          </>
        )}
      </FieldArray>
    </Box>
  );
};

export default UploadImages;
