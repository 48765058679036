import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { TableRowUser } from "./TableRowUser";
export const UsersTable = ({ items }) => {
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Email
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Phone
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Transactions
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Edit
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Del / Res
              </TableCell>
              <TableCell
                align="center"
                sx={{ fontSize: ["13px", "16px"], fontWeight: "bold" }}
              >
                Block
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              ? items.map((user) => {
                return (
                  <TableRowUser user={user} key={user._id} />
                );
              })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
