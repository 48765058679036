import { Skeleton, Stack } from '@mui/material'
export const MessagesSkeleton = () => {
    return (

        <Stack
            sx={{
                backgroundColor: "text.main",
                borderRadius: "10px",
                p: 2,
                position: "relative",
                width: "100%",
            }}
        >

            <Stack direction="row" sx={{ alignItems: "center" }} spacing={2}>
                <Skeleton variant="circular" sx={{ width: 40, height: 40 }} />
                <Skeleton variant="text" sx={{ width: 100, height: 15 }} />
            </Stack>
            <Stack sx={{ pl: 2, mt: 2 }}>
                <Skeleton variant="text" sx={{ width: "100%", height: "30px" }} />
            </Stack>
        </Stack>


    )
}
