import { Box, Container, Stack, Typography } from '@mui/material'
import { SaveAdress } from '../components/Settings/Saved-Adress/SaveAdress'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
export const SaveAddress = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    // go back to the previous page
    navigate(-1);
  }
  return (
    <Container sx={{ py: 8 }}>
      <Stack spacing={2} direction="row" alignItems="center" mb={8} sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", top: "0px", left: "0px" }}>
          <ArrowBackIcon sx={{ color: "primary.main", fontSize: "30px", cursor: "pointer" }} onClick={handleBack} />
        </Box>
        <Typography variant="h4" component="h3" align="center" sx={{ color: "primary.main", fontWeight: "bold", width: "100%" }}>
          Save Address
        </Typography>
      </Stack>
      <SaveAdress />
    </Container>
  )
}
