import { useGetCategories } from "../hooks/useGetCategories";
import { useTranslation } from "react-i18next";

export const useSelectBoxOptions = () => {
  const { t } = useTranslation("selectBox-options");
  // Time periods options
  const TIME_PERIODS_OPTIONS = [
    { label: t("timePeriods.day"), value: "Day" },
    { label: t("timePeriods.week"), value: "Week" },
    { label: t("timePeriods.month"), value: "Month" },
    { label: t("timePeriods.year"), value: "Year" },
  ];
  //  Conditions options
  const CONDITIONS_OPTIONS = [
    { label: t("conditions.new"), value: "New" },
    { label: t("conditions.used"), value: "Used" },
  ];
  // Available options
  const AVAILABLE_OPTIONS = [
    { label: t("available.open"), value: "Open" },
    { label: t("available.close"), value: "Close" },
  ];
  return { TIME_PERIODS_OPTIONS, CONDITIONS_OPTIONS, AVAILABLE_OPTIONS };
};

// Categories options
export const useGetCategoriesOptions = () => {
  const {categories} = useGetCategories();
  return categories?.map((category) => ({
    value: category._id,
    label: category.name,
  }));
};
