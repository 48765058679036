import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { ChangeLanguage } from './ChangeLanguage'
import { useTranslation } from 'react-i18next'
export const Main = () => {
  const { t } = useTranslation("header");
  return (
    <Stack sx={{ justifyContent: "end", alignItems: "center", width: "100%", px: 2, py: 1, position: "relative", zIndex: 300, flexDirection: ["row"] }}>
      <Button sx={{pl:0, color: "secondary.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: ["10px", "15px"] }} component={Link} to={`/faq`}>
        {t("faq")}
      </Button>
      <Button sx={{pl:0, color: "secondary.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: ["10px", "15px"] }} component={Link} to={`/terms-of-service`}>
        {t("Terms of Service")}
      </Button>
      <ChangeLanguage />
    </Stack>
  )
}
