import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const usePay = ({ transactionID }) => {
    const navigate = useNavigate()
    const { showAlert } = useShowAlert();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        setSubmitting(true);

        if (values.paymentMethod === "Cash On Delivery") {
            axiosInstance.post("payments/create", { transactionID: transactionID })
                .then((response) => {
                    showAlert("success", response.data.message);
                    setSuccess(true);
                    navigate("/transactions")
                })
                .catch((error) => {
                    showAlert("error", error.response.data.error);
                })
                .finally(() => {
                    setLoading(false);
                    setSubmitting(false);
                });
        } else {
            axiosInstance.post("/payments/stripe-payment", { transactionId: transactionID })
                .then((response) => {
                    window.location.href = `${response.data.checkoutURL}`;
                })
                .catch((error) => {
                    showAlert("error", error.response.data.error);
                })
                .finally(() => {
                    setLoading(false);
                    setSubmitting(false);
                });
        }
    };

    return {
        handleSubmit,
        loading,
        success
    };
};