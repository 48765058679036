import { useDispatch, useSelector } from "react-redux";
import { setActiveCategory } from "../../../redux/slices/filterSlice";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { TableLoading } from "../../general/loading/TableLoading";
import { useGetCategories } from "../../../hooks/useGetCategories";
import { useTranslation } from "react-i18next"; 
const CategoryList = () => { 
  const { t } = useTranslation("shop");
  const {categories, loading} = useGetCategories();
  const dispatch = useDispatch();
  const activeCategory = useSelector(
    (state) => state.filter.filterKeys.category
  );
  const theme = useTheme();
  const handleItemClick = (category) => {
    dispatch(setActiveCategory(category));
  };

  return (
    <Box>
      {
         loading ? (
          <Stack spacing={2} pt={3}>
            <TableLoading number={10} />
          </Stack>
        ) : (
          <>
            <Typography
              component="div"
              variant="subtitle1"
              onClick={() => handleItemClick(null)}
              sx={{
                cursor: "pointer",
                transition: "color 0.3s, transform 0.3s ease-in-out",
                textTransform: "capitalize",
                marginTop: 4,
                marginBottom: 1,
                width: "fit-content",
                fontSize: ["14px", "14px", "16px"],

                color:
                  activeCategory === null ? theme.palette.primary.main : "inherit",
                fontWeight: activeCategory === null ? "bolder" : "normal",
                "&:hover": {
                  color: theme.palette.primary.main,
                  transform: 'scale(1.05)',
                },
              }}
            >
              {t("allCategories")}
            </Typography>
            {categories.map((category) => (
              <Typography
                key={category._id}
                component="div"
                variant="subtitle1"
                onClick={() => handleItemClick(category)}
                sx={{
                  cursor: "pointer",
                  transition: "color 0.3s, transform 0.3s ease-in-out",
                  marginBottom: 1,
                  width: "fit-content",
                  fontSize: "17px",
                  textTransform: "capitalize",
                  color:
                    activeCategory === category._id
                      ? theme.palette.primary.main
                      : theme.palette.secondary.main,
                  fontWeight: activeCategory === category ? "bolder" : "normal",
                  "&:hover": {
                    color: theme.palette.primary.main,
                    transform: 'scale(1.05)',
                  },
                }}
              >
                {category.name}
              </Typography>
            ))}
          </>
        )
      }
    </Box>
  );
};

export default CategoryList;

