import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useReviewValidation = () => {
    const { t } = useTranslation("comments-reviews");
    const validationSchema = yup.object({
        review: yup.string().required(t("review.add.formErrors.review.required")),
    });
    return {validationSchema};
};
