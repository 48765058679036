import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
export const LoginMethod = ({ loginMethod, setLoginMethod }) => {
    const { t } = useTranslation("login");
    return (
        <div>
            {
                loginMethod === "email" ? (
                    <Typography variant="body1" component="h6" onClick={() => setLoginMethod("phone")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer", }}>
                        {t("loginMethod.phone")}
                    </Typography>
                ) : (
                    <Typography variant="body1" component="h6" onClick={() => setLoginMethod("email")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer" }}>

                        {t("loginMethod.email")}
                    </Typography>
                )
            }
        </div>
    )
}
