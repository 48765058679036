import { Box, IconButton, Stack } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";
import { styled, useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const StyledInput = styled('input')({
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
});
export const Search = () => {
    const { t } = useTranslation("home");
    const navigate = useNavigate();
    // go to shop page
    const GoShopPage = () => {
        navigate("/shop");
    }
    const theme = useTheme();

    return (

        <Stack direction="row" justifyContent="center" alignItems="center"
            sx={{
                height: "60px",
                backgroundColor: "rgb(247, 247, 247)",
                borderRadius: "20px",
                px: 3,
                width: ["100%", "50%"],
                margin: "auto",
                cursor: "pointer",
                position: "relative",
                my: 3
            }}

        >
            {/*box absolute to click it when user want search open search Dialog */}
            <Box sx={{ position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", cursor: "pointer" }} onClick={GoShopPage}></Box>
            <IconButton
                aria-label="searchIcone"
                sx={{

                    color: "",
                }}
            >
                <SearchIcon aria-label="searchIcone" />
            </IconButton>
            <StyledInput value={t("categories.search-lable")} disabled aria-label="Search products" />
            <TuneIcon sx={{ color: theme.palette.primary.main }} />
        </Stack>

    )
}
