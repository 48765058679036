import { Box, Container, Typography, Grid } from '@mui/material';
import ProductDisplay from '../components/add-edit-comment-review/ProductDisplay';
import { useTranslation } from 'react-i18next';
import EditReviewForm from '../components/add-edit-comment-review/EditReviewForm';
export const EditReview = () => {
  const { t } = useTranslation("comments-reviews");

  return (
    <Box sx={{ pt: 8 }}>
      <Typography
        variant="h4"
        sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center', mb: 8 }}
      >
        {t("review.edit.title")}
      </Typography>
      <Box sx={{ backgroundColor: 'text.main', py: 5 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>
              <ProductDisplay />
            </Grid>
            <Grid item xs={12} sm={5}>
              <EditReviewForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
