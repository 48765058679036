import { LocalizationProvider } from "@mui/x-date-pickers";
import { Formik, Form } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
const BaseForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Form>{children}</Form>
      </LocalizationProvider>

    </Formik>
  );
};
export default BaseForm;