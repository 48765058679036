import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../../axios/axiosInstance'
import { useEffect, useState } from 'react'
import { ProductItem } from '../../general/ProductItem'
import { SkeletonLoadingProducts } from '../../general/loading/SkeletonLoadingProducts'
import { useTranslation } from 'react-i18next'
export const PopularProducts = () => {
    const { t } = useTranslation("home")
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axiosInstance.get(`/products/popular-products?page=${1}&limit=${6}`)
            .then(res => {
                setProducts(res.data.popularProducts)
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
    }, [])

    // navigate to popular products page
    const navigateToPopularProducts = () => {
        navigate('/popular-products')
    }

    return (
        products.length > 0 &&
        <Box sx={{ backgroundColor: "rgb(243,233,223)", py: 4 }}>
            <Container >
                <Stack spacing={2} my={5} justifyContent="center" >
                    <Typography variant="h5" component={"h3"} sx={{ textAlign: "center", color: "primary.main", textTransform: "uppercase", fontWeight: "bold" }}>
                        {t("Popular-Products.title.titleLine1")}
                    </Typography>
                    <Typography variant="h4" component={"h4"} sx={{ textAlign: "center", color: "primary.main", textTransform: "capitalize", fontWeight: "bold" }}>
                        {t("Popular-Products.title.titleLine2.1")}
                        {
                            " "
                        }
                        <span style={{ color: "#055F71" }}>
                            {t("Popular-Products.title.titleLine2.2")}
                        </span>
                    </Typography>
                </Stack>
                <Grid container spacing={5} >

                    {loading ? ["", "", "",].map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index} >
                            <SkeletonLoadingProducts />
                        </Grid>
                    )) : (
                        products.map((product, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <ProductItem product={product} />
                            </Grid>
                        ))
                    )
                    }

                </Grid>
                <Button variant="outlined" sx={{ display: "block", margin: "auto", my: "50px", borderRadius: "20px", px: 8, fontWeight: "bold", py: 2 }} onClick={navigateToPopularProducts}>
                    {t("Popular-Products.view-all")}
                </Button>
            </Container>
        </Box>
    )
}

