import { Box, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import uploadImg from "../../../assets/images/uploadImg.webp";

const RenderMainImageInput = ({ handleImageChange, imageLoading }) => {
  const { t } = useTranslation("add-product");
  const { setFieldValue, values } = useFormikContext(); // Access Formik context
  const fileInputRef = useRef(null);

  const handleDelete = () => {
    setFieldValue("pictures", values.pictures.filter((_, index) => index !== 0));
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <input
        ref={fileInputRef}
        type="file"
        id="mainImage"
        onChange={(event) => handleImageChange(event, 0, setFieldValue)}
        style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, opacity: 0, cursor: 'pointer' }}
      />
      {!values.pictures[0] ? (
        <label htmlFor="mainImage">
          <Box>
            {imageLoading[0] ? (
              <Box sx={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {t("formFields.loading")}
              </Box>
            ) : (
              <>
                <img src={uploadImg} alt="upload" style={{ width: "100%", height: "50px", objectFit: "contain" }} loading="lazy" />
                <Typography variant="body1" sx={{ fontSize: ["8px", "16px"], mt: 3,textAlign: "center" }}>
                  {t("formFields.dragDrop")}
                </Typography>
              </>
            )}
          </Box>
        </label>
      ) : (
        <Box sx={{ position: "relative", width: "100%" }}>
          <img src={values.pictures[0]} alt="main" style={{ width: "100%", height: "100px", objectFit: "cover", borderRadius: "10px" }} />
          <Button
            variant="contained"
            color="error"
            onClick={handleDelete}
            sx={{ position: "absolute", top: "5px", right: "5px", width: "30px", height: "30px" }}
          >
            <DeleteIcon />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RenderMainImageInput;
