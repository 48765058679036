import { Box, Skeleton } from '@mui/material'


export const CategoriesSkeleton = () => {
    return (
        <Box sx={{ borderRadius: "50px" }} >
            <Skeleton variant="rectangular" sx={{ width: "100%", height: "200px" }} />
            <Skeleton variant="text" sx={{ width: "100%", height: "20px", marginTop: "10px" }} />

        </Box>
    )
}
