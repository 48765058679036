import back1 from "../../../assets/images/back1.webp"
import back2 from "../../../assets/images/back2.webp"
import back3 from "../../../assets/images/back3.webp"
export const items = [
    {
        img: back1,
        title: "your-back.steps.step1.title",
        description: "your-back.steps.step1.description"
    },
    {
        img: back2,
        title: "your-back.steps.step2.title",
        description: "your-back.steps.step2.description"
    },
    {
        img: back3,
        title: "your-back.steps.step3.title",
        description: "your-back.steps.step3.description"
    }
]