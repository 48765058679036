import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import axiosInstance from "../../axios/axiosInstance";
import useSetNotificationAsRead from "./hooks/SetNotificationAsReaded";
import { SkeletonLoading } from "./SkeletonLoading";
import { useTranslation } from "react-i18next";
import NotificationItem from "./NotificationItem";
import { NotificationContext } from "../../context";
import { useContext } from "react";
const NotificationList = ({ type, limit, noMoreText, fetchUrl, actions }) => {
    const { updateNotification } = useContext(NotificationContext);
    const { t } = useTranslation("notifications");
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [hiddenLoadMore, setHiddenLoadMore] = useState(false);
    let [page, setPage] = useState(1);

    const setNotificationAsRead = useSetNotificationAsRead();

    useEffect(() => {
        if (page === 1 && notifications.length === 0) {
            setLoading(true);
        }
        const getNotifications = () => {
            axiosInstance
                .get(`${fetchUrl}?type=${type}&isRead=${false}&page=${page}&limit=${limit}`)
                .then((res) => {
                    if (res.data.notifications.length === 0) {
                        setHiddenLoadMore(true);
                    }
                    setNotifications((prev) => [...prev, ...res.data.notifications]);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
        getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page]);

    useEffect(() => {
        if (notifications.length === 0) return;
        axiosInstance
            .get(`${fetchUrl}?type=${type}&isRead=${false}&page=${1}&limit=${limit}`)
            .then((res) => {
                setNotifications(res.data.notifications);
                if (res.data.notifications.length === 0) {
                    setHiddenLoadMore(true);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateNotification]);

    const fetchMoreNotification = () => {
        setPage(page + 1);
    };

    return (
        <Stack spacing={1}>
            <SkeletonLoading loading={loading} />
            {notifications.map((notification) => (
                <NotificationItem
                    key={notification._id}
                    notification={notification}
                    type={type}
                    actions={{ setNotificationAsRead, ...actions }}
                />
            ))}
            {!hiddenLoadMore ? (
                <Button
                    variant="contained"
                    sx={{ backgroundColor: "primary.main", color: "white", mt: 2 }}
                    onClick={fetchMoreNotification}
                >
                    {t("loadMore")}
                </Button>
            ) : (
                <Box sx={{ textAlign: "center", mt: 2, color: "gray" }}>
                    {t(noMoreText)}
                </Box>
            )}
        </Stack>
    );
};

export default NotificationList;
