import { Box, Button, Checkbox, FormControl, FormControlLabel, InputLabel, Radio, RadioGroup, Slider, Stack, Typography, } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { DisplayLoaction } from "../../general/DisplayLoaction";
import { CustomDatePicker } from "./CustomDatePicker";
import useFilterFunctions from "./hook/ControlFiltersKey";
import { useTranslation } from "react-i18next";
export const AllFilterKey = () => {
  const { t } = useTranslation("shop");
  const { filtersKey, handleFieldChange, updateFilterKey, clearFilter, handleLocationCheckboxChange } = useFilterFunctions();
  return (
    <Stack spacing={2} sx={{ py: 2, }}>
      <Box sx={{ pl: 2 }}>
        <Typography variant="h6" component="div" sx={{ pb: 1, color: "primary.main",fontWeight: "bolder"  }}>
        {t('AllFilterKey.price')}
        </Typography>
        <Slider
          getAriaLabel={() => t('AllFilterKey.rangePrice')}
          value={filtersKey.price}
          onChange={(event, newValue) => handleFieldChange("price", newValue)}
          valueLabelDisplay="auto"
          max={10000}
          min={0}
          step={10}
          sx={{ width: "100%" }}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ py: 1, color: "secondary.main", fontWeight: "bolder" }}>
          <Typography variant="caption" component="div">
          {t('AllFilterKey.minPrice')}: {filtersKey.price[0]}
          </Typography>
          <Typography variant="caption" component="div">
          {t('AllFilterKey.maxPrice')}: {filtersKey.price[1]}
          </Typography>

        </Stack>
      </Box>

      <Box sx={{ py: 3 }}>
      <Typography variant="h6" component="div" sx={{ pb: 1, color: "primary.main",fontWeight: "bolder"  }}>
        {t('AllFilterKey.availability')}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={2}>
            <CustomDatePicker
             label={t('AllFilterKey.startDate')}
              value={filtersKey.avilapility.start}
              minDate={dayjs(new Date())}
              onChange={(newValue) =>
                handleFieldChange("avilapility", {
                  ...filtersKey.avilapility,
                  start: newValue ? dayjs(newValue) : null,
                })
              }

            />
            <CustomDatePicker
              label={t('AllFilterKey.endDate')}
              value={filtersKey.avilapility.end}
              minDate={dayjs(new Date())}
              onChange={(newValue) =>
                handleFieldChange("avilapility", {
                  ...filtersKey.avilapility,
                  end: newValue ? dayjs(newValue) : null,
                })
              }

            />
            <Typography variant="caption" sx={{ color: "red" }}>
              {filtersKey.avilapility.start &&
                filtersKey.avilapility.end &&
                filtersKey.avilapility.start > filtersKey.avilapility.end &&
                t('AllFilterKey.dateError')}
            </Typography>
          </Stack>
        </LocalizationProvider>

      </Box>

      <Box>
      <Typography variant="h6" component="div" sx={{ pb: 1, color: "primary.main",fontWeight: "bolder"  }}>
        {t('AllFilterKey.condition')}
        </Typography>
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={filtersKey.condition}
            onChange={(event) =>
              handleFieldChange("condition", event.target.value)
            }
            sx={{ pb: 2 }}
          >
            <FormControlLabel value="All" control={<Radio sx={{ color: "secondary.main" }} />} label={t('AllFilterKey.all')} />
            <FormControlLabel value="New" control={<Radio sx={{ color: "secondary.main" }} />}  label={t('AllFilterKey.newItem')}/>
            <FormControlLabel value="Used" control={<Radio sx={{ color: "secondary.main" }} />}  label={t('AllFilterKey.usedItems')}/>
          </RadioGroup>

        </FormControl>
      </Box>
      <Box>
      <Typography variant="h6" component="div" sx={{ pb: 1, color: "primary.main",fontWeight: "bolder"  }}>
        {t('AllFilterKey.location')}
        </Typography>

        <DisplayLoaction location={filtersKey.location} />
        <Stack spacing={2} direction="row" sx={{ alignItems: "center" }}>
          <Checkbox
            name="location"
            checked={Boolean(filtersKey.location)}
            onChange={handleLocationCheckboxChange}
            aria-label="location checkbox"
            id="locationCheckbox"
            sx={{ color: "secondary.main", }}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={<RadioButtonCheckedIcon />}
          />
          <InputLabel htmlFor="locationCheckbox" sx={{ color: "secondary.main" }}>
          {t('AllFilterKey.useCurrentLocation')}
          </InputLabel>
        </Stack>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={updateFilterKey}
          sx={{ borderRadius: "10px", py: 2 }}
        >
          {t('AllFilterKey.applyFilter')}
        </Button>

        <Button variant="outlined" color="primary" fullWidth onClick={clearFilter} sx={{ my: 2, borderRadius: "10px", py: 2 }}>
        {t('AllFilterKey.clearFilter')}
        </Button>
      </Box>
    </Stack>
  );
};



