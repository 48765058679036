import { Avatar, Box, Paper, Stack, Typography } from '@mui/material'
import { UserAvatarSkeleton } from './loading/UserAvatarSkeleton'
export const UserAvatar = ({ user, displayInProfile = false }) => {
    return (
        !user ? (
            <UserAvatarSkeleton />
        ) : (
            <Stack direction="row" spacing={2} alignItems="center">
                <Paper
                    sx={{
                        borderRadius: "50%",
                        width: "100px",
                        height: "100px",
                        position: "relative",
                    }}
                    elevation={5}
                >
                    <Avatar
                        sx={{ textDecoration: "none", width: "100px", height: "100px" }}
                    >
                        {user && user.profilePicture ? (
                            <img
                                src={user?.profilePicture}
                                alt={ user?.displayName}
                                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                                loading="lazy"
                            />
                        ) : (
                          
                            user?.displayName?.[0]?.toUpperCase() 
                         
                        )}
                    </Avatar>
                </Paper>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",

                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        { user?.displayName}
                    </Typography>
                    {displayInProfile && (
                        <Typography variant="subtitle2" sx={{ color: "secondary.main" }}>
                            {user?.email || user?.phoneNumber}
                        </Typography>
                    )}
                </Box>
            </Stack>
        )
    )
}

