import { Box, Container, Grid, Skeleton, Stack } from "@mui/material"


export const PaymentSkeleton = () => {
    return (

        <Box py={5}>
            <Container >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <Skeleton variant="rectangular" width="100%" height={300} />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Stack spacing={2} sx={{ boxShadow: "0px 0px 2px 0px gray", p: 2 }}>
                            <Skeleton variant="text" width="100%" height={30} />
                            <Skeleton variant="text" width="100%" height={30} />
                            <Skeleton variant="text" width="100%" height={30} />

                        </Stack>
                        <br />
                        <Stack >
                            <Box sx={{ width: "100%" }}>
                                <Skeleton variant="rectangular" width={"100%"} height={20} />
                            </Box>
                            <br />
                            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <Skeleton variant="rectangular" width={"80%"} height={20} />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
