import { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance"; 
import { useParams } from "react-router-dom";
export const useGetProduct = () => {
    const {productID} = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        axiosInstance
          .get(`/products/${productID}`)
          .then((res) => setProduct(res.data.product))
          .catch(() => setProduct(null))
          .finally(() => setLoading(false));
      }, [productID]);

      return { product, loading };
}
