import { useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { getUser } from "../../../redux/slices/AuthUser";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useShowAlert } from "../../../hooks/useShowAlert";
export const useSendMessage = () => {
    const { showAlert } = useShowAlert();
    const authUserId = useSelector(getUser)?.id; // get user id from redux
    const { userId } = useParams();
    const [loading, setLoading] = useState(false);
    const handleSubmit = (values, { resetForm }) => {
        const contactValues = {
            sender: authUserId,
            receiver: userId,
            content: values.message,
        };
            setLoading(true);
            axiosInstance
                .post(
                    "/messages",
                    contactValues
                )
                .then((res) => {
                    showAlert("success", res.data.message);

                    setLoading(false);
                    resetForm();
                })
                .catch((err) => {
                    if (err) {
                        showAlert("error", err.response.data.error);

                    }
                    setLoading(false);
                });
        
    };

    return { handleSubmit, loading };
};
