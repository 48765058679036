import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from "@mui/material";
import { getUser } from "../../redux/slices/AuthUser";
import { TransactionRow } from "./TransactionRow";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
export const TransactionsTable = ({ items }) => {
  const { t } = useTranslation("history");
  const userId = useSelector(getUser)?.id;
  return (
    <TableContainer component={Box} sx={{ borderRadius: "10px", borderBottom: "1px solid rgb(247,247,247)" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "rgb(209,209,209)" }}>
            {["Name", "Start At", "End At", "Renter", "Owner", "Cancel", "Review", "Status"].map((header) => (
              <TableCell key={header} align="center" sx={{ fontSize: "13px", color: "primary.main" }}>
                {t(`table.${header}`)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>

          {items.length > 0 ? (
            items.map((transaction, i) => (
              <TransactionRow
                key={i}
                transaction={transaction}
                userId={userId}
              />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8}>
                <Box sx={{ textAlign: "center", color: "primary.main", width: "100%", fontWeight: "bold" }}>
                  {t("no-data")}
                </Box>
              </TableCell>
            </TableRow>
          )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};


