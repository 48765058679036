import { InputAdornment, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
// import icons 
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomFormikField from '../../../formik/CustomFormikField';
import CountryCodeSelect from '../../../formik/CountryCodeSelect';
import PasswordInput from '../../../formik/PasswordInput';
import SubmitButton from '../../../formik/SubmitButton';
export const LoginFiled = ({ loginMethod, loading }) => {

    const { t } = useTranslation("login");
    const theme = useTheme();

    return (
        <Stack spacing={2}>
            {loginMethod === "phone" ? (
                <div style={{ display: 'flex', }}>
                    <CountryCodeSelect
                        name="countryCode"
                        type="tel"
                        placeholder={t("signUpinput.phone")}
                        size="small"
                    />
                    <CustomFormikField
                        name="identifier"
                        type="tel"
                        placeholder={t("logininput.phone")}
                        size="small"
                    />

                </div>
            ) : (
                <CustomFormikField
                    name="identifier"
                    type="email"
                    placeholder={t("logininput.email")}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MailOutlineIcon sx={{ fontSize: "15px" }} />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            <PasswordInput name="password" placeholder={t("logininput.password")} size="small" />
            <Link to="/reset-password" style={{ color: theme.palette.text.main, textDecoration: "none" }}>{t("forgot")}</Link>
            <SubmitButton disabled={loading}>
                {t("login")} {"   "}
            </SubmitButton>



        </Stack>
    )
}
