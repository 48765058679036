import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import ShareIcon from "@mui/icons-material/Share";

import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    LinkedinIcon,
    LinkedinShareButton,
} from "react-share";

import { Stack } from '@mui/material';

export const ShareProducts = ({ shareUrl, title }) => {
    const [copied, setCopied] = useState(false);

    let whatsappURL = `whatsapp://send?text=${encodeURIComponent(title + ' ' + shareUrl)}`;

    const handleCopy = async (url) => {
        try {
            await navigator.clipboard.writeText(url);
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        } catch (_) {/* empty */}
    };
    const actions = [
        {
            icon: (
                <FacebookShareButton
                    style={{ display: "flex", alignItems: "center" }}
                    url={shareUrl}
                    title={title}
                >
                    <FacebookIcon size={20} round />
                </FacebookShareButton>
            ),
            name: "Facebook",
        },
        {
            icon: (
                <TwitterShareButton
                    style={{ display: "flex", alignItems: "center" }}
                    url={shareUrl}
                    title={title}
                >
                    <TwitterIcon size={20} round />
                </TwitterShareButton>
            ),
            name: "Twitter",
        },
        {
            icon: (
                <LinkedinShareButton
                    style={{ display: "flex", alignItems: "center" }}
                    url={shareUrl}
                >
                    <LinkedinIcon size={20} round />
                </LinkedinShareButton>
            ),
            name: "Linkedin",
        },
        {
            icon: (
                <a href={whatsappURL} style={{ display: "flex", alignItems: "center" }}>
                    <WhatsappIcon size={20} round />
                </a>
            ),
            name: "Whatsapp",
        },
    ];

    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <ShareIcon variant="outlined" onClick={handleClickOpen} sx={{ color: "primary.main", cursor: "pointer", }} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    Share {title} on social media
                </DialogTitle>
                <DialogContent>
                    <DialogContentText as="div">
                        <Stack direction="row" spacing={2}>
                            {
                                actions.map((action) => (
                                    <Paper key={action.name} sx={{ p: 1 }}> {action.icon}</Paper>
                                ))
                            }
                            <Paper sx={{ cursor: 'pointer' }} >
                                <Button onClick={() => handleCopy(shareUrl)} sx={{ textTransform: "capitalize" }}>{copied ? "Copied" : "Copy"}</Button>
                            </Paper>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};