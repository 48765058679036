import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useCommentValidation = () => {
    const { t } = useTranslation("comments-reviews");
  const validationSchema = yup.object({
        comment: yup.string().required(t("comment.add.formErrors.comment.required")),
    });
    return {validationSchema};
};
