import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../redux/slices/AuthUser";
import { setOpenPopup } from "../redux/slices/EmailPopup";

export const useShowEmailPopup = () => {
    const authUser = useSelector(getUser);
    const dispatch = useDispatch();
    
    const checkEmail = (userType) => {
        if (!authUser?.user?.email || /^.+@directrent\.com$/.test(authUser.user.email)) {
            dispatch(setOpenPopup({ openPopup: true, userType }));
            return true;
        }
        return false;
    }
    
    return checkEmail;
};
