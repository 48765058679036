import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
export const LoadingScreen = ({ loadingState }) => {
    const { t } = useTranslation("waitLoginBySocail");
    const [open, setOpen] = useState(false);

    //  setloadingState(true)
    useEffect(() => {
        if (loadingState) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [loadingState]);

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiBackdrop-root': {
                    backdropFilter: 'blur(5px)',

                },
            }}
        >
            <DialogContent dividers sx={{

            }}>
                <Box sx={{
                    width: ["200px", "300px"],
                    height: ["200px"],
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Stack spacing={3} sx={{

                        justifyContent: "center",
                        alignItems: "center"
                    }} >
                        <Typography >
                            {t("title1")}
                            <br />
                            {t("title2")}
                        </Typography>
                        <CircularProgress sx={{ color: "primary.main" }} />
                    </Stack>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
