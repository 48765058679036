import { Select, MenuItem, } from "@mui/material";
import { useField } from 'formik';
import { useState } from "react";
const CountryCodeSelect = ({ name, onChange, ...props }) => {
    const [value, setValue] = useState("+1");
    const [field, meta, helpers] = useField(name);
    const handleChange = (event) => {
        setValue(event.target.value);
        helpers.setValue(event.target.value);
        if (onChange) {
            onChange(event);
        }
    };

    return (

        <Select
            {...field}
            onChange={handleChange}
            size="small"
            value={value}
            sx={{
                marginRight: 1,
                width: "120px",
                height: "40px",
                backgroundColor: "white",
                color: "secondary.main",
                borderRadius: "10px",
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'white',
                },
                '& .MuiSvgIcon-root': {
                    color: 'secondary.main',
                },
            }}
            {...props}
        >
            <MenuItem value="+1">+1 (US)</MenuItem>
            <MenuItem value="+34">+34 (ES)</MenuItem>
            <MenuItem value="+20">+20 (EG)</MenuItem>
        </Select>

    );
};

export default CountryCodeSelect;