import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { getUser } from '../../../../redux/slices/AuthUser';
import { Links } from './handleLinks';
import useLogout from '../../../../hooks/useLogOut';
import LogoutIcon from '@mui/icons-material/Logout';
export const SettingBar = ({ navbarRef, openStack }) => {
    const Logout = useLogout(); // logout function
    const userId = useSelector(getUser)?.id; // get user id from redux
    const { t } = useTranslation("header");

    return (
        <Stack sx={{ flexDirection: ["column", "row", "row", "row"], justifyContent: "space-evenly", alignItems: "center", bgcolor: "secondary.main", py: 5, borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px", width: "100%", position: "absolute", top: openStack ? `${navbarRef.current.clientHeight}px` : "-100%", zIndex: 200, transition: "all .5s ease-in-out" }}>

            {
                userId && Links(userId).map((link, index) => (
                    <Button
                        key={index}
                        sx={{
                            my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                            '&:hover': {
                                transform: 'scale(1.05)',
                            },
                        }}
                        component={Link}
                        to={link.to}
                    >
                        <link.icon sx={{ fontSize: "20px" }} />
                        {t(link.name)}
                    </Button>
                ))
            }
            <Button
                sx={{
                    my: 2, color: "text.main", display: "flex", alignItems: "center", gap: 1, textTransform: "capitalize", fontSize: "16px", transition: 'transform 0.3s ease-in-out', fontWeight: "bold",
                    '&:hover': {
                        transform: 'scale(1.05)',
                    },
                }}
                onClick={() => Logout("You have successfully logged out.",true)}
            >
                <LogoutIcon sx={{ fontSize: "20px" }} />
                {t("Logout")}
            </Button>
        </Stack>
    )
}
