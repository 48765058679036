import axiosInstance from "../../../../axios/axiosInstance";
import { useShowAlert } from "../../../../hooks/useShowAlert";

const useAddCategory = ({langBoxes,setLangBoxes}) => {
  const { showAlert } = useShowAlert();

  const addCategory = (values, { resetForm }) => {
    const nameTranslations = langBoxes.reduce((acc, lang) => ({
      ...acc,
      [lang.lang]: values[lang.lang]
    }), {})

    const data = {
      name: values.name,
      picture: values.picture,
      nameTranslations: nameTranslations
    }
    axiosInstance.post("admin/categories/create", data).then(() => {
      showAlert('success', "Category added successfully");
      resetForm();
      setLangBoxes([]);
    }).catch((err) => {
      showAlert('error', err.response.data.error);
    }); 
  };

  return {
    addCategory
  };
};

export default useAddCategory;
