import { useState, useEffect, useCallback } from "react";
import axiosInstance from "../../../../axios/axiosInstance";
import { useShowAlert } from "../../../../hooks/useShowAlert";

const useShowDeleteCategory = () => {
    const { showAlert } = useShowAlert();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    // Fetch all categories from the server
    const fetchCategories = useCallback(async () => {
        setLoading(true);
        try {
            const res = await axiosInstance.get("/products/categories");
            setCategories(res.data);
        } catch {
            setCategories([]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    // Delete category
    const deleteCategory = async (id) => {
        try {
            await axiosInstance.delete(`/admin/categories/${id}`);
            showAlert('success', "Category deleted successfully");
            // felter out the deleted category from the categories state
            setCategories(categories.filter((category) => category._id !== id));
        } catch (err) {
            showAlert('error', err.response.data.error);
        }
    };

    return {
        categories,
        loading,
        deleteCategory
    };
};

export default useShowDeleteCategory;
