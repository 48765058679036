import { Box, Stack } from "@mui/material";
import payment1 from "../../../assets/images/payment1.svg";
import payment2 from "../../../assets/images/payment2.svg";
import payment3 from "../../../assets/images/payment3.svg";
import payment4 from "../../../assets/images/payment4.svg";
import payment5 from "../../../assets/images/payment5.svg";

const payments = [payment1, payment2, payment3, payment4, payment5];

const FooterPayments = () => (
  <Stack direction="row" spacing={1} justifyContent="center">
    {payments.map((payment, index) => (
      <Box key={index} sx={{ backgroundColor: "text.main", p: "1px", width: "40px", height: "25px", borderRadius: "5px" }}>
        <img src={payment} alt="payment" loading="lazy" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
      </Box>
    ))}
  </Stack>
);

export default FooterPayments;
