import { useContext } from "react";
import { TransactionsTable } from "./TransactionsTable";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import { UserTransactionsSearch } from "../../context";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import InfiniteScrollList from "../general/InfiniteScrollLis";
import { TableLoading } from "../general/loading/TableLoading";
export const TransactionList = () => {
  const { updatetransactions } = useContext(UserTransactionsSearch);
  const { t } = useTranslation("history");

  // get search keys from context
  const { searchKeys } = useContext(UserTransactionsSearch);
  const userId = useSelector(getUser)?.id; // get user id from redux
  const fetchData = async (page, limit) => {
    const response = await axiosInstance.get(
      `/transactions/user/${userId}?page=${page}&limit=${limit}&status=${searchKeys.status}&search=${searchKeys.name}`
    );
    return response.data.transactions;
  };
  return (
    <InfiniteScrollList
      fetchData={fetchData}
      loadingText={t("loadMore")}
      noProductsText={t("no-data")}
      LoadingComponet={TableLoading}
      RenderComponent={TransactionsTable}
      updateTrigger={updatetransactions}
    />
  );
};

