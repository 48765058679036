// src/hooks/useLogout.js
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser } from "../redux/slices/AuthUser";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";
const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = (message, clearPathHistory) => {
    dispatch(clearUser());
    localStorage.removeItem("token");// Remove token
    localStorage.removeItem("productSaved");// Remove saved products
    if (clearPathHistory) {
      localStorage.removeItem("pathHistory");// Remove path history
    }
    dispatch(setAlertDetails({ type: "success", message }));
    navigate("/login");
  };

  return logout;
};

export default useLogout;
