import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useCallback } from 'react'

const ReferralTable = ({ items }) => {

    const formatDate = useCallback((date) => {
        const d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    }, []);
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead sx={{ backgroundColor: "#FAFBFC" }}>
                    <TableRow>
                        <TableCell align="center">Resever Name</TableCell>
                        <TableCell align="center">Resever Email</TableCell>
                        <TableCell align="center">Sender Email</TableCell>
                        <TableCell align="center">referral Code</TableCell>
                        <TableCell align="center">Join Date</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((user) => (
                        <TableRow key={user._id}>
                            <TableCell align="center">{user?.receiver?.displayName}</TableCell>
                            <TableCell align="center">{user?.receiver?.email}</TableCell>
                            <TableCell align="center">{user?.sender?.email}</TableCell>
                            <TableCell align="center">{user?.sender?.referralCode}</TableCell>
                            <TableCell align="center">{formatDate(user?.createdAt)}</TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ReferralTable