import * as yup from "yup";
import { useTranslation } from "react-i18next";
export const usePopupProfileDetails = () => {
  const { t } = useTranslation("UserDetailsDialog");
  const popupProfileDetails = yup.object({
    firstName: yup.string().required(t("formErrors.firstName.required")),
    lastName: yup.string().required(t("formErrors.lastName.required")),
    displayName: yup.string().required(t("formErrors.displayName.required")),
    phoneNumber: yup.string()
    .matches(
      /^\+[1-9]\d{1,14}$/, 
      t("formErrors.phoneNumber.invalidFormat")
    )
      .required(t("formErrors.phoneNumber.required")),
    email: yup.string().email(t("formErrors.email.invalidFormat")).required(t("formErrors.email.required")),
    location: yup.object().nullable().required(t("formErrors.location.required")),
    profilePicture: yup.string().required(t("formErrors.profilePicture.required")),
  });
  return { popupProfileDetails };
};
