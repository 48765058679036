// import svg icons
import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
export const Links = (userId) => {
    return [
        {
            'name':'history',
            'to':'/transactions',
             "icon": ScheduleIcon,
        },
        {
            'name':'profile',
            'to':'/profile',
            "icon": PersonIcon,
        },
        {
            'name':'chat',
            'to':'/chat',
            "icon": MessageIcon,
        },
        {
            'name':'settings',
            'to':'/settings',
            "icon": SettingsIcon,
        },
        {
            'name':'favorites',
            'to':`/favorite-products/${userId}`,
            "icon": FavoriteBorderIcon,
        },
        {
            'name':'your Products',
            'to':'/my-products',
            "icon": ManageSearchIcon,
        },
    ]

}