import {  useContext } from "react";
import { useParams } from "react-router-dom";
import { TransactionsTable } from "./TransactionsTable";
import { AdminTransactionsSearch } from "../../../context";
import { TableLoading } from "../../general/loading/TableLoading";
import axiosInstance from "../../../axios/axiosInstance";
import InfiniteScrollList from "../../general/InfiniteScrollLis";
const UserTransactions = () => {
  const { userID } = useParams();
  const { searchKeys } = useContext(AdminTransactionsSearch);
  // Fetch initial transactions when the component mounts

  const fetchData = async (page, limit) => {
    const response = await axiosInstance.get(`/admin/user/transactions/${userID}?page=${page}&limit=${limit}&search=${
      searchKeys.search
    }&status=${searchKeys.status}`);
    return response.data.transactions;
  };
  return (
    <InfiniteScrollList
      fetchData={fetchData}
      loadingText={"loading more Transactions"}
      noProductsText={"No Transactions found"}
      LoadingComponet={TableLoading}
      RenderComponent={TransactionsTable}
    />
  );
};
export default UserTransactions;
