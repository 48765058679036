import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import { UserProductsSearch } from "../../context";
import axiosInstance from "../../axios/axiosInstance";
import { UserProductsContext } from "../../context";
import { ProductTable } from "./ProductTable";
import { useTranslation } from "react-i18next";
import InfiniteScrollList from "../general/InfiniteScrollLis";
import { TableLoading } from "../general/loading/TableLoading";
export const Productlist = () => {
  const { t } = useTranslation("user-products");
  const [Update, setUpdate] = useState(false);
  const userId = useSelector(getUser)?.id; // get user id from redux store
  const { searchKeys } = useContext(UserProductsSearch);
  const fetchProducts = async (page, limit) => {
    const response = await axiosInstance.get(`/products/user/${userId}?page=${page}&limit=${limit}&available=${searchKeys.status}&search=${searchKeys.name}`);
    return response.data.products;
  };

  // handle update products 
  const updateProducts = () => {
    setUpdate(!Update);
  }
  return (
    <UserProductsContext.Provider value={{ Update: false, setUpdate: updateProducts }}>
      <InfiniteScrollList
        fetchData={fetchProducts}
        loadingText={t("loadMore")}
        noProductsText={t("no-data")}
        LoadingComponet={TableLoading}
        RenderComponent={ProductTable}
        updateTrigger={Update}
      />
    </UserProductsContext.Provider>
  );
};
