import { useGoogleLogin } from "@react-oauth/google";
import "./googleStyle.css"
import { useState } from "react";
import { LoadingScreen } from "../LoadingScreen";
import axiosInstance from "../../../../axios/axiosInstance";
import { Button } from "@mui/material";
import { fetchUser, setAuthUser } from "../../../../redux/slices/AuthUser";
import { useDispatch } from "react-redux";
import { decodeToken } from "../../../../helper/decodeToken";
import GoogleIcon from '@mui/icons-material/Google';
import useNavigateBasedOnPath from "../../helper/checkPrevPath";
import { useShowAlert } from "../../../../hooks/useShowAlert";
export const LoginButton = () => {
    const { showAlert } = useShowAlert();
    const navigateBasedOnPath = useNavigateBasedOnPath();
    const dispatch = useDispatch();
    const [accessToken, setAccessToken] = useState('');
    const googleLogin = useGoogleLogin({
        onSuccess: async tokenResponse => {
            setAccessToken(tokenResponse.access_token);
            axiosInstance.post('/user/auth/google', { accessToken: tokenResponse.access_token }).then(async (res) => {
                localStorage.setItem('token', res.data?.token);
                const { id, exp } = await decodeToken(res.data?.token);
                dispatch(setAuthUser({ user: null, exp: exp, id: id })) // set user to redux
                dispatch(fetchUser())
                    .then(() => {
                        // check prev path to navigate to the correct path
                        navigateBasedOnPath();
                    })
                    .catch((err) => {
                        showAlert("error", err.response.data.error);
                    })
            }).catch((err) => {
                showAlert("error", err.response.data.message); // show error message to user 
                setAccessToken(''); // reset access token to hide loading screen
            });
        },
        onError: error => {
            showAlert("error", error.message); // show error message to user
            setAccessToken('');// reset access token to hide loading screen 
        },
    });
    return (
        <>
            <Button variant="contained" startIcon={<GoogleIcon />} sx={{
                width: "244px"
            }}
                onClick={googleLogin}
            >
                Google
            </Button>
            <LoadingScreen loadingState={!!accessToken} />
        </>

    )
}
