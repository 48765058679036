import { useDispatch } from "react-redux";
import { setAlertDetails } from "../redux/slices/AlertDetailsSlice";

export const useShowAlert = () => {
    const dispatch = useDispatch();

    const showAlert = (type, message) => {
        dispatch(setAlertDetails({ type, message }));
    };

    return { showAlert };
};