import { useState, useContext } from "react";
import { AdminTransactionsSearch } from "../../../../context";

const useSearch = () => {
  const [searchKeys, setSearchKeys] = useState({
    name: "",
    status: "",
  });

  const { changeSearchKeys } = useContext(AdminTransactionsSearch);

  // Handle change of input fields
  const handleChangeField = (e) => {
    setSearchKeys({ ...searchKeys, [e.target.name]: e.target.value });
  };

  // Send search keys to context
  const sendSearchKeys = () => {
    // if status is All, set it to empty string to get all transactions
    if (searchKeys.status === "All") {
      searchKeys.status = "";
    }
    changeSearchKeys({ search: searchKeys.name, status: searchKeys.status });
  };

  // Handle enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendSearchKeys();
    }
  };

  return {
    searchKeys,
    handleChangeField,
    sendSearchKeys,
    handleKeyDown
  };
};

export default useSearch;
