import { Box, CircularProgress, Stack, Typography } from "@mui/material";

 
export const LoadingMoreProductsAnimation = ({loadingText}) => {
  return (
    <Box as="div" sx={{width:"100%"}}>
      <Stack spacing={5} direction="row" sx={{justifyContent:"center"}}>
        <CircularProgress />
        <Typography variant="body1">{loadingText}</Typography>
      </Stack>
    </Box>
  );
};
