import { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { TransactionList } from "../components/history/TransactionList";
import { UserTransactionsSearch } from "../context";
import { Searchkeys } from "../components/history/SearchKeys";
import { useTranslation } from "react-i18next";
export const TransactionHistory = () => {
  const { t } = useTranslation("history");
  const [searchKeys, setSearchKeys] = useState({ name: "", status: "" });
  const [updatetransactions, setUpdateTransactions] = useState(false);

  const changeSearchKeys = (keys) => { // change search keys
    setSearchKeys(keys);
  };
  // handle update transactions
  const handleupdateTransactions = () => {
    setUpdateTransactions(!updatetransactions);
  }

  return (
    <Container maxWidth="xl" sx={{ py: 8, position: "relative" }}>
      <UserTransactionsSearch.Provider
        value={{ searchKeys, changeSearchKeys, updatetransactions, handleupdateTransactions }}
      >
        <Typography variant="h3" sx={{ mb: 8, color: "primary.main", textAlign: "center", fontWeight: "bold" }}>
          {t("title")}
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Searchkeys />
          </Grid>
          <Grid item xs={12} md={8}>
            <TransactionList />
          </Grid>
        </Grid>

      </UserTransactionsSearch.Provider>
    </Container>
  );
};


