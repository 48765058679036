import { useMemo } from "react";
import { Box, Grid, Stack, Typography, Divider, Container } from "@mui/material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import FooterLogo from "./FooterLogo";
import FooterLinks from "./FooterLinks";
import FooterAppDownloads from "./FooterAppDownloads";
import FooterPayments from "./FooterPayments";

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const { t } = useTranslation('footer');

  const serviceLinks = useMemo(() => [
    { text: t("aboutUs"), to: "/about-us", color: theme.palette.text.main },
    { text: t("howItWorks"), to: "/#howItWork", color: theme.palette.text.main },
    { text: t("shop"), to: "/shop", color: theme.palette.text.main },
  ], [t, theme.palette.text.main]);

  const contactLinks = useMemo(() => [
    { text: t("email"), to: "/contact-us", color: theme.palette.text.main },
    { text: t("facebook"), to: "/", color: theme.palette.text.main },
    { text: t("instagram"), to: "/", color: theme.palette.text.main },
  ], [t, theme.palette.text.main]);

  const legalLinks = useMemo(() => [
    { text: t("Legal"), to: "/terms-of-service#governingLaw", color: theme.palette.text.main },
    { text: t("termsOfService"), to: "/terms-of-service", color: theme.palette.text.main },
    { text: t("guaranteePolicy"), to: "/terms-of-service#h", color: theme.palette.text.main },
  ], [t, theme.palette.text.main]);

  return (
    <Box sx={{ py: 4, backgroundColor: "primary.main" }}>
      <Container maxWidth="xl">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={4}>
            <FooterLogo />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3} pt={4}>
              <Grid item xs={6} md={3}>
                <FooterLinks title={t("services")} links={serviceLinks} />
              </Grid>
              <Grid item xs={6} md={3}>
                <FooterLinks title={t("contact")} links={contactLinks} />
              </Grid>
              <Grid item xs={6} md={3}>
                <FooterLinks title={t("legal")} links={legalLinks} />
              </Grid>
              <Grid item xs={6} md={3}>
                <FooterAppDownloads />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Stack mt={5}>
          <Divider sx={{ backgroundColor: "white" }} />
        </Stack>
        <Stack sx={{ mt: 2, justifyContent: "space-between", flexDirection: ["column", "row"] }}>
          <Typography variant="body2" color="text.main" align="center" sx={{ order: [2, 1] }}>
            © {currentYear}{" "}
            <Link to="/" style={{ color: theme.palette.text.main }}>
              Direct Rent
            </Link>{" "}
            . {t("allRightsReserved")}
          </Typography>
          <FooterPayments />
        </Stack>
      </Container>
    </Box>
  );
};
