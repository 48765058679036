import { Box, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { SearchByNameOrStatus } from '../../components/admin/transaction/SearchByNameOrStatus'
import { AdminTransactionsSearch } from '../../context'
import  UserTransactions  from '../../components/admin/transaction/UserTransactios'
export const UserTransactionsPage = () => {
  const { username } = useParams()
  const [searchKeys, setSearchKeys] = useState({ search: "", status: "" })

  const changeSearchKeys = (keys) => {
    setSearchKeys(keys)
  }
  return (
    <Box>
      <Typography variant="h5" component="h4" sx={{ my: 3, color: "text.secoundry", fontWeight: "bold" }}>
        All Transactions For {username}
      </Typography>
      <AdminTransactionsSearch.Provider value={{ searchKeys: searchKeys, changeSearchKeys: changeSearchKeys }}>
        <SearchByNameOrStatus />
        <UserTransactions />
      </AdminTransactionsSearch.Provider>
    </Box>
  )
}
