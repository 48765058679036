import NotificationList from "../NotificationList";
export const Ongoing = () => {
  return (
    <NotificationList
      type="transaction paid"
      limit={10}
      noMoreText="ongoing.noMore"
      fetchUrl="/notifications"
    />
  );
};
