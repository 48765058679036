import { Box } from "@mui/material";
import logo2 from "../../../assets/images/logo2.svg";

const FooterLogo = () => (
  <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
    <img src={logo2} alt="logo" loading="lazy" style={{ objectFit: "contain" }} />
  </Box>
);

export default FooterLogo;
