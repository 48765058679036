import { Box, Skeleton, Stack } from '@mui/material'
export const SkeletonInCommentAndReviews = () => {
    return (
        <Stack spacing={3} direction="row">
            <Box sx={{ width: "100px", height: "100px", borderRadius: "20px" }}>
                <Skeleton variant='rectangular' sx={{ width: "100%", height: "100%", orderRadius: "20px" }} />
            </Box>
            <Box sx={{ p: 2, width: "100%" }}>
                <Skeleton variant='text' sx={{ width: "100%", height: "15px" }} />
                <Stack spacing={2} mt={3}>
                    <Skeleton variant='text' sx={{ width: "100%", height: "12px" }} />
                    <Skeleton variant='text' sx={{ width: "100%", height: "12px" }} />
                    <Skeleton variant='text' sx={{ width: "100%", height: "12px" }} />
                </Stack>

            </Box>

        </Stack>
    )
}
