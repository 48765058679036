import { useEffect, useState } from "react";
import axiosInstance from "../axios/axiosInstance";
import i18next from "i18next";
export const useGetCategories = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        axiosInstance.get("/products/categories").then((res) => {
            setCategories(res.data);
        }).catch(() => {
            setCategories([]);
        }).finally(() => {
            setLoading(false);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.languages[0]]);
    return {categories, loading};
}