export const handleRenderLink = (user) => {

    const links = [
        {
            name: "home",
            to: "/",
            renderd: true
        },
        {
            name: "about",
            to: "/about-us",
            renderd: true

        }, {
            name: "shop",
            to: "/shop",
            renderd: true
        },
        {
            name: "rent",

            to: "/add-new-product",
            renderd: user ? true : false

        },
        {
            name: "categories",
            to: "/categories",
            renderd: true
        },
        {
            name: "contact",
            to: "/contact-us",
            renderd: true

        }, {
            name: "admin",
            to: "/admins",
            renderd: user?.role === "Admin" ? true : false

        },




    ]
    return links;
}