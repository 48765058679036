import { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { useParams } from "react-router-dom";
export const useGetReview = () => {
    const { reviewID } = useParams()
    const [review, setReview] = useState("");
    useEffect(() => {
        const fetchReview  = async () => {
            axiosInstance.get(`/reviews/${reviewID}`).then((res) => {
                setReview(res.data.review.review);
              
            }).catch(() => {
                setReview("");
            })
        };

        fetchReview();
    }, [reviewID]);

    return { review }
}