import { useState, useEffect, } from "react";
import { Container, Grid, ToggleButtonGroup, ToggleButton, Button, Box, Stack } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WindowIcon from "@mui/icons-material/Window";
import { useSelector } from "react-redux";
import ProductsListContainer from "../components/shop/ProductsListContainer";
import FilterContainer from "../components/shop/filtering/FilterContainer";
import FilterContainerOnMobile from "../components/shop/filtering/FilterContainerOnMobile";
import { Search } from "../components/shop/Search";
import CatigoryListOnMobile from "../components/shop/filtering/CategoryListOnMobile";
import MapComponent from "../components/shop/ShowProductsOnMap/MapComponent";
import { SelectedFilter } from "../components/shop/SelectedFilter";

export const Shop = () => {
  const filterKes = useSelector((state) => state.filter.filterKeys);
  const [alignment, setAlignment] = useState("grid");
  const [showFilterInMobile, setShowFilterInMobile] = useState(false); // show filter in mobile
  const handleShowFilterInMobile = () => {
    // handle show filter in mobile
    setShowFilterInMobile(!showFilterInMobile);
  };
  const handleChange = (event, newAlignment) => {
    // handle change alignment
    setAlignment(newAlignment);
  };
  useEffect(() => {
    setShowFilterInMobile(false)
  }, [filterKes]);
  return (
    <>
      <Box sx={{ py: 8 }}>
        <Grid container sx={{ justifyContent: "space-around" }} spacing={2}>
          <Grid item xs={12} md={3} sx={{ display: ["none", "none", "block"] }}>
            <FilterContainer />
          </Grid>
          <Grid item xs={12} md={9}>

            <Container>
              <Search />
              <SelectedFilter />
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleShowFilterInMobile}
                  sx={{ display: ["block", "block", "none"], my: 3, py: 1 }}
                >
                  Filter
                </Button>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                  sx={{ display: "flex", justifyContent: "end", my: 3, flexGrow: 1 }}
                >
                  <ToggleButton value="grid">
                    <WindowIcon color="primary" />
                    Grid
                  </ToggleButton>
                  <ToggleButton value="map">
                    <LocationOnIcon color="primary" />
                    Map
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
              <Box sx={{ display: ["block", "block", "none"] }}>
                <CatigoryListOnMobile />
                {showFilterInMobile && (
                  <FilterContainerOnMobile />
                )}
              </Box>
              {alignment == "grid" ? <ProductsListContainer /> : <MapComponent />}
            </Container>
          </Grid>


        </Grid>
      </Box>
    </>
  );
};



