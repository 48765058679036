import axiosInstance from "../../../axios/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { useShowAlert } from "../../../hooks/useShowAlert";
import {useState} from "react";
export const useEditReview = () => {
    const { showAlert } = useShowAlert();
    const {productID, reviewID} = useParams()
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const handleSubmit = (values, { resetForm }) => {
        setLoading(true);
        axiosInstance
            .put(`/reviews/${reviewID}`, { review: values.review })
          .then((res) => {
            showAlert('success', res.data.message);
            navigate(`/product-detail/${productID}`);
          })
          .catch((err) => {
            showAlert('error', err.response.data.error);
          })
          .finally(() => setLoading(false));
        resetForm();
      };
      return { handleSubmit, loading };
}