import { Box, Button } from "@mui/material";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";

const mapStyle = {
  width: "100%",
  height: "300px",
};

export const SelectLocation = ({
  location,
  onLocationChange,
  mapId = "default_map_id",
  autoDetectButton = true,
  mapStyleOverride = mapStyle,
}) => {
  const handleAutoDetectLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        const newPosition = { lat: latitude, lng: longitude };
        onLocationChange(newPosition);
      });
    }
  };

  const ApiKey = import.meta.env.VITE_VERCEL_MAP_API_KEY;

  return (
    <APIProvider apiKey={ApiKey}>
      <Map
        mapId={mapId}
        zoom={2}
        center={location || { lat: 0, lng: 0 }}
        onClick={(event) => {
          const position = event.detail.latLng;
          onLocationChange(position);
        }}
        style={mapStyleOverride}
      >
        {location ? <AdvancedMarker position={location} /> : null}
      </Map>
      {autoDetectButton && (
        <Box>
          <Button onClick={handleAutoDetectLocation} sx={{ textAlign: "center" }}>
            Auto Detect Location
          </Button>
        </Box>
      )}
    </APIProvider>
  );
};
