import NotificationList from "../NotificationList";
export const Approved = () => {
  return (
    <NotificationList
      type="transaction approved"
      limit={10}
      noMoreText="approve.noMore"
      fetchUrl="/notifications"
    />
  );
};
