import { useSelector } from 'react-redux';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { getUser } from '../../redux/slices/AuthUser';
import { useEffect, useState } from 'react';
import axiosInstance from '../../axios/axiosInstance';
import { useContext } from 'react';
import { UserFavoritesContext } from '../../context';
import { useShowAlert } from '../../hooks/useShowAlert';
export const ControlFavoriteProduct = ({ product }) => {
    const { showAlert } = useShowAlert();
    const values = useContext(UserFavoritesContext);
    const authUser = useSelector(getUser); // get user from redux
    const [isFavorite, setIsFavorite] = useState(false)

    // add product to favorite
    const addProductToFavorite = () => {
        axiosInstance.post('/products/add-to-favorites', {
            userId: authUser?.id,
            productId: product?._id
        }).then((res) => {
            showAlert('success', res.data.message)
            setIsFavorite(true)

        }).catch(err => {
            showAlert('error', err.response.data.error)
        })
    }
    // remove product from favorite
    const removeProductFromFavorite = () => {
        axiosInstance.post('/products/remove-from-favorites', {
            userId: authUser?.id,
            productId: product._id
        }).then((res) => {
            showAlert('success', res.data.message)
            setIsFavorite(false)
            values?.handleUpdateProducts() // update favorite products list
        }).catch(err => {
            showAlert('error', err.response.data.error)

        })
    }
    const controlFaivoriteProduct = () => {
        if (isFavorite) {
            removeProductFromFavorite()
        } else {
            addProductToFavorite()
        }
    }

    // check if product is favorite
    useEffect(() => {
        const checkProductIsFavorite = () => {
            axiosInstance.post('/products/is-favorited', {
                userId: authUser?.id,
                productId: product ? product._id : null
            }).then(res => {
                setIsFavorite(res.data.isFavorited)
            })
        }
        if (authUser?.id) {
            checkProductIsFavorite()
        }

    }, [authUser?.id, product])


    return (
        <FavoriteIcon onClick={controlFaivoriteProduct} sx={{ fontSize: "35px", color: isFavorite ? "primary.main" : "gray", cursor: "pointer", }}
        />
    )
}
