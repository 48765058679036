import { Box, Stack, Typography, } from '@mui/material'
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/slices/AuthUser';
import axiosInstance from '../../axios/axiosInstance';
import { MessagesSkeleton } from '../general/loading/MessagesSkeleton';
import { MessageItem } from './MessageItem';
import { useTranslation } from 'react-i18next';
export const MessagesHistory = () => {
    const { t } = useTranslation("chat");
    const theme = useTheme();
    const userId = useSelector(getUser)?.id; // get user id from redux
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            setLoading(true);
            try {
                const response = await axiosInstance.get(`/messages/${userId}`);
                setMessages(response.data.messages);
            }finally {
                setLoading(false);
            }
        };
        fetchMessages();
    }, [userId]);

    return (
        <Box sx={{
            height: "100vh",
            width: "100%",
            overflowY: "scroll",
            border: "1px solid #ccc",
            borderRadius: "20px",
            padding: "20px",
            display: messages.length > 0 ? "block" : "flex",
            alignItems: "center",
            justifyContent: "center",
            "&::-webkit-scrollbar": {
                width: "0.5em"
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "20px"
            }
        }}>
            <Stack spacing={1} sx={{ width: "100%" }} >
                {
                    loading ? (
                        Array.from({ length: 6 }).map((_, index) => (
                            <MessagesSkeleton key={index} />
                        ))
                    ) : (
                        messages.length > 0 ? (
                            messages.map(message => (
                                <MessageItem key={message._id} message={message} theme={theme} />
                            ))
                        ) : (
                            <Typography variant="h6" component="div" sx={{ color: "primary.main", textAlign: "center" }}>
                                {t("noMessages")}
                            </Typography>
                        )
                    )
                }
            </Stack>
        </Box>
    );
}