import { useState } from "react";
import { useShowAlert } from "../../../hooks/useShowAlert";
import axiosInstance from "../../../axios/axiosInstance";
import { useNavigate } from "react-router-dom";

export const useSendMailResetPassword = () => {
    const navigate = useNavigate()
    const { showAlert } = useShowAlert();
    const [loading, setLoading] = useState(false)
    // send Email to reset password
    const SendToken = (values) => {
      setLoading(true)
        axiosInstance.post("/user/generate-reset-token", values
        ).then((res) => {
            showAlert("success", res.data.message)
            navigate("/reset-password/new-password")
        }).catch(err => {
            showAlert("error", err.response.data.error)
        }).finally(() => {
            setLoading(false)
        })
    }
    return { SendToken, loading }
}
