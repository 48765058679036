import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const useContactUsValidation = () => {
  const { t } = useTranslation("contactUs");
  const contactUsValidationSchema = Yup.object().shape({
    name: Yup.string().required(t("contactUs.formErrors.name.required")),
    email: Yup.string().email(t("contactUs.formErrors.email.invalid")).required(t("contactUs.formErrors.email.required")),
    phone: Yup.string().required(t("contactUs.formErrors.phone.required")),
    message: Yup.string().required(t("contactUs.formErrors.message.required")),
  });
  return { contactUsValidationSchema };
};

