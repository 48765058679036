import { Stack, Box, Typography } from "@mui/material";
import { countrys } from "../../../helper/Countrys";
import { states } from "../../../helper/HandelStates";
import { useTranslation } from "react-i18next";
import CustomSelectBox from "../../../formik/CustomSelectBox";
import CustomFormikField from "../../../formik/CustomFormikField";
import { useFormikContext } from "formik";

const StateField = ({t}) => {
  const { values } = useFormikContext();
  const stateOptions = states(values.country).map(state => ({
    value: state,
    label: state
  }));

  return (
    <CustomSelectBox
      name="state"
      label={t("save-address.labels.State")}
      options={stateOptions}
    />
  );
};

export const AddressInput = () => {
  const { t } = useTranslation("settings");
  const countryOptions = countrys.map(country => ({
    value: country,
    label: country
  }));
  return (
    <Box spacing={2} p={2} sx={{ borderRadius: "10px", backgroundColor: "#F7F7F7" }}>
      <Typography variant="h6" sx={{ color: "secondary.main", fontWeight: "500", py: 2 }}>
        {t("save-address.home")}
      </Typography>
    
        <Stack spacing={2}>
          <CustomSelectBox
            name="country"
            label={t("save-address.labels.Country")}
            options={countryOptions}
          />
          <CustomFormikField
            name="address"
            label={t("save-address.labels.Address")}
          />
          <CustomFormikField
            name="apartment"
            label={t("save-address.labels.Apartment")}
          />
          <CustomFormikField
            name="city"
            label={t("save-address.labels.City")}
          />
          <StateField t={t} />
          <CustomFormikField
            name="zipCode"
            label={t("save-address.labels.ZipCode")}
          />
        </Stack>
      
    </Box>
  );
};
