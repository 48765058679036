import { useContext } from "react";
import { UsersTable } from "./UsersTable";
import { UserContext } from "../../../context";
import { TableLoading } from "../../general/loading/TableLoading";
import axiosInstance from "../../../axios/axiosInstance";
import InfiniteScrollList from "../../general/InfiniteScrollLis";
const GetAllUsers = () => {
  const { search } = useContext(UserContext); // Get the search value from the context to send it to the backend

  // Fetch initial user when the component mounts
  const fetchData = async (page, limit) => {
    const response = await axiosInstance.post(`/admin/users?page=${page}&limit=${limit}`, { name: search.searchValue });
    return response.data.users;
  };

  return (
    <InfiniteScrollList
      fetchData={fetchData}
      loadingText={"loading more users"}
      noProductsText={"No users found"}
      LoadingComponet={TableLoading}
      RenderComponent={UsersTable}
    />
  );
};
export default GetAllUsers;
