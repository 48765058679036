import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useEditProductValidation = () => {
  const { t } = useTranslation("editProduct");
  const editProductValidationSchema = yup.object({
    category: yup.string().required(t("formErrors.category.required")),
    name: yup.string().required(t("formErrors.name.required")),
    price: yup.number().required(t("formErrors.price.required")).positive(t("formErrors.price.positive")),
    per: yup.string().required(t("formErrors.per.required")),
    description: yup.string().required(t("formErrors.description.required")),
    condition: yup.string().required(t("formErrors.condition.required")),
    available: yup.string().required(t("formErrors.available.required")),
  });
  return { editProductValidationSchema };
};
