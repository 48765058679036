import {  Skeleton, Stack } from "@mui/material"


export const ProductDetailsInfoSkeleton = () => {
  return (
    <Stack spacing={1} sx={{width:"100%"}}>
        <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}}/>
        <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}}/>

    </Stack>
  )
}
