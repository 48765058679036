import { Box, } from "@mui/material"
import { PopularProducts } from "../components/Home/popular-products/ListProducts"
import { Earning } from "../components/Home/Earning/Earning"
import { StartRent } from "../components/Home/StartRent/StartRent"
import { HowItWork } from "../components/Home/HowItWork/HowItWork"
import { YourBack } from "../components/Home/YourBack/YourBack"
import { DounloadApp } from "../components/Home/DounloadApp/DounloadApp"
/* import { Testimonials } from "../components/Home/Testimonials/Testimonials" */
import { HeroSection } from "../components/Home/heroSection/heroSection"
import UserDetailsDialog from "../components/general/user-details-dialog/UserDetailsDialog"
import { CategoriesList } from "../components/Home/categories/CategoriesList"
import { Referrals } from "../components/Home/Referrals/Referrals"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"


export const Home = () => {
  const { hash } = useLocation();
  useEffect(() => { // to scroll to the section with the id in the hash when the component mounts
    if (hash) {
      const scrollToElement = () => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      };

      // Delay scrolling to ensure element is rendered
      setTimeout(scrollToElement, 400);
    }
  }, [hash]);

  return (
    <Box sx={{ width: "100%" }}>
      <UserDetailsDialog />
      <HeroSection />
      <CategoriesList />
      <PopularProducts />
      <Earning />
      <StartRent />
      <HowItWork />
      <Referrals />
      <YourBack />
      <DounloadApp />
      {/*  <Testimonials /> */}

    </Box>

  )
}
