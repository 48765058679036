import { useDispatch } from "react-redux";
import { useCallback,useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { fetchUser, setAuthUser } from "../../../redux/slices/AuthUser";
import { decodeToken } from "../../../helper/decodeToken";
import { showPopup } from "./showPopup";
import useNavigateBasedOnPath from "../helper/checkPrevPath";
import { useShowAlert } from "../../../hooks/useShowAlert";

export const useSubmitForm = () => {
    const [loading, setLoading] = useState(false);
    const { showAlert } = useShowAlert();
    const dispatch = useDispatch();
    const navigateBasedOnPath = useNavigateBasedOnPath();
    const submitForm = useCallback(async (values) => {
        setLoading(true);
        // Determine if the identifier is an email or phone number
        const isEmail = values.identifier.includes("@") || values.identifier.includes(".");
        // Prepare the payload
        const payload = {
            password: values.password,
            identifier: isEmail ? values.identifier : `${values.countryCode}${values.identifier}`
        };

        try {
            const res = await axiosInstance.post("/user/login", payload);
            const { id, exp } = await decodeToken(res.data?.token);
            dispatch(setAuthUser({ user: null, exp: exp, id: id })); // Set user to redux
            localStorage.setItem("token", res.data?.token);

            dispatch(fetchUser())
                .then((res) => {
                    const user = res.payload;
                    showPopup(user, dispatch); // Show dialog if user does not have required information
                    navigateBasedOnPath(); // Check prev path to navigate to the correct path
                })
                .catch((err) => {
                    showAlert("error", err.response.data.error);
                })
                .finally(() => {
                    setLoading(false);
                })
        } catch (err) {
            showAlert("error", err.response?.data?.error || "An error occurred");
            setLoading(false); // Add this line to set loading to false on error
        }
        finally{
            setLoading(false);
        }
       
    }, [dispatch, navigateBasedOnPath, showAlert]);

    return { submitForm, loading };
};
