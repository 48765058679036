import InfiniteScrollList from "../general/InfiniteScrollLis";
import { ProductItem } from "../general/ProductItem";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { SkeletonLoadingProducts } from "../general/loading/SkeletonLoadingProducts";

const PopularProductsList = () => {
  const { t } = useTranslation("popularProducts");
  const limit = 10;
  // Function to fetch products
  const fetchProducts = async (page, limit) => {
    try {
      const response = await axiosInstance.get(
        `/products/popular-products?page=${page}&limit=${limit}`
      );
      if (response.status === 200) {
        return response.data.popularProducts;
      }
    } catch (error) {
      // Handle error
      if (error.response?.status === 404) {
        return []; // Return empty array to signify no more products
      }
    }
    return []; // Return empty array if there's an issue
  };
  const ProductItems = ({ items }) => {
    return (
      <Grid container spacing={2}>
        {items.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4} >
            <ProductItem product={product} />
          </Grid>
        ))}
      </Grid>
    );
  }
  const LoadingProducts = () => {
    return (
      <Grid container spacing={2}>
        {[...Array(6)].map((_, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} >
            <SkeletonLoadingProducts />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <InfiniteScrollList
      fetchData={fetchProducts}
      loadingText={t("more")}
      noProductsText={t("notFound")}
      limit={limit}
      initialPage={1}
      LoadingComponet={LoadingProducts}
      RenderComponent={ProductItems}
    />
  );
};

export default PopularProductsList;
