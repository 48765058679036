import { Button, Typography, Box, Stack } from "@mui/material";
import { useMemo, useState, useEffect } from "react";
import dayjs from "dayjs";
import { NotifyMe } from "../NotifyMe";
import { useSubmit } from "./helper/UseSubmit";
import { calculateEndDate } from "./helper/helper_fun";
import { useTranslation } from "react-i18next";
import { useRentDateValidation } from "../../../validation/useRentDateValidation";
import BaseForm from "../../../formik/BaseForm";
import CustomFormikField from "../../../formik/CustomFormikField";
import { useFormikContext } from "formik";
import CustomDatePicker from "../../../formik/CustomDatePicker";

const RentDateFields = ({ product, isDateDisabled }) => {
  const { t } = useTranslation("product-details");
  const [endDate, setEndDate] = useState(null);
  const { values, setFieldValue } = useFormikContext();
  const today = new Date();

  useEffect(() => {
    if (values.From && values.number && product?.per !== "Day") {
      const calculatedEndDate = calculateEndDate(values.From, values.number, product.per);
      setEndDate(calculatedEndDate);
      setFieldValue("To", calculatedEndDate);
    }
  }, [values.From, values.number, product?.per, setFieldValue]);

  return (
    <Box sx={{ width: "100%", backgroundColor: "#F7F7F7", p: 2, borderRadius: "5px" }}>
      <Typography variant="h6" component="h2" color="textPrimary" mb={2}>
        {t("rentDate.startRent")}
      </Typography>
      <Stack spacing={2}> 
        <CustomDatePicker
          name="From"
          label={t("rentDate.formLabels.from")}
          shouldDisableDate={isDateDisabled}
          disabled={!product?.available}
        />
        {product?.per === "Day" ? (
          <CustomDatePicker
            name="To"
            label={t("rentDate.formLabels.to")}
            minDate={values.From ? dayjs(values.From).add(1, 'day') : dayjs(today)}
            shouldDisableDate={isDateDisabled}
            disabled={!product?.available}
          />
        ) : (
          <CustomFormikField
            name="number"
            label={`Number of ${product?.per}`}
            type="number"
            disabled={!product?.available}
          />
        )}
        {endDate && product?.per !== "Day" && (
          <Typography variant="body2" color="textSecondary">
            {t("rentDate.endDate")} {dayjs(endDate).format("ddd, MMM DD, YYYY")}
          </Typography>
        )}
        <NotifyMe />
        {!product?.available && (
          <Typography variant="caption" component="p" color="error">
            {t("notAvailable")}
          </Typography>
        )}
      </Stack>
    </Box>
  );
};

export const RentDate = ({ product }) => {
  const { t } = useTranslation("product-details");
  const initialValues = {
    From: null,
    To: null,
    number: "",
  };

  const { onSubmit, GoChatPage, loading } = useSubmit(product);
  const validationSchema = useRentDateValidation(product);

  // memoize the disabledDates computation
  const disabledDates = useMemo(
    () =>
      product?.unavailability?.map(({ start_date, end_date }) => ({
        start: dayjs(start_date),
        end: dayjs(end_date),
      })),
    [product?.unavailability]
  );

  const isDateDisabled = (date) => {
    const selectedDate = dayjs(date);
    return disabledDates.some(
      ({ start, end }) =>
        selectedDate.isBetween(
          start.startOf("day"),
          end.endOf("day"),
          { inclusive: "[]" }
        ) || selectedDate.isSame(start.startOf("day"))
    );
  };

  return (
    <BaseForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <RentDateFields product={product} isDateDisabled={isDateDisabled} />
      <Stack spacing={2} sx={{ my: 3 }}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{ backgroundColor: "#F7F7F7", color: "secondary.main", "&:hover": { backgroundColor: "#F7F7F7", color: "secondary.main" } }}
          onClick={() => GoChatPage(product?.userID)}
        >
          {t("chat")}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="large"
          type="submit"
          disabled={!product?.available || loading}
        >
          {t("rentBtn")}
        </Button>
      </Stack>
    </BaseForm>
  );
};