import { Skeleton, Stack } from '@mui/material'

export const SkeletonLoading = ({loading}) => {
  return (
    loading && (
        <Stack spacing={2}>
            {[1, 2, 3, 4].map((item) => (
                <Skeleton key={item} variant="rectangular" height={100} sx={{ borderRadius: "5px" }} />
            ))}
        </Stack>
    )
  )
}

