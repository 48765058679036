import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTop = () => {
    const { hash,pathname } = useLocation();

    useEffect(() => { 
      if(!hash){
        window.scrollTo(0, 0);
        }
        if (hash) {
            const scrollToElement = () => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            };

            // Delay scrolling to ensure element is rendered
            setTimeout(scrollToElement, 400);
        }
    }, [hash,pathname]);
};