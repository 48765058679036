import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
export const TableLoading = () => {
    return (
        <Grid container spacing={2}>
        {[...Array(10)].map((_, index) => (
        <Grid item key={index} xs={12} >
            <Skeleton variant="rectangular" width="100%" height={30} />
          </Grid>
        ))}
      </Grid>
    );
}