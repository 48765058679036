import { useShowAlert } from "../../../hooks/useShowAlert";
import axiosInstance from "../../../axios/axiosInstance";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const useVerifyAcountSubmit = (method) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { showAlert } = useShowAlert();
  // handle send verification code
  const sendVerificationCode = (values) => {
    setLoading(true);
    if (method === "email") {
      axiosInstance
        .post(
          "/user/verify-email",
          { verifyToken: values.code }
        )
        .then(() => {
          navigate("/login");
        })
        .catch((err) => {
          showAlert("error", err.response.data.error);
        }).finally(() => {
          setLoading(false);
        })

    } else {
      axiosInstance
        .post(
          "/user/verify-phone",
          { verifyToken: values.code }
        )
        .then(() => {
          navigate("/login");
        })
        .catch((err) => {
          if (err) {
            showAlert("error", err.response.data.err);

          }
        }).finally(() => {
          setLoading(false);
        })
    }
  };

  return { loading, sendVerificationCode };
}
