import { Box, Typography } from '@mui/material'
import BaseForm from  "../../formik/BaseForm";
import CustomFormikField from "../../formik/CustomFormikField";
import SubmitButton from "../../formik/SubmitButton";
import { useEditComment } from './hooks/editComment';
import { useCommentValidation } from '../../validation/CommentValidation';
import { useTranslation } from 'react-i18next';
import { useGetComment } from './hooks/getComment';
const EditCommentForm = () => {
  const { handleSubmit, loading } = useEditComment();
  const {validationSchema} = useCommentValidation();
  const { t } = useTranslation("comments-reviews");
  const {comment} = useGetComment();
  return (
    <Box sx={{backgroundColor: '#F4EFEA', p: 3, borderRadius: '10px'}}>
      <Typography variant="h6" sx={{ color: 'secondary.main', fontFamily: 'Poppins-Bold', pb: 2 }}>
        {t("comment.edit.title")}
      </Typography>
        <BaseForm
            initialValues={{ comment: comment || '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <CustomFormikField name="comment" label={t("comment.edit.formLabels.comment")} type="text" rows={4} />
            <SubmitButton  disabled={loading}>
                {t("comment.edit.formSubmit")}
            </SubmitButton>
        </BaseForm>
    </Box>
  )
}

export default EditCommentForm