import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';
export const ImageBox = ({ picture, i, loading, handleImageChange, deleteImage }) => (
    <Box key={i} sx={{ position: "relative", width: ["135px", "150px"], height: "100px", border: "1px solid #8080801f", borderRadius: "5px", boxShadow: "0px 0px 3px 0px #8080807c" }}>
        <img src={picture} alt="product" style={{ width: "100%", height: "100%", borderRadius: "5px" }} />
        {loading ? (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    padding: "10px",
                    borderRadius: "5px",
                }}
            >
                Loading...
            </Box>
        ) : null}
        <Box
            sx={{
                position: "absolute",
                top: "0",
                right: "5px",
                color: "primary.main",
                backgroundColor: "white",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }}
            onClick={() => deleteImage(i)}
        >
            <DeleteIcon />
        </Box>
        <Box
            sx={{
                position: "absolute",
                top: "0",
                right: "50px",
                color: "secondary.main",
                backgroundColor: "white",
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }}
        >
            <Box sx={{ position: "relative" }}>
                <label htmlFor={`upload-image-${i}`} style={{ cursor: "pointer" }}>
                    <UpgradeIcon />
                    <input
                        id={`upload-image-${i}`}
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageChange(e, i)}
                        style={{ display: "none" }}
                    />
                </label>
            </Box>
        </Box>
    </Box>
);