import InfiniteScrollList from "../general/InfiniteScrollLis";
import { ProductItem } from "../general/ProductItem";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { SkeletonLoadingProducts } from "../general/loading/SkeletonLoadingProducts";
export const ProductsListByCategories = ({ activeFilter, apiFilterUrl }) => {
  const { t } = useTranslation("shop");

  // Fetch products by categories
  const fetchProductsByCategories = async (page, limit) => {
    try {
      const response = await axiosInstance.post(
        `${apiFilterUrl}?page=${page}&limit=${limit}`,
        activeFilter,
        { headers: { "Content-Type": "application/json" } }
      );
      return response.data.products;
    } catch (error) {
      return []; // Handle errors as needed
    }
  };
  const ProductItems = ({ items }) => {
    return (
      <Grid container spacing={2}>
        {items.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4} >
            <ProductItem product={product} />
          </Grid>
        ))}
      </Grid>
    );
  }
  const LoadingProducts = () => {
    return (
      <Grid container spacing={2}>
        {[...Array(6)].map((_, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} >
            <SkeletonLoadingProducts />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <InfiniteScrollList
      fetchData={fetchProductsByCategories}
      loadingText={t("loadingText")}
      noProductsText={t("noProducts")}
      initialPage={1}
      LoadingComponet={LoadingProducts}
      RenderComponent={ProductItems}

    />
  );
};
