import { Box } from "@mui/material";
import { useState } from "react";
import { SearchByName } from "../../components/admin/user/SearchByName";
import { UserContext } from "../../context";
import  GetAllUsers  from "../../components/admin/user/GetAllUsers";
export const AllUsers = () => {
  const [searchValue, setSearchValue] = useState("");
  // Change the search value in the context
  const changeSearchValue = (value) => {
    setSearchValue(value);
  };

  return (
    <Box
    sx={{width:"100%"}}
    >
      <UserContext.Provider
        value={{
          search: {
            searchValue: searchValue,
            changeSearchValue: changeSearchValue,
          },
        }}
      >
        <SearchByName />
        <br />
        <br />
        <GetAllUsers />
      </UserContext.Provider>
    </Box>
  );
};

