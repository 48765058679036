import { IconButton, Stack } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear"; // Import Clear icon
import { styled, useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { setSearchByName } from '../../redux/slices/filterSlice';
import { useTranslation } from 'react-i18next';
// Styled input component
const StyledInput = styled('input')({
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    outline: "none",
    border: "none",
    backgroundColor: "transparent",
});

export const Search = () => {
    const { t } = useTranslation("shop");
    const curruntSearch = useSelector((state) => state.filter.filterKeys)?.name;
    const [search, setSearch] = useState(!curruntSearch ? "" : curruntSearch);
    const dispatch = useDispatch();
    const theme = useTheme();

    // handle change search
    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    // handle clear search
    const handleClearSearch = () => {
        setSearch("");
        dispatch(setSearchByName(""));
    };

    // useEffect with debounce to dispatch the search action
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (search === "") {
                dispatch(setSearchByName(""));
            } else {
                dispatch(setSearchByName(search));
            }
        }, 500); // 500ms debounce time

        return () => clearTimeout(delayDebounceFn); // Cleanup the timeout on component unmount or if search changes
    }, [search, dispatch]);

    useEffect(() => {
        // update search value depend on the redux state
        setSearch(curruntSearch);
    }, [curruntSearch]);
    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: "60px",
                backgroundColor: "rgb(247, 247, 247)",
                borderRadius: "20px",
                px: 3,
                width: ["100%", "50%"],
                margin: "auto",
                position: "relative",
            }}
        >
            <IconButton
                aria-label="searchIcone"
                sx={{
                    color: "",
                }}
            >
                <SearchIcon aria-label="searchIcone" />
            </IconButton>

            <StyledInput
                value={search || ""}
                type="text"
                placeholder={t("search")}
                onChange={handleChangeSearch}
            />

            {search && (
                <IconButton
                    aria-label="clearSearch"
                    onClick={handleClearSearch}
                    sx={{
                        color: "",
                    }}
                >
                    <ClearIcon />
                </IconButton>
            )}

            <TuneIcon style={{ color: theme.palette.primary.main }} />
        </Stack>
    );
};
