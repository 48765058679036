import { Field, ErrorMessage } from 'formik';
import { MenuItem, FormHelperText } from '@mui/material';
import { Select } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)({
    color: "secondary.main", backgroundColor: "white", borderRadius: "10px",
    ".MuiOutlinedInput-notchedOutline": { border: 0 },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
        border: 0,
    },
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        border: 0,
    },
});

const CustomSelectBox = ({ name, label, options, ...props }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const selectedOption = options.find(option => option.value === field.value);

                return (
                    <>
                        <CustomSelect
                            {...field}
                            displayEmpty
                            renderValue={(selected) => {
                                return selected ? selectedOption?.label || label : label;
                            }}
                            size="medium"
                            error={form.touched[name] && Boolean(form.errors[name])}
                            fullWidth
                            {...props}
                        >
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </CustomSelect>
                        <ErrorMessage name={name}>
                            {(msg) => <FormHelperText error>{msg}</FormHelperText>}
                        </ErrorMessage>
                    </>
                );
            }}
        </Field>
    );
};

export default CustomSelectBox;
