import { Box,Container, Typography, } from "@mui/material";
import { ProductFields } from "../components/edit-product/ProductFields";
import { EditImages } from "../components/edit-product/editImages/EditImages";
import { useTranslation } from "react-i18next";
export const EditProducts = () => {
    const { t } = useTranslation("editProduct");
    return (
        <Box sx={{ py: 5 }}>
            <Container maxWidth="md">
                <Typography variant="h4" sx={{ color: "primary.main", fontWeight: "bold", textAlign: "center", my: 5,fontFamily: "Poppins-Bold" }}>
                    {t("title")}
                </Typography>
                <ProductFields />
                <Typography variant="h6" sx={{ mt: 3, color: "secondary.main", my: 3, fontWeight: "bold" }}>
                    {t("editImages")}
                </Typography>

                <EditImages />
            </Container>

        </Box>
    );
};

