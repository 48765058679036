import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export const MethodSignUp = ({signUpmethod,setSignUpMethod}) => {
  const { t } = useTranslation("signup");
  return (
    <div>
          { // if user want to login with email show login with phone number button and vice versa
              signUpmethod === "email" ? (
                <Typography variant="body1" component="h6" onClick={() => setSignUpMethod("phone")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer", }}>
                  {t("signUpMethod.phone")}
                </Typography>
              ) : (
                <Typography variant="body1" component="h6" onClick={() => setSignUpMethod("email")} sx={{ mt: 2, color: "white", textAlign: "center", cursor: "pointer" }}>

                  {t("signUpMethod.email")}
                </Typography>
              )
            }
            <Typography variant="body2" sx={{ textAlign: "center", mt: 2, color: "white" }}>
             {t("signUpMethod.agree")}
              <Link to="/" style={{ color: "white", paddingLeft: "5px" }}> {t("signUpMethod.privacy")} </Link>.
            </Typography>
    </div>
  )
}
