import "./styleLoadingApp.css";
export const Loading = () => {
  return (
    <div className="center">
      <span className="letter">D</span>
      <span className="letter">i</span>
      <span className="letter">r</span>
      <span className="letter">e</span>
      <span className="letter">c</span>
      <span className="letter">t</span>
      <span className="letter"> </span>
      <span className="letter">R</span>
      <span className="letter">e</span>
      <span className="letter">n</span>
      <span className="letter">t</span>
    </div>
  )
};
