import { Box, Container, Grid, Typography } from '@mui/material'
import { UserInfo } from '../components/profile/UserInfo'
import { NavigationProducts } from '../components/profile/tabs/NavigationProducts'
import { useTranslation } from 'react-i18next'
export const Profile = () => {
    const { t } = useTranslation("profile");
  return (
   <Box py={8}>
    <Container maxWidth="lg">
        <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold" }}>
            {t("title")}
        </Typography>

        <Grid container spacing={8} py={5}>
            <Grid item xs={12} md={5}>
                 <UserInfo/>
            </Grid>
            <Grid item xs={12} md={7}>
             <NavigationProducts/>
            </Grid>
        </Grid>
        </Container>

   </Box>
  )
}
