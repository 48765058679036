import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../../formik/SubmitButton';
import { Stack } from '@mui/material';
import CustomFormikField from '../../../formik/CustomFormikField';
import PasswordInput from '../../../formik/PasswordInput';
export const FieldInput = ({ loading }) => {
    const { t } = useTranslation("referralSignup");
    return (
        <Stack spacing={2}>
            <CustomFormikField name="username" placeholder={t("formLabels.username")} icon={<PermContactCalendarIcon sx={{ fontSize: "15px" }} />}  />
            <CustomFormikField name="email" placeholder={t("formLabels.email")} icon={<MailOutlineIcon sx={{ fontSize: "15px" }} />}  />
            <PasswordInput name="password" placeholder={t("formLabels.password")}  />
            <PasswordInput name="repeatPassword" placeholder={t("formLabels.confirmPassword")} />
            <CustomFormikField name="referral" placeholder={t("formLabels.referralCode")} icon={<Diversity3Icon sx={{ fontSize: "15px" }} />} />
            <SubmitButton disabled={loading}>
                {t("formLabels.signUp")}
            </SubmitButton>

        </Stack>
    );
};
