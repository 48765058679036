import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/slices/AuthUser";
import { CustomDialog } from "./helper/CustomDialog";
import useSubmitData from "./hook/useSumbitData";
import { useTranslation } from "react-i18next";
import { SelectLocation } from "../SelectLocation";
import BaseForm from "../../../formik/BaseForm";
import CustomFormikField from "../../../formik/CustomFormikField";
import SubmitButton from "../../../formik/SubmitButton";
import { usePopupProfileDetails } from "../../../validation/popup-profile-details";
import { ErrorMessage, useFormikContext } from "formik";
import { ProfileImage } from "./ProfileImage";

const FormContent = ({ authUser }) => {
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation("UserDetailsDialog");
  return (
    <Stack spacing={2}>
      <CustomFormikField
        name="firstName"
        label={t("formLabels.firstName")}
        type="text"
        size="small"
      />
      <CustomFormikField
        name="lastName"
        label={t("formLabels.lastName")}
        type="text"
        size="small"
      />
      <CustomFormikField
        name="displayName"
        label={t("formLabels.displayName")}
        type="text"
        size="small"
      />
      <CustomFormikField
        name="email"
        label={t("formLabels.email")}
        type="email"
        size="small"
        disabled={authUser?.email}
      />
      <CustomFormikField
        name="phoneNumber"
        label={t("formLabels.phoneNumber")}
        type="text"
        size="small"
        disabled={authUser?.phoneNumber}
      />
      <SelectLocation
        location={values.location || authUser.location}
        onLocationChange={(location) => {
          setFieldValue('location', location);
        }}
        mapId="e8b1b7b3b3b4b3b3hgc"
      />
      <ErrorMessage name="location" component={Box} sx={{ color: "red" }} />
      <ProfileImage />
    </Stack>
  );
};

const UserDetailsDialog = () => {
  const { t } = useTranslation("UserDetailsDialog");
  const { loading, handleSubmit, handleClose } = useSubmitData();
  const authUser = useSelector(getUser).user;
  const openDialog = useSelector((state) => state.openDialog.open);
  const { popupProfileDetails } = usePopupProfileDetails();

  const initialValues = {
    firstName: authUser?.firstName || "",
    lastName: authUser?.lastName || "",
    displayName: authUser?.displayName || "",
    email: authUser?.email || "",
    phoneNumber: authUser?.phoneNumber || "",
    location: authUser?.location || { lat: 0, lng: 0 },
    profilePicture: authUser?.profilePicture || "",
  };

  return (
    <CustomDialog open={openDialog} onClose={handleClose} scroll="paper">
      <DialogTitle id="scroll-dialog-title" sx={{ color: "primary.main", fontSize: ["16px", "25px"] }}>
        {t("title")}
      </DialogTitle>
      <DialogContent
        sx={{
          "&::-webkit-scrollbar": {
            width: "13px",
            boxShadow: "inset 0 0 5px grey",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "rgba(128, 128, 128, 0.375)",
            borderRadius: "10px",
          }
        }}
      >
        <DialogContentText
          sx={{ mt: 2, backgroundColor: "text.main",p:2, borderRadius: "10px" }}
          id="scroll-dialog-description"
          tabIndex={-1}
          component={"div"}
        >
          <BaseForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={popupProfileDetails}
            enableReinitialize={true}
          >
            <FormContent authUser={authUser} />
            <SubmitButton disabled={loading}>
              {t("submit")}
            </SubmitButton>
          </BaseForm>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          {t("skip")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default UserDetailsDialog;
