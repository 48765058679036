import { Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import useConfiguration from "../../components/admin/configuration/hooks/useConfiguration";

export const Configuration = () => {
  const {
    numberPopularProducts,
    additionalFees,
    tax,
    setNumberPopularProducts,
    setAdditionalFees,
    setTax,
    updateConfiguration,
    updatePopularProductsCount
  } = useConfiguration();

  // handle change additional fees
  const handleChangeAdditionalFees = (e) => {
    setAdditionalFees(e.target.value);
  };

  // handle change tax
  const handleChangeTax = (e) => {
    setTax(e.target.value);
  };

  // handle change number of popular products
  const handleChangeNumberPopularProducts = (e) => {
    setNumberPopularProducts(e.target.value);
  };

  return (
    <Container maxWidth="md" sx={{ my: 5 }}>
      <Typography variant="h5" component="h1" sx={{ fontWeight: "bold", pb: 2, textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
        Configuration
      </Typography>
      <Stack spacing={2} my={5}>
        {/* Additional fees */}
        <Box>
          <Typography variant="h6" component="h6" sx={{ pb: 2, color: "primary.main", fontSize: ["14px", "14px", "20px"], fontWeight: "bold" }}>
            Additional Fees
          </Typography>
          <Stack direction="row" sx={{ width: "100%" }}>
            <TextField type="number" value={additionalFees} fullWidth placeholder="Enter Additional Fees" onChange={handleChangeAdditionalFees} />
            <Button variant="contained" color="primary" size="large" onClick={() => updateConfiguration("additional fees", additionalFees)}>Update</Button>
          </Stack>
        </Box>
        {/* Tax */}
        <Box>
          <Typography variant="h6" component="h6" sx={{ pb: 2, color: "primary.main", fontSize: ["14px", "14px", "20px"], fontWeight: "bold" }}>
            Tax
          </Typography>
          <Stack direction="row" sx={{ width: "100%" }}>
            <TextField type="number" value={tax} fullWidth placeholder="Enter Tax" onChange={handleChangeTax} />
            <Button variant="contained" color="primary" size="large" onClick={() => updateConfiguration("tax", tax)}>Update</Button>
          </Stack>
        </Box>
        {/* Number of popular products */}
        <Box>
          <Typography variant="h6" component="h6" sx={{ pb: 2, color: "primary.main", fontSize: ["14px", "14px", "20px"], fontWeight: "bold" }}>
            Number of Popular Products
          </Typography>
          <Stack direction="row" sx={{ width: "100%" }}>
            <TextField type="number" value={numberPopularProducts} fullWidth placeholder="Enter Number of Popular Products" onChange={handleChangeNumberPopularProducts} />
            <Button variant="contained" color="primary" size="large" onClick={() => updatePopularProductsCount(numberPopularProducts)}>Update</Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};
