import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axiosInstance from "../../../axios/axiosInstance"
import { useTranslation } from "react-i18next"
export const AverageRating = () => {
  const { t } = useTranslation("rentProfile")
  const [average, setAverage] = useState(0)
  const params = useParams()
  useEffect(() => {
    const getRatingAvarege = () => {
      axiosInstance.post(`ratings/average-rating`, {
        id: params?.userId,
      }).then(res => {
        setAverage(res.data.rating)
      })
    }
    getRatingAvarege()
  }
    , [params?.userId])
  return (
    <Box sx={{ py: 3 }}>
      <Typography
        variant="h6"
        component="div"
        sx={{ color: "primary.main" }}

      >
        {t("Rating")}
      </Typography>
      <Typography
        variant="h4"
        component="div"
        sx={{ color: "secondary.main" }}
      >
        {average ? `${average + "." + 0}` : "0.0"}
      </Typography>
    </Box>
  )
}
