import { styled } from "@mui/material/styles";
import { TextField, Container, Button, Box } from "@mui/material";
import { useState, useContext } from "react";
import { UserContext } from "../../../context"; // Import the context

const CustomInput = styled(TextField)({
  "& .css-cg8wgx-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: 0,
  },
});

export const SearchByName = () => {
  const [searchValue, setSearchValue] = useState("");
  const { search } = useContext(UserContext); // Get the context set state function

  const handleChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  // Set the search value in the context
  const handleSubmit = () => {
    search.changeSearchValue(searchValue); // Set the search value in the context
    setSearchValue(""); // Clear the input after submitting
  };

  // Handle search on enter key
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <CustomInput
          type="text"
          placeholder="Search by name"
          onChange={handleChangeSearch}
          value={searchValue}
          onKeyDown={handleKeyDown}
          sx={{
            flexGrow: 1,
            maxWidth: { xs: "100%", sm: "75%" },
            mb: { xs: 2, sm: 0 },
          
          }}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{
            borderRadius: 0,
            py: 2,
            maxWidth: { xs: "100%", sm: "25%" },
          }}
          onClick={handleSubmit}
        >
          Search
        </Button>
      </Box>
    </Container>
  );
};
