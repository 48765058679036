import { Box, Button, Container, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
export const FailedPayment = () => {
  const { t } = useTranslation("failedPayment");
  const urlParams = new URLSearchParams(window.location.search);
  const transactionId = urlParams.get('transactionId');
  const navigate = useNavigate();
  // back payment page
  const handleBackPaymentPage = () => {
    navigate(`/payment/${transactionId}`);
  };
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh'
        }}
      >
        <ErrorOutlineIcon color="error" style={{ fontSize: 100 }} />
        <Typography variant="h4" component="div" gutterBottom>
          {t("title")}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t("message")}
        </Typography>
        <Button variant="contained" onClick={handleBackPaymentPage}>
          {t("btn")}
        </Button>
      </Box>
    </Container>
  );
}

