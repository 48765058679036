import { useEffect, useState } from 'react';
import axiosInstance from '../../../axios/axiosInstance';
import { Stack, Typography, Box } from '@mui/material';
import Item from './Item'; // Reusable Item component
import { ShowCommentAndReviewSkeleton } from '../../general/loading/ShowCommentAndReviewSkeleton';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ShowItems = ({ productID, type }) => {
    const { t } = useTranslation("product-details");
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const fetchItems = async () => {
            try {
                const res = await axiosInstance.get(
                    `/${type}/product${type === "reviews" ? "s" : ""}/${productID}`
                );
                setItems(res.data[`${type}`]); // Access the data dynamically
            } catch (_) { /**/ }
            finally {
                setLoading(false);
            }
        };

        fetchItems();
    }, [productID, type]);

    const handleEdit = (itemID) => {
        navigate(type === 'comments' ? `/edit-comment/${productID}/${itemID}` : `/edit-review/${productID}/${itemID}`);
    };

    const handleDelete = (itemID) => {
        setItems(prevItems => prevItems.filter(item => item._id !== itemID));
    };

    return (
        <>
            {
                !loading ? (
                    <Box className="scroll-comments-reviews">
                        <Stack spacing={2}>
                            {
                                items.length > 0 ? (
                                    items.map(item => (
                                        <Item
                                            key={item._id}
                                            item={item}
                                            type={type}
                                            onEdit={() => handleEdit(item._id)}
                                            onDelete={() => handleDelete(item._id)}
                                        />
                                    ))
                                ) : (
                                    <Typography variant="h6" color="primary.main">
                                        {t(`${type}.notFound`)}
                                    </Typography>
                                )
                            }
                        </Stack>
                    </Box>
                ) : (
                    <ShowCommentAndReviewSkeleton />
                )
            }
        </>
    );
};
