import { Box, Stack, } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchUser } from "./helper/fetchUser";
import { InputForm } from "./InputForm";
const EditUser = () => {
  const { userID } = useParams();
  const [user, setUser] = useState({
    username: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: {
      country: "",
      state: "",
      zipCode: "",
    },
  });
  // get user details 
  useFetchUser(userID, setUser);
  return (
    <Box>
      <form>
        <Stack spacing={2}>
          <InputForm user={user} setUser={setUser} userID={userID} />
        </Stack>
      </form>
    </Box>
  );
};
export default EditUser;
