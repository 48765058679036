import { useEffect, useState } from "react";
import axiosInstance from "../axios/axiosInstance";
import { useShowAlert } from "./useShowAlert";
export const useGetProduct = (id) => {
    const { showAlert } = useShowAlert();
    const [product, setProduct] = useState(null);
    useEffect(() => {
        // fetch product by id
        axiosInstance
            .get(
                `/products/${id}`
            )
            .then((res) => {
                setProduct(res.data.product);
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return product;
}