import { Dialog } from "@mui/material";
import { styled } from '@mui/material/styles';
export const CustomDialog = styled(Dialog)(() => ({
    "& .MuiPaper-root": {
      minHeight: "90vh",
      borderRadius: "50px",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.5) !important",
      backdropFilter: "blur(3px)",
    },
    '@media (max-width: 400px)': {
      "& .MuiPaper-root": {
        minWidth: window.innerWidth - 10 + "px"
      },
    },
    '@media (min-width: 768px)': {
      "& .MuiPaper-root": {
        minWidth: "600px"
      },
    },
  }));
  