import { useState } from "react"
import axiosInstance from "../../../axios/axiosInstance"
import { useShowAlert } from "../../../hooks/useShowAlert"  

export const useSendCodeReferral = () => {
    const { showAlert } = useShowAlert()
    const [loading, setLoading] = useState(false)
    const [sended, setSended] = useState(false)
    const handleSubmit = (values) => {
        setLoading(true)
        axiosInstance.post('user/referral/send-code', { friendEmail: values.email }).then((res) => {
            setSended(true)
            showAlert('success', res.data.message)
        }).catch((err) => {
            showAlert('error', err.response.data.error)
        }).finally(() => {
            setLoading(false)
        })
    }
    return { handleSubmit, loading, sended }
}
