import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { styled, useTheme } from "@mui/material/styles"
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ForRenter,ForOwner } from './steps'
export const HowItWork = () => {
    const { t } = useTranslation("home")
    const [renter, setRenter] = useState(true)
    const dataRender = renter ? ForRenter : ForOwner
    // change the state to show the owner
    const handleRenderOwner = () => {
        setRenter(false)
    }
    // change the state to show the renter
    const handleRenderRenter = () => {
        setRenter(true)
    }

    const theme = useTheme();
    const ButtonStyle = styled(Button)({
        backgroundColor: theme.palette.text.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.text.secondary,
        },
    });
    return (
        <Box id="howItWork" sx={{ backgroundColor: "secondary.main", py: 5 }}>
            <Container>
                <Typography variant="h4" sx={{ textAlign: "center", color: "text.main", fontFamily: "Poppins-Bold" }}>
                    {t("Howitworks.title")}
                </Typography>
                <Stack spacing={2} direction="row" sx={{ justifyContent: "center", my: 2 }}>
                    <Button variant="contained" onClick={handleRenderRenter}>
                        {t("Howitworks.btn1")}
                    </Button>
                    <ButtonStyle variant="contained" onClick={handleRenderOwner}>
                        {t("Howitworks.btn2")}
                    </ButtonStyle>
                </Stack>
                <Grid container spacing={5} sx={{ my: 4 }}>
                    {dataRender.map((item) => (
                        <Grid item xs={12} sm={4} key={item.title}>
                            <Box sx={{ textAlign: "center" }}>
                                <Box sx={{ width: "100px", height: "30px", margin: "auto", mb: 1 }}>
                                    <img src={item.imgUrl} alt="img" loading='lazy' style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                </Box>
                                <Typography variant="h5" sx={{ color: "text.main", fontWeight: "bold" }}>
                                    {t(item.title)}
                                </Typography>
                                <Typography sx={{ color: "text.main" }}>
                                    {t(item.description)}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}
