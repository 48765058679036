import { Box, Grid, Stack, Typography } from '@mui/material';
import uploadImage from '../../../assets/images/uploadImg.webp';
import { useTranslation } from 'react-i18next';
import { uploadImage as uploadImageToS3 } from '../../../helper/UploadImage';
import { useFormikContext, ErrorMessage } from 'formik';
import { useState } from 'react';

export const ProfileImage = () => {
  const { t } = useTranslation("UserDetailsDialog");
  const { setFieldValue } = useFormikContext();
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = async (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setUploadStatus('Uploading...');
      const imageUrl = await uploadImageToS3(file);
      if (imageUrl) {
        setFieldValue("profilePicture", imageUrl);
        setUploadStatus(`${t("Image uploaded successfully")} : ${file.name}`);
      } else {
        setUploadStatus(t("Upload failed. Please try again."));
      }
    }
  };

  return (
    <Grid item xs={12}>
      <Typography variant="body1" gutterBottom component="div">
        {t("Picture")}
      </Typography>
      <Box sx={{ border: "1px solid #80808066", p: 1, borderRadius: "10px" }}>
        <Stack spacing={2} direction="row">
          <Box position="relative">
            <input
              id="upload-file"
              type="file"
              onChange={handleFileChange}
              name="profilePicture"
              accept="image/*"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                opacity: 0,
                width: '100%',
                height: '100%',
                cursor: 'pointer'
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <img src={uploadImage} alt="upload" loading='lazy' style={{ width: "30px", height: "30px" }} />
              <Typography variant="body1" gutterBottom component="div" sx={{ fontSize: ["8px", "16px"] }}>
               {t("drag")}
              </Typography>
            </Stack>
          </Box>

          <Typography variant="body1" gutterBottom component="div" sx={{ color: "secondary.main", fontWeight: "bold" }}>
            {t("or")}
          </Typography>

          <label htmlFor="upload-file" style={{
            backgroundColor: "#FE1F02",
            color: "white",
            borderRadius: "5px",
            padding: "5px",
            cursor: "pointer",
            fontSize: "12px",
            textAlign: "center",
            width: "100px"
          }}>
            {t("select-file")}
          </label>
        </Stack>
      </Box>
      {uploadStatus && (
        <Typography variant="body2" sx={{ mt: 1, color: 'secoundry.main' }}>
          {uploadStatus}
        </Typography>
      )}
      <ErrorMessage name="profilePicture">
        {(msg) => (
          <Typography color="error" variant="caption" sx={{ mt: 1 }}>
            {msg}
          </Typography>
        )}
      </ErrorMessage>
    </Grid>
  );
};
