import axios from "axios";
import axiosInstance from "../axios/axiosInstance";
import { compressImage } from "./compressImage";

export const uploadImage = async (file) => {
    const response = await axiosInstance.post("/user/upload-image-url", {
      key: file.name,
      contentType: file.type
    });

    const presignedUrl = response.data.presignedUrl;
    const imageUrl = response.data.imgUrl;

    const compressedFile = await compressImage(file);

    const uploaded = await axios.put(presignedUrl, compressedFile, {
      headers: { 'Content-Type': file.type }
    });

    if (uploaded.status === 200) {
      return imageUrl;
    }
    return null;
  };
