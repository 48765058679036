import { Box, Container, Typography } from '@mui/material'
import { ContactUsForm } from '../components/contact-us/ContactUsForm'
import { ContactUsInfo } from '../components/contact-us/ContactUsInfo'
import { useTranslation } from 'react-i18next'
export const ContactUs = () => {
  const { t } = useTranslation("contactUs");
  return (

    <Box sx={{ pt: 8 }}>
      <Box>
        <Typography variant="h3" align="center" sx={{ color: "primary.main", mb: "70px", fontWeight: "bold", fontFamily: "Poppins-Bold" }}>
          {t("contactUs.title")}
        </Typography>
        <Box sx={{ backgroundColor: "text.main" }}>
          <Container maxWidth="md">
            <ContactUsForm />
            <ContactUsInfo />
          </Container>
        </Box>
      </Box>
    </Box>
  )
}


