import { Box, Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MessageForm from "../components/contact-with-user/MessageForm";
export const ContactWithUser = () => {
  const { t } = useTranslation("sendMessage");
  const navigate = useNavigate();
  const { username } = useParams();
  // handle go back button
  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Box>
      <Box sx={{ pt: [3, 8], paddingLeft: [3, 8] }}>
        <ArrowBackIcon sx={{ color: "primary.main", fontSize: "40px", cursor: "pointer", fontWeight: "bold" }} onClick={handleBack} />
      </Box>
      <Container maxWidth="md" sx={{ my: 5, }}>

        <Box sx={{ backgroundColor: "text.main", p: 3,borderRadius:3,maxWidth:700,margin:"auto" }}>
          <Typography variant="h6" component="h3" sx={{ pb: 1, color: "secondary.main", fontWeight: "bold" }}>
            {t("sendTo")}{" "}
            <Typography
              variant="h6"
              component="span"
              sx={{ color: "primary.main", display: "inline-block", px: 1, fontWeight: "bold" }}
            >
              {username}
            </Typography>
          </Typography>
          <MessageForm />
        </Box>
      </Container>
    </Box>
  );
};
