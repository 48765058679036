import { Box, Typography } from '@mui/material'
import BaseForm from  "../../formik/BaseForm";
import CustomFormikField from "../../formik/CustomFormikField";
import SubmitButton from "../../formik/SubmitButton";
import { useReviewValidation } from  "../../validation/reviewValidation"
import { useTranslation } from 'react-i18next';
import { useAddReview } from './hooks/addReview';
const AddReviewForm = () => {
  const { handleSubmit, loading } = useAddReview();
  const {validationSchema} = useReviewValidation();
  const { t } = useTranslation("comments-reviews");
  return (
    <Box sx={{backgroundColor: '#F4EFEA', p: 3, borderRadius: '10px'}}>
      <Typography variant="h6" sx={{ color: 'secondary.main', fontFamily: 'Poppins-Bold', pb: 2 }}>
            {t("review.add.title")}
      </Typography>
        <BaseForm
            initialValues={{ review: '' }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            <CustomFormikField name="review" label={t("review.add.formLabels.review")} type="text" rows={4} />
            <SubmitButton  disabled={loading}>
                {t("review.add.formSubmit")}
            </SubmitButton>
        </BaseForm>
    </Box>
  )
}

export default AddReviewForm