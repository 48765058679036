import { Box, Container, Typography } from "@mui/material";
import axiosInstance from "../../axios/axiosInstance";
import InfiniteScrollList from "../../components/general/InfiniteScrollLis";
import ReferralTable from "../../components/admin/referrals/ReferralTable";
import { TableLoading } from "../../components/general/loading/TableLoading";
export const UsersReferred = () => {
    const fetchData = async (page, limit) => {
        const response = await axiosInstance.get(`/admin/referrals?page=${page}&limit=${limit}`);
        return response.data.referrals;
    }

    return (
        <Box py={8}>
             <Typography variant="h5" component="h1" sx={{ fontWeight: "bold", pb: 8, textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
                Referred Users
            </Typography>
            <Container maxWidth="lg" sx={{ py: 3 }}>
                <InfiniteScrollList
                    fetchData={fetchData}
                    loadingText={"loading more Referrals"}
                    noProductsText={"No Referrals found"}
                    LoadingComponet={TableLoading}
                    RenderComponent={ReferralTable}
                />
            </Container>
        </Box>
    );
};
