import * as yup from "yup";

export const categoryValidationSchema = yup.object({
  name: yup.string().required("Category name is required"),
  picture: yup.mixed().required("Category picture is required"),
  nameTranslations: yup.object()
    .test(
      "valid-translations",
      "All translations must be non-empty strings",
      (value) => {
        if (!value) return true;
        return Object.values(value).every(text => 
          typeof text === 'string' && text.trim().length > 0
        );
      }
    )
    .nullable(),
});