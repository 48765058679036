import InfiniteScrollList from "../general/InfiniteScrollLis";
import { ProductItem } from "../general/ProductItem";
import axiosInstance from "../../axios/axiosInstance";
import { useSelector } from "react-redux";
import { getUser } from "../../redux/slices/AuthUser";
import { useTranslation } from "react-i18next";
import { UserFavoritesContext } from "../../context";
import { useState } from "react";
import { Grid } from "@mui/material";
import { SkeletonLoadingProducts } from "../general/loading/SkeletonLoadingProducts";

const FavoritesProductsList = () => {
  const { t } = useTranslation("favorites");
  const userId = useSelector(getUser)?.id;
  // Handle update products
  const [updateProducts, setUpdateProducts] = useState(false);
  const handleUpdateProducts = () => setUpdateProducts(prev => !prev);
  // Function to fetch products
  const fetchProducts = async (page, limit) => {
    try {
      const response = await axiosInstance.get(
        `/products/user/${userId}/favorite-products?page=${page}&limit=${limit}`
      );
      return response.data.favoriteProducts;
    } catch (error) {
      // Handle error
      return [];
    }
  };
  const ProductItems = ({ items }) => {
    return (
      <Grid container spacing={2}>
        {items.map((product) => (
          <Grid item key={product._id} xs={12} sm={6} md={4} >
            <ProductItem product={product} />
          </Grid>
        ))}
      </Grid>
    );
  }
  const LoadingProducts = () => {
    return (
      <Grid container spacing={2}>
        {[...Array(6)].map((_, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} >
            <SkeletonLoadingProducts />
          </Grid>
        ))}
      </Grid>
    );
  }
  return (
    <UserFavoritesContext.Provider value={{ updateProducts, handleUpdateProducts }}>
      <InfiniteScrollList
        fetchData={fetchProducts}
        loadingText={t("loadMore")}
        noProductsText={t("no-favorites")}
        initialPage={1}
        LoadingComponet={LoadingProducts}
        RenderComponent={ProductItems}
        updateTrigger={updateProducts}
      />
    </UserFavoritesContext.Provider>
  );
};

export default FavoritesProductsList;
