import { Container, Typography, } from "@mui/material";
import AddProductForm from "../components/add-product/AddProductForm";
import { useTranslation } from "react-i18next";
export const AddNewProduct = () => {
  const { t } = useTranslation("add-product");
  return (
    <Container sx={{ py: 5 }}>
      <Typography variant="h4" align="center" sx={{ mt: 2, mb: 5, color: "primary.main", fontWeight: "bold",fontFamily:"Poppins-Bold" }}>
        {t("title")}
      </Typography>
      <AddProductForm />
    </Container>
  );
};
