import { Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

export const HaveAcount = () => {
    const { t } = useTranslation("signup");
    const theme = useTheme();
  return (
    <Typography variant="body2" sx={{ textAlign: "center", mt: 3, color: "white" }}>
    {t("have-account")}{" "}
    <Link
        to="/login"
        style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
    >
        {t("login")}{" "}
    </Link>
</Typography>
  )
}
