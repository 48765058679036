import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
const UseOfServiceSection = ["eligibility", "limited_license", "accounts", "rules", "changes", "disputes"];
const TermsContent = () => {
  const { t } = useTranslation("terms-of-service")
  return (
    <Box sx={{ py: 8 }}>
      <section>
        <Typography variant="h5" sx={{ pb: 1, color: "primary.main" }}>
          {t("introduction.title")}
        </Typography>
        <Box>
          <p>{t("introduction.content")}</p>
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t("service.title")}
        </Typography>
        <Box>
          <p>{t("service.content")}</p>
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t("use_of_service.title")}
        </Typography>
        <Box>
          {UseOfServiceSection.map((section) => (
            <Typography variant="body1" sx={{ py: 2 }} key={section}>
              <strong>{t(`use_of_service.sections.${section}.title`)}</strong>
              <br />
              {t(`use_of_service.sections.${section}.content`)}
            </Typography>
          ))}
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t("user-content.title")}
        </Typography>
        <Box>

          {t("user-content.content")}
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('proprietary-rights.title')}
        </Typography>
        <Box>
          {t('proprietary-rights.content')}
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('terms-specific-for-owners.title')}
        </Typography>
        <Box>
          {Object.keys(t('terms-specific-for-owners.sections', { returnObjects: true })).map((key) => (
            <div key={key} id={key}>
              <Typography variant="body1" sx={{ py: 2 }}  >
                <Typography component="strong" variant="body1" sx={{ fontWeight: "bold", pb: 1, display: "inline-block" }}>
                  {t(`terms-specific-for-owners.sections.${key}.title`)}
                </Typography>
                <br />
                {t(`terms-specific-for-owners.sections.${key}.content`)}
              </Typography>
            </div>
          ))}
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('terms-specific-for-renters.title')}
        </Typography>
        <Box>
          {Object.keys(t('terms-specific-for-renters.sections', { returnObjects: true })).map((key) => (
            <div key={key}>
              <Typography variant="body1" sx={{ py: 2 }}>
                <Typography component="strong" variant="body1" sx={{ fontWeight: "bold", pb: 1, display: "inline-block" }}>
                  {t(`terms-specific-for-renters.sections.${key}.title`)}
                </Typography>
                <br />
                {t(`terms-specific-for-renters.sections.${key}.content`)}
              </Typography>
            </div>
          ))}
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('interactions-and-disputes1.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('interactions-and-disputes1.content')}
          </Typography>
        </Box>
      </section>
      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('interactions-and-disputes2.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('interactions-and-disputes2.content')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('fees-and-payment-terms.title')}
        </Typography>

        <Box>
          {Object.keys(t('fees-and-payment-terms.sections', { returnObjects: true })).map((key) => (
            <Typography key={key} variant="body1" sx={{ py: 2 }}>
              <Typography component="strong" variant="body1" sx={{ fontWeight: "bold", pb: 1, display: "inline-block" }}>
                {t(`fees-and-payment-terms.sections.${key}.title`)}
              </Typography>
              <br />
              {t(`fees-and-payment-terms.sections.${key}.content`)}
            </Typography>
          ))}
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('interactions-and-disputes3.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('interactions-and-disputes3.content')}
          </Typography>
        </Box>
      </section>


      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('text-messaging.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('text-messaging.content')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('security.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('security.content')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('user-content2.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('user-content2.section1')}
          </Typography>

          <Typography variant="body1" sx={{ py: 2 }}>
            {t('user-content2.section2.part1')}
            <br />
            {t('user-content2.section2.part2')}
            <br />
            {t('user-content2.section2.part3')}
            <br />
            {t('user-content2.section2.part4')}
            <br />
            {t('user-content2.section2.part5')}
          </Typography>

          <Typography variant="body1" sx={{ py: 2 }}>
            {t('user-content2.section3')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('third-party-links-and-information.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('third-party-links-and-information.content')}
          </Typography>
        </Box>
      </section>


      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('indemnity.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('indemnity.content')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('noWarranty.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('noWarranty.content')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('limitationOfLiability.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{ py: 2 }}>
            {t('limitationOfLiability.content')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('governingLawArbitration.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} id="governingLaw" name="governingLaw">
            {t('governingLawArbitration.governingLaw')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('governingLawArbitration.arbitration')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('governingLawArbitration.classActionWaiver')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('mobileApplicationsStoreTerms.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('mobileApplicationsStoreTerms.mobileApplications')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('mobileApplicationsStoreTerms.appleStore')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('mobileApplicationsStoreTerms.googlePlayStore')}
          </Typography>
        </Box>
      </section>

      <section>
        <Typography variant="h5" sx={{ py: 1 }}>
          {t('general.title')}
        </Typography>
        <Box>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('general.assignment')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('general.notificationProcedures')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('general.entireAgreement')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('general.noWaiver')}
          </Typography>
          <Typography variant="body1" sx={{
            py: 2,
            '&::first-letter': {
              fontWeight: 'bold'
            }
          }} >
            {t('general.contact')}
          </Typography>
        </Box>
      </section>


    </Box>
  )
}

export default TermsContent