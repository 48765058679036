import axiosInstance from "../../../axios/axiosInstance";
import useSetNotificationAsRead from "../hooks/SetNotificationAsReaded"
import { useContext } from "react";
import { NotificationContext } from "../../../context";
import { useShowAlert } from "../../../hooks/useShowAlert";

const usePendingActions = () => {
  const { showAlert } = useShowAlert();
  const { updateNotification } = useContext(NotificationContext);
  const setNotificationAsRead = useSetNotificationAsRead();

  const handleAccept = (request) => {
    axiosInstance
      .put(`/transactions/approve/${request.objectId}`, {})
      .then((res) => {
        showAlert("success", res.data.message);
        setNotificationAsRead(request._id);
        updateNotification();// update notification list
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      });
  };

  const handleReject = (request) => {
    axiosInstance
      .put(`/transactions/reject/${request.objectId}`, {})
      .then((res) => {
        showAlert("success", res.data.message);
        setNotificationAsRead(request._id);
        updateNotification(); // update notification list
      })
      .catch((err) => {
        showAlert("error", err.response.data.error);
      });
  };

  return { handleAccept, handleReject };
};

export default usePendingActions;
