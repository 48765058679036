import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector,useDispatch } from 'react-redux';
import { setClosePopup } from '../../redux/slices/EmailPopup';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../redux/slices/AuthUser';
import { useTranslation } from 'react-i18next';
export default function EmailPopup() {
  const userId = useSelector(getUser)?.id;
  const { t } = useTranslation("emailPopup");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openPopup, userType } = useSelector((state) => state.emailPopup);
  const handleClose = () => {
    dispatch(setClosePopup());
  };
  const handleUpdateEmail = () => {
    navigate(`/account-info/${userId}`);
    dispatch(setClosePopup());
  };

  return (
    <>
      <Dialog
        open={openPopup}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t("title")}
        </DialogTitle>
        <DialogContent sx={{textTransform:"capitalize"}}>
          <DialogContentText>
            {t(`descriptionFor.${userType}`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t("skip")}
          </Button>
          <Button onClick={handleUpdateEmail}>
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
