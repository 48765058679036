import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { MainComponent } from "./HandleMainComponent";
import { sentryInit } from "./helper/sentryInit";
import { i18nInit } from "./helper/I18nextInit";
import "./index.css";
// sentry configuration
if (import.meta.env.VITE_VERCEL_ENVIRONMENT !== "development") { // only initialize sentry in production
  sentryInit();
}
// initialize the i18next instance
i18nInit();
createRoot(document.getElementById("root")).render(
  <Sentry.ErrorBoundary>
    <MainComponent />
  </Sentry.ErrorBoundary>

);
