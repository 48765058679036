import axiosInstance from "../../../../../axios/axiosInstance";
import { useShowAlert } from "../../../../../hooks/useShowAlert";
export const useSubmitForm = (user, userID) => {
    const { showAlert } = useShowAlert();
    const handleSubmit = (e) => {
        e.preventDefault();

        if (user.username === "") {
            showAlert("error", "Username should not be empty");
            return;
        }

        axiosInstance
            .put(`/admin/user/${userID}`, user)
            .then(() => {
                showAlert("success", "User updated successfully");
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
            });
    };

    return handleSubmit;
};
