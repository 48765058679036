import { Box, Container, Grid, Typography } from "@mui/material"
import { items } from "./steps"
import { useTranslation } from "react-i18next"
export const YourBack = () => {
    const { t } = useTranslation("home")
    return (
        <Box sx={{ py: 4 }}>
            <Container>
                <Typography variant="h4" component={"h5"} align="center" sx={{ color: "primary.main", fontSize: ["30px", "40px"], fontWeight: "bold", fontFamily: "Poppins-Bold" }}>
                    {t("your-back.title")}
                </Typography>
                <Typography variant="h6" component={"h6"} align="center" sx={{ color: "secondary.main", pt: 2 }}>
                    {t("your-back.description")}
                </Typography>
                <Grid container spacing={"50px"} sx={{ my: 4 }}>
                    {items.map((item) => (
                        <Grid item xs={12} md={4} key={item.title}>
                            <Box sx={{ textAlign: "center" }}>
                                <Box sx={{ width: "100px", height: "80px", margin: "auto", mb: 1 }}>
                                    <img src={item.img} alt="img" loading="lazy" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                </Box>
                                <Typography variant="h5" sx={{ color: "secoundry.main", fontWeight: "bold" }}>{t(item.title)}</Typography>
                                <Typography sx={{ color: "secoundry.main", pt: 2 }}>{t(item.description)}</Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </Box>
    )
}
