import { useState } from 'react';
import { LoadingScreen } from '../LoadingScreen';
import { fetchUser, setAuthUser } from '../../../../redux/slices/AuthUser';
import { useDispatch } from 'react-redux';
import axiosInstance from '../../../../axios/axiosInstance';
import { decodeToken } from '../../../../helper/decodeToken';
import FacebookLogin from '@greatsumini/react-facebook-login';
import useNavigateBasedOnPath from '../../helper/checkPrevPath';
import { useShowAlert } from '../../../../hooks/useShowAlert';
const FacebookLoginButton = () => {
  const { showAlert } = useShowAlert();
  const dispatch = useDispatch();
  const navigateBasedOnPath = useNavigateBasedOnPath();
  const APPID = import.meta.env.VITE_VERCEL_APP_ID_FACEBOOK
  const [accessToken, setAccessToken] = useState('');
  // handle response from facebook and send token to server
  const responseFacebook = (response) => {
    setAccessToken(response.accessToken);
    // send token to server
    axiosInstance.post('/user/auth/facebook', { accessToken: response.accessToken }).then(async (res) => {
      localStorage.setItem('token', res.data?.token);
      const { id, exp } = await decodeToken(res.data?.token);
      dispatch(setAuthUser({ user: null, exp: exp, id: id })) // set user to redux
      dispatch(fetchUser())
        .then(() => {
          navigateBasedOnPath();
        })
        .catch((err) => {
          showAlert("error", err.response.data.error);
        })
    }).catch((err) => {
      showAlert("error", err.response.data.message); // show error message to user
      setAccessToken(''); // reset access token to hide loading screen
    });
  }
  return (

    <>
      <FacebookLogin
        appId={APPID}
        onSuccess={responseFacebook}
        onFail={(err) => {
          showAlert("error", err.message); // show error message to user
          setAccessToken('');// reset access token to hide loading screen
        }}
        style={{
          height: '50px',
          background: 'blue',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
          fontSize: '1.1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '10px',
          marginBottom: '10px',
          width: "244px",
          borderRadius: "5px",
        }}
      />


      <LoadingScreen loadingState={!!accessToken} />
    </>

  );
};

export default FacebookLoginButton;
