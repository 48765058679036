import { TableCell, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { formatDate } from "../../helper/formatDate";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
export const ProductRow = ({ product, handleEdit, handleDelete, LinkStyle }) => {
    return (
        <TableRow>
            <TableCell align="center" sx={{ color: "primary.main" }}>
                <Link to={`/product-detail/${product._id}`} style={LinkStyle}>
                    {product.name}
                </Link>
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"] }}>
                {formatDate(product.updatedAt)}
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"] }}>
                {formatDate(product.createdAt)}
            </TableCell>
            <TableCell align="center" sx={{ color: product.available ? "green" : "red", fontSize: ["13px", "16px", "18px"] }}>
                {product.available ? "Open" : "Close"}
            </TableCell>
            <TableCell align="center" sx={{ fontSize: ["13px", "16px", "18px"] }}>
                {product.category.name}
            </TableCell>
            <TableCell align="center">
                <EditIcon
                    sx={{ color: "primary.main", cursor: "pointer", fontSize: ["16px", "18px"] }}
                    onClick={() => handleEdit(product._id)}
                />
            </TableCell>
            <TableCell align="center">
                <DeleteIcon
                    sx={{ color: "primary.main", cursor: "pointer", fontSize: ["16px", "18px"] }}
                    onClick={() => handleDelete(product._id)}
                />
            </TableCell>
        </TableRow>
    );
};