import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const useSaveAddressValidation = () => {
  const { t } = useTranslation("settings");
  const saveAddressValidationSchema = Yup.object().shape({
    country: Yup.string().required(t("save-address.validation.country.required")),
    address: Yup.string().required(t("save-address.validation.address.required")),
    apartment: Yup.string().required(t("save-address.validation.apartment.required")),
    city: Yup.string().required(t("save-address.validation.city.required")),
    state: Yup.string().required(t("save-address.validation.state.required")),
    zipCode: Yup.string().required(t("save-address.validation.zipCode.required")),
    location: Yup.object().shape({
      lat: Yup.number().required(t("save-address.validation.location.required")),
      lng: Yup.number().required(t("save-address.validation.location.required")),
    }),
  });
  return { saveAddressValidationSchema };
};

