import {useEffect,useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../../axios/axiosInstance";
import { useShowAlert } from "../../../../hooks/useShowAlert";
import { langs } from "../langs";
const useEditCategory = ({langBoxes,setLangBoxes}) => {
  const { showAlert } = useShowAlert();
  const { id } = useParams();
  const [category, setCategory] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(null);

  // handle edit category
  const handleEditCategory = (values,{resetForm}) => {

    const nameTranslations = langBoxes.reduce((acc, lang) => ({
      ...acc,
      [lang.lang]: values[lang.lang]
    }), {})

    const data = {
      name: values.name,
      picture: values.picture,
      nameTranslations: nameTranslations
    }
    axiosInstance.put(`admin/categories/${id}`, data).then(() => {
      showAlert("success", "Category edited successfully");
      resetForm();
    }).catch((err) => {
      showAlert("error", err.response.data.error);
    });
  };

  // get category details
  useEffect(() => {
    axiosInstance.get(`admin/categories/${id}`).then((res) => {
      const categoryData = res.data.category;
      setCategory(categoryData);
      
      // Set initial form values including translations
      setInitialFormValues({
        name: categoryData.name,
        picture: categoryData.picture,
        currentSelectedLang: "",
        ...categoryData.nameTranslations  // This spreads all translations directly into form values
      });

      // Handle langBoxes setup
      if (categoryData.nameTranslations) {
        setLangBoxes(
          Object.keys(categoryData.nameTranslations)
            .map(lang => ({
              lang: lang,
              label: langs.find(l => l.value === lang)?.label
            }))
            .filter(item => item.label)
        );
      }
    }).catch(() => {
      showAlert("error", "Failed to fetch category details");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    category,
    handleEditCategory,
    initialFormValues,
  };
};

export default useEditCategory;
