import { Box, Button, Container, Stack, Typography } from '@mui/material'
import notFoundImage from "../assets/images/not-found.webp"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const imageStyle = {
    width: "100%",
    height: "100%"

}
export const NotFound = () => {
    const { t } = useTranslation('notFound');
    return (
        <Container maxWidth="xl">
            <Stack spacing={5} sx={{ justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <Box >
                    <img src={notFoundImage} alt="page-not-Found" loading="lazy" style={imageStyle} />
                </Box>
                <Typography variant='h6' component="div" sx={{ color: "primary.main", fontWeight: "bold", textAlign: "center", textTransform: "capitalize" }}>
                    {t('title1')}
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/">
                    {t('goToHome')}
                </Button>
            </Stack>
        </Container>
    )
}
