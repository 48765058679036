import BaseForm from "../../../formik/BaseForm"
import CustomFormikField from "../../../formik/CustomFormikField" 
import SubmitButton from "../../../formik/SubmitButton"
import { useSendReferralCodeValidation } from "../../../validation/sendReferralCodeValidation"
import { useTranslation } from "react-i18next"
const SendCodeForm = ({handleSubmit, loading}) => {
    const { sendReferralCodeValidationSchema } = useSendReferralCodeValidation()
    const { t } = useTranslation("sendRefferal")
  return (
    <div>
        <BaseForm
         initialValues={{ email: '' }}
         onSubmit={handleSubmit}
         validationSchema={sendReferralCodeValidationSchema}
         >
            <CustomFormikField name="email" label="Email" type="email" size="small" />
            <SubmitButton loading={loading} disabled={loading}>
                {t("form.button.text")}
            </SubmitButton>
        </BaseForm>
    </div>
  )
}

export default SendCodeForm