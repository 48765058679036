import { Box, Chip, Container } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import { setActiveCategory, setFilterKeys, setSearchByName } from '../../redux/slices/filterSlice';
import { useTranslation } from 'react-i18next';
export const SelectedFilter = () => {
    const { t } = useTranslation("shop");
    const dispatch = useDispatch();
    const filterKeys = useSelector((state) => state.filter.filterKeys);
    const handleDelete = (key) => {
        switch (key) {
            case 'category':
                dispatch(setActiveCategory(null));
                break;
            case 'name':
                dispatch(setSearchByName(""));
                break;
            default:
                dispatch(setFilterKeys({
                    ...filterKeys,
                    [key]: null
                }));
                break;
        }
    };

    return (
        <Container sx={{ mt: 5, mb: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap", gap: 2 }}>
                {
                    filterKeys.category && (
                        <Chip
                            label={`${t("SelectedFilter.category")}: ${filterKeys?.category?.name}`}
                            onDelete={() => handleDelete('category')}
                            deleteIcon={<DeleteIcon />}
                            variant="outlined"
                        />
                    )
                }
                {
                    filterKeys.name && (
                        <Chip
                            label={`${t("SelectedFilter.name")}: ${filterKeys.name}`}
                            onDelete={() => handleDelete('name')}
                            deleteIcon={<DeleteIcon />}
                            variant="outlined"
                        />
                    )
                }
                {Object.entries(filterKeys)
                    .filter(([key, value]) => value !== null && value !== "" && key !== 'category' && key !== 'name')
                    .map(([key, value]) => (
                        <Chip
                            key={key}
                            label={`${t(`SelectedFilter.${key}`)}: ${typeof value === 'object' ? JSON.stringify(value) : value}`}
                            onDelete={() => handleDelete(key)}
                            deleteIcon={<DeleteIcon />}
                            variant="outlined"
                        />
                    ))}
            </Box>
        </Container>
    );
};
