import { ErrorMessage, Field } from 'formik';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import dayjs from 'dayjs';
import { Box } from '@mui/material';

const StyledDatePicker = styled(DatePicker)({
  width: "100%",
  color: "secondary.main",
  '& .MuiOutlinedInput-root': {
    borderRadius: "10px",
    backgroundColor: "white",
    color: "secondary.main",
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
    '& .MuiInputBase-input::placeholder': {
      color: 'black',
      fontSize: '12px',
    },
  },
  '& .MuiFormLabel-root': {
    color: 'black',
  },
});

const CustomDatePicker = ({ name, label, shouldDisableDate,minDate, disabled=false, ...props }) => {
    const today = new Date();
  return (
    <>
    <Field name={name} fullWidth>
      {({ field, form: { setFieldValue } }) => (
        <StyledDatePicker
          {...field}
          label={label}
          value={field.value}
          onChange={(date) => setFieldValue(name, date)}
          minDate={minDate || dayjs(today)}
          shouldDisableDate={shouldDisableDate}
          disabled={disabled}
          {...props}
        />
      )}
    </Field>
    <ErrorMessage name={name} component={Box} sx={{ color: "red" }} />
    </>
  );
};

export default CustomDatePicker;
