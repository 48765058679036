import { Stack, Link, Typography } from "@mui/material";
import googleApp from "../../../assets/images/googlePlay.png";
import appleApp from "../../../assets/images/app-store.png";

const FooterAppDownloads = () => (
  <div>
    <Typography variant="body1" sx={{ color: "white", fontWeight: "bolder" }}>
      Get Our app
    </Typography>
    <Stack spacing={1} mt={2}>
      <Link to="/">
        <img src={googleApp} alt="google app" loading="lazy" style={{ width: "120px", height: "100%" }} />
      </Link>
      <Link to="/">
        <img src={appleApp} alt="apple app" loading="lazy" style={{ width: "120px", height: "100%" }} />
      </Link>
    </Stack>
  </div>
);

export default FooterAppDownloads;
