import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
export const CustomDatePicker = styled(DatePicker)({
    backgroundColor: "white",
    color: "secondary.main",
    borderRadius: "10px", // To remove round shape
    '& .MuiOutlinedInput-root': { // To remove outline
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
    '& .MuiFormLabel-root': {
      color: 'black', // To change label color
    },

  });
