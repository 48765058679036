import { useState } from "react";
import { Typography, Paper, Box, Container, } from "@mui/material";
import { useLoginValidation } from "../../../validation/loginValidation";
import { LoginFiled } from "./LoginFiled";
import { LoginMethod } from "./LoginMethod";
import { LoginWithSocial } from "../LoginBySocialmedia/LoginWithSocial";
import { useSubmitForm } from "../hooks/loginSubmit";
import { useTranslation } from "react-i18next";
import { DontHaveAcount } from "./DontHaveAcount";
import BaseForm from "../../../formik/BaseForm";
const initialValues = {
    identifier: "",
    password: "",
    countryCode: "+1",
};
const Login = () => {
    const { loginValidationSchema } = useLoginValidation();
    const { t } = useTranslation("login");
    const { submitForm, loading } = useSubmitForm();
    const [loginMethod, setLoginMethod] = useState("email");
    return (
        <Box sx={{ pt: 8 }}>
            <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
                {t("title")}
            </Typography>

            <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ py: 4, borderRadius: 2, backgroundColor: "secondary.main", px: [2, 5] }}>
                        <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold", fontSize: ["30px", "40px"] }}>
                            {t("Welcome")}
                        </Typography>
                        <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
                            {t("subTitle")}
                        </Typography>
                        <BaseForm
                            initialValues={initialValues}
                            validationSchema={loginValidationSchema}
                            onSubmit={submitForm}
                        >
                            <LoginFiled loginMethod={loginMethod} loading={loading} />
                        </BaseForm>
                        <LoginMethod loginMethod={loginMethod} setLoginMethod={setLoginMethod} />
                        <LoginWithSocial />
                        <DontHaveAcount />
                    </Paper>
                </Container>
            </Box>
        </Box>
    );
};

export default Login;