// calculateEndDate.js
import dayjs from "dayjs";

export const calculateEndDate = (fromDate, number, period) => {
  let endDate;
  if (period === "Week") {
    endDate = dayjs(fromDate).add(number, "week").subtract(1, "day");
  } else if (period === "Month") {
    endDate = dayjs(fromDate).add(number, "month").subtract(1, "day");
  } else if (period === "Year") {
    endDate = dayjs(fromDate).add(number, "year").subtract(1, "day");
  }
  return endDate;
};