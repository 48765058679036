import NotificationList from "../NotificationList";
import usePendingActions from "../hooks/usePendingActions";
export const Pendding = () => {
  const { handleAccept, handleReject } = usePendingActions();
  return (
    <NotificationList
      type="transaction created"
      limit={10}
      noMoreText="pending.noMore"
      fetchUrl="/notifications"
      actions={{ handleAccept, handleReject }}
    />
  );
};
