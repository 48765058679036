import { Container, Typography } from "@mui/material";
import FavoritesProductsList from "../components/Favourites/FavoritesProductsList"; 
import { useTranslation } from "react-i18next";
export const FavoriteProducts = () => {
    const { t } = useTranslation("favorites");
    return (
            <Container maxWidth="lg" sx={{py:"100px"}}>
                <Typography variant="h3" align="center" sx={{color:"primary.main",mb:"70px",fontWeight:"bold"}}>
                    {t("title")}
                </Typography>
                <FavoritesProductsList/>
            </Container>
    );
};





