import { Paper } from "@mui/material"
import { AllFilterKey } from "./AllFilterKey"
const FilterContainerOnMobile = () => {
  return (
    <Paper sx={{ width: "100%", p: 1,backgroundColor:"text.main"  }}>
      <AllFilterKey />
    </Paper>
  )
}

export default FilterContainerOnMobile
