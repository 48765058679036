import { Box, Container, Typography, Grid, Button } from "@mui/material";
import BaseForm from "../../formik/BaseForm";
import CustomFormikField from "../../formik/CustomFormikField";
import CustomSelectBox from "../../formik/CustomSelectBox";
import { categoryValidationSchema } from "../../validation/CategoryValidation";
import CustomUploadImage from "../../formik/CustomUploadImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import { useFormikContext } from 'formik';
import useEditCategory from "../../components/admin/Categories/hooks/useEditCategory";
import { langs } from "../../components/admin/Categories/langs";
export const EditCategories = () => {
  
  const [langBoxes, setLangBoxes] = useState([]);
  const { handleEditCategory, initialFormValues } = useEditCategory({ langBoxes, setLangBoxes });
  // add lang box 
  const handleAddLangBox = (lang) => {
    // if lang is already in langBoxes or lang is empty return true
    if (langBoxes.some(l => l.lang === lang) || lang === "") return;
    setLangBoxes([...langBoxes, { lang: lang, label: langs.find(l => l.value === lang).label }]);
  };
  // remove lang box
  const handleRemoveLangBox = (lang) => {
    setLangBoxes(langBoxes.filter(l => l.lang !== lang));
  };

  const OtherLangBoxes = () => {
    const { values } = useFormikContext();
    return (
      <Box sx={{ mt: 3 }}>
        <Typography variant="h6" component="h2" sx={{ pb: 2, textAlign: "center", color: "primary.main", fontSize: { xs: "14px", md: "20px" } }}>Add Other Languages</Typography>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
          <CustomSelectBox name="currentSelectedLang" label="Language" options={langs} size="small" />
          <Button variant="contained" color="primary" onClick={() => handleAddLangBox(values.currentSelectedLang)}>Add</Button>
        </Box>
        {langBoxes.map((lang) => (
          <Box key={lang.lang} sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            mt: 2
          }} >
            <Typography variant="h6" component="h2" sx={{ fontWeight: "bold", width: "25%", textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold", order: { xs: 1, md: 1 } }}>{lang.label}</Typography>
            <CustomFormikField name={`${lang.lang}`} label={`${lang.label}`} size="small" sx={{ width: { xs: "100%", md: "65%" }, order: { xs: 3, md: 2 } }} />

            <DeleteIcon onClick={() => handleRemoveLangBox(lang.lang)} sx={{ cursor: "pointer", width: "10%", color: "red", order: { xs: 2, md: 3 } }} />

          </Box>
        ))}
      </Box>
    )
  }

  // Only render the form when initialFormValues is available
  if (!initialFormValues) {
    return null; // or a loading spinner
  }

  return (
    <Box py={8}>
      <Typography variant="h5" component="h1" sx={{ fontWeight: "bold", pb: 2, textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
        Edit Category
      </Typography>
      <Container maxWidth="md" sx={{ p: { xs: 1, md: 5 }, backgroundColor: "text.main", borderRadius: "10px" }}>
        <BaseForm
          initialValues={initialFormValues}
          validationSchema={categoryValidationSchema}
          onSubmit={handleEditCategory}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomFormikField name="name" label="Category Name" size="small" />
              <OtherLangBoxes />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box sx={{ backgroundColor: "white", borderRadius: "5px", p: 2 }}>
                <CustomUploadImage name="picture" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                Edit Category
              </Button>
            </Grid>
          </Grid>
        </BaseForm>
      </Container>
    </Box>
  );
};
