import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import BaseForm from "../../../formik/BaseForm";
import SubmitButton from "../../../formik/SubmitButton";
import CustomFormikField from "../../../formik/CustomFormikField";
import { useEditProfile } from '../hooks/editProfile';
import { useEditProfileValidation } from '../../../validation/editProfileValidation';
export const FormAcountInfo = ({ userInfo }) => {
    const { handleSubmit, loading } = useEditProfile();
    const { t } = useTranslation("settings");
    const { editProfileValidationSchema } = useEditProfileValidation();
    const initialValues = {
        username: userInfo?.username ? userInfo.username : "",
        displayName: userInfo?.displayName ? userInfo.displayName : "",
        firstName: userInfo?.firstName ? userInfo.firstName : "",
        lastName: userInfo?.lastName ? userInfo.lastName : "",
        phoneNumber: userInfo?.phoneNumber ? userInfo.phoneNumber : "",
        email: userInfo?.email ? userInfo.email : "",
    }
    return (
        <Box>
            <BaseForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={editProfileValidationSchema}
            >
                <Stack spacing={2} sx={{backgroundColor:"text.main",px:2,py:4, borderRadius:2}}>
                    <Box>
                        <Typography variant="subtitle2" sx={{ mb: 1, color: "secondary.main",fontWeight:600,textTransform:"capitalize" }}>
                            {t("acount-info.formLabels.username")}
                        </Typography>
                        <CustomFormikField label={t("acount-info.formLabels.username")} name="username" size="small"/>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" sx={{  mb: 1, color: "secondary.main",fontWeight:600,textTransform:"capitalize" }}>
                            {t("acount-info.formLabels.displayName")}
                        </Typography>
                        <CustomFormikField label={t("acount-info.formLabels.displayName")} name="displayName" size="small"/>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" sx={{ mb: 1, color: "secondary.main",fontWeight:600,textTransform:"capitalize"  }}>
                            {t("acount-info.formLabels.firstName")}
                        </Typography>
                        <CustomFormikField label={t("acount-info.formLabels.firstName")} name="firstName" size="small"/>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" sx={{  mb: 1, color: "secondary.main",fontWeight:600,textTransform:"capitalize"  }}>
                            {t("acount-info.formLabels.lastName")}
                        </Typography>
                        <CustomFormikField label={t("acount-info.formLabels.lastName")} name="lastName" size="small"/>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" sx={{  mb: 1, color: "secondary.main",fontWeight:600,textTransform:"capitalize"  }}>
                            {t("acount-info.formLabels.email")}
                        </Typography>
                        <CustomFormikField label={t("acount-info.formLabels.email")} name="email" size="small"/>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" sx={{ mb: 1, color: "secondary.main",fontWeight:600,textTransform:"capitalize"  }}>
                            {t("acount-info.formLabels.phoneNumber")}
                        </Typography>
                        <CustomFormikField label={t("acount-info.formLabels.phoneNumber")} name="phoneNumber" size="small"/>
                    </Box>
                    <SubmitButton disabled={loading} >
                        {t("acount-info.save")}
                    </SubmitButton>
                </Stack>
            </BaseForm>
        </Box>

    )
}
