import { Avatar, Box, Paper, Stack, TextField, Typography } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { fetchUser, getUser } from "../../redux/slices/AuthUser";
import { useState } from "react";
import { setAlertDetails } from "../../redux/slices/AlertDetailsSlice";
import { Link } from "react-router-dom";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CreateIcon from '@mui/icons-material/Create';
import { uploadImage } from "../../helper/UploadImage";
import axiosInstance from "../../axios/axiosInstance";

const imageStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
};

const UploadingOverlay = () => (
  <Box sx={{
    position: "absolute", top: "0", left: "0", right: "0", bottom: "0",
    display: "flex", justifyContent: "center", alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)", borderRadius: "50%", zIndex: "3"
  }}>
    <Typography variant="bo" sx={{ color: "white" }}>Uploading...</Typography>
  </Box>
);

const AvatarImage = ({ profilePicture, displayName, successUpload, uploadedImage }) => (
  <Avatar sx={{ textDecoration: "none", width: "100px", height: "100px" }}>
    {profilePicture ? (
      <img
        src={successUpload ? uploadedImage : profilePicture}
        alt={displayName || "user"}
        style={imageStyle}
        loading="lazy"
      />
    ) : (
      displayName?.[0].toUpperCase() || ""
    )}
  </Avatar>
);

const EditIcon = ({ userId }) => (
  <Box
    sx={{
      backgroundColor: "primary.main", display: "flex", justifyContent: "center",
      alignItems: "center", borderRadius: "50%", width: "30px", height: "30px"
    }}
    component={Link} to={`/account-info/${userId}`}
  >
    <CreateIcon sx={{ cursor: "pointer", color: "white", textDecoration: "none" }} />
  </Box>
);

export const UserAvatar = () => {
  const authUser = useSelector(getUser);
  const [successUpload, setSuccessUpload] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const setAlert = (type, message) => {
    dispatch(setAlertDetails({ type, message }));
  };

  const handleChangeImage = async (e) => {
    setUploading(true);
    const file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      setSuccessUpload(false);
      setUploading(false);
      setAlert("error", "Please choose the correct type of file");
      return;
    }

    try {
      const imageUrl = await uploadImage(file);
      setUploadedImage(imageUrl);

      await axiosInstance.put(`/user/${authUser?.id}`, { profilePicture: imageUrl });

      dispatch(fetchUser());

      setSuccessUpload(true);
      setAlert("success", "Image uploaded successfully");
    } catch (error) {
      setAlert("error", "Image upload failed");
    } finally {
      setUploading(false);
    }
  };

  return (
    <Stack
      sx={{ alignItems: "center", flexDirection: ["column", "column", "row"], gap: "10px" }}
    >
      <Box>
        <Paper
          sx={{
            borderRadius: "50%", width: "100px", height: "100px", position: "relative"
          }}
          elevation={5}
        >
          {uploading && <UploadingOverlay />}
          <AvatarImage
            profilePicture={authUser?.user?.profilePicture}
            displayName={authUser?.user?.displayName}
            successUpload={successUpload}
            uploadedImage={uploadedImage}
          />
          <Box sx={{ position: "absolute", bottom: "11px", right: "15px" }}>
            <TextField
              type="file"
              variant="outlined"
              size="small"
              sx={{ display: "none" }}
              id="avatar"
              onChange={handleChangeImage}
            />
            <label htmlFor="avatar">
              <Box
                sx={{
                  backgroundColor: "primary.main", padding: "2px", width: "30px",
                  height: "30px", borderRadius: "50%", display: "flex", alignItems: "center",
                  justifyContent: "center", border: "2px solid white", cursor: "pointer"
                }}
              >
                <AddAPhotoIcon sx={{ fontSize: "15px", color: "white" }} />
              </Box>
            </label>
          </Box>
        </Paper>
      </Box>

      <Stack spacing={2} direction="row">
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={1}>
            <Typography
              variant="h5"
              sx={{ flexGrow: [0, 1, 1], fontSize: ["18px", "20px"], fontWeight: "bold" }}
            >
              {authUser?.user?.displayName}
            </Typography>
            <Typography
              variant="h5"
              sx={{ flexGrow: [0, 1, 1], fontSize: ["16px", "18px", "20px"] }}
            >
              {authUser?.user?.email || authUser?.user?.phoneNumber}
            </Typography>
          </Stack>
        </Box>
        <EditIcon userId={authUser?.id} />
      </Stack>
    </Stack>
  );
};
