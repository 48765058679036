import { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";
import { useParams } from "react-router-dom";
export const useGetComment = () => {
    const { commentID } = useParams()
    const [comment, setComment] = useState("");
    useEffect(() => {
        const fetchComment = async () => {
            axiosInstance.get(`/comments/${commentID}`).then((res) => {
                setComment(res.data.comment.content);
              
            }).catch(() => {
                setComment("");
            })
        };

        fetchComment();
    }, [commentID]);

    return { comment }
}