import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { CategoriesSkeleton } from "../../components/general/loading/CategoriesSkeleton";
import useShowDeleteCategory from "../../components/admin/Categories/hooks/useShowDeleteCategory"; 

export const ShowAdminCategories = () => {
  const { categories, loading, deleteCategory } = useShowDeleteCategory();
  const navigate = useNavigate();

  // Navigate to edit category page
  const goToEditCategory = (id) => {
    navigate(`/admins/edit-categories/${id}`);
  };

  return (
    <Box sx={{ py: 8 }}>
      <Container>
      <Typography variant="h5" component="h1" sx={{ fontWeight: "bold", pb: 8, textAlign: "center", color: "primary.main", fontFamily: "Poppins-Bold" }}>
          Categories
        </Typography>
        <Grid container spacing={5}>
          {loading ? (
            Array.from({ length: 12 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <CategoriesSkeleton />
              </Grid>
            ))
          ) : (
            categories.map((category) => (
              <Grid item xs={12} sm={6} md={3} key={category._id}>
                <Paper sx={{ textAlign: "center", borderRadius: "20px", position: "relative" }}>
                  <Box sx={{ height: "30px", width: "30px", backgroundColor: "white", position: "absolute", top: "5px", right: "50px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <EditIcon sx={{ cursor: "pointer" }} onClick={() => goToEditCategory(category._id)} />
                  </Box>
                  <Box sx={{ height: "30px", width: "30px", backgroundColor: "white", position: "absolute", top: "5px", right: "5px", borderRadius: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <DeleteIcon sx={{ cursor: "pointer", color: "primary.main" }} onClick={() => deleteCategory(category._id)} />
                  </Box>
                  <img src={category.picture} alt={category.name} style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "20px" }} />
                  <Typography variant="body1" sx={{ color: "secondary.main", pt: 3 }}>
                    {category.name}
                  </Typography>
                </Paper>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </Box>
  );
};
