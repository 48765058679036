import { createSlice } from '@reduxjs/toolkit';
const OpenDialogSlice = createSlice({
  name: 'Dialog',
  initialState: { open: false },
  reducers: {
    setOpenDialog: (state, { payload }) => {
      state.open = payload;
    },
  },
});

export const { setOpenDialog } = OpenDialogSlice.actions;
export default OpenDialogSlice.reducer;

