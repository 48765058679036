import { useState } from 'react';
import { Field } from 'formik';
import { InputAdornment, IconButton } from '@mui/material';
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const CustomTextField = styled(TextField)({
    width: "100%",
    color: "secondary.main",
    '& .MuiOutlinedInput-root': { // To remove outline
        borderRadius: "10px",
        backgroundColor: "white",
        color: "secondary.main",
        '& fieldset': {
            borderColor: 'transparent',
        },
        '&:hover fieldset': {
            borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
        },
        '& .MuiInputBase-input::placeholder': {
            color: 'black',
            fontSize: '12px',
        },
    },
    '& .MuiFormLabel-root': { // To change label color
        color: 'black', // Replace 'desiredColor' with the color you want
    },
});

const PasswordInput = ({ name, label, icon = <LockOpenIcon sx={{ fontSize: "15px" }} />, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Field name={name}>
            {({ field, meta }) => (
                <CustomTextField
                    {...field}
                    label={label}
                    type={showPassword ? 'text' : 'password'}
                    size="small"
                    error={meta.touched && Boolean(meta.error)}
                    helperText={meta.touched && meta.error}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {icon}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...props}
                />
            )}
        </Field>
    );
};

export default PasswordInput;