import { Skeleton, Stack } from '@mui/material'
export const ShowCommentAndReviewSkeleton = () => {
    return (
        <Stack spacing={3} sx={{ width: "100%" }}>

            <Skeleton variant='rectangular' sx={{ width: "100%", height: "50px" }} />

            <Skeleton variant='rectangular' sx={{ width: "100%", height: "50px" }} />

            <Skeleton variant='rectangular' sx={{ width: "100%", height: "50px" }} />

          

        </Stack>
    )
}
