import { Box, Container, Typography } from '@mui/material'
import { MessagesHistory } from '../components/chat/MessagesHistory'
import { useTranslation } from 'react-i18next'
export const Chat = () => {
  const { t } = useTranslation("chat")
  return (
  <Box sx={{py:8}}> 
    <Typography variant="h4" align="center" sx={{color:"primary.main",pb:5,fontWeight:"bold",fontSize:["22px","30px"]}}>
      {t("title")}
    </Typography>
    <Container>
    <MessagesHistory/>
 </Container> 
  </Box>
  )
}

