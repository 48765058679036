import { useTranslation } from "react-i18next";
import * as yup from "yup";
export const useSignUpReferralValidation = () => {
    const { t } = useTranslation("referralSignup");

  const signUpWithReferralValidationSchema = yup.object({
      username: yup
        .string()
        .required(t('formErrors.username.required'))
        .min(3, t('formErrors.username.min'))
        .max(30, t('formErrors.username.max'))
        .matches(
          /^[a-zA-Z0-9]+$/,
          t('formErrors.username.matches')
        ),
      email: yup
        .string()
        .required(t('formErrors.email.required'))
        .email(t('formErrors.email.invalid')),
      password: yup
        .string()
        .required(t('formErrors.password.required'))
        .min(8, t('formErrors.password.min'))
        .matches(
          /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
          t('formErrors.password.matches')
        ),
      repeatPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], t('formErrors.confirmPassword.match'))
        .required(t('formErrors.confirmPassword.required')),
      referral: yup.string().required(t('formErrors.referralCode.required')),
    });
    return {signUpWithReferralValidationSchema}
};
