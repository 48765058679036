import { Grid, Skeleton } from '@mui/material';


export const SkeletonLoadingOpiningListProducts = ({number}) => {
    const numberOfBoxes = Array.from({ length: number }, (_, i) => i + 1);
    return (
        <>
            {
                numberOfBoxes.map((box) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={box}>
                            <Skeleton variant="text" sx={{ width: "100%", height: "200px" }} />
                        </Grid>
                    )
                }
                )
            }
        </>

    );
  
}
