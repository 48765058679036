import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import i18next from 'i18next';
// IMPORT LANGUAGE FLAGS IMAGES
import en from '../../../assets/images/EN-FLAG.png';
import es from '../../../assets/images/ES-FLAG.png';
import { Box } from '@mui/material';
const languages = [
    {
        title: "En ",
        code: 'en',
        flush: en,

    },
    {
        title: "Es",
        code: 'es',
        flush: es,
    },

];
export const ChangeLanguage = () => {
    const [currentLanguage, setCurrentLanguage] = useState(i18next.language); // get the current language from i18next
    const handleChange = (event) => {
        setCurrentLanguage(event.target.value);
        i18next.changeLanguage(event.target.value);
    };
    return (
        <FormControl variant="outlined" size="small" sx={{width:"85px"}}>
            <Select
                labelId="language-select-label"
                id="language-select"
                value={currentLanguage}
                onChange={handleChange}
                sx={{width:"85px"}}

            >
                {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code} >
                        <Box sx={{display:"flex",alignItems:"center"}}>
                        <span style={{display:"inline-block"}}>
                        {lang.title}
                        </span>
                        <img src={lang.flush} alt={lang.code} style={{ width: '20px', height: '20px', marginLeft: '6px',display:"inline-block" }} />
                     </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

