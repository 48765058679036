import { Box } from "@mui/material";
import UpgradeIcon from '@mui/icons-material/Upgrade';
export const NewImageBox = ({ newImageLoading, handleImageChange, pictures }) => (
    <Box sx={{ position: "relative", width: ["135px", "150px"], height: "100px", border: "1px solid #8080801f", boxShadow: "0px 0px 3px 0px #8080807c" }}>
        {newImageLoading ? (
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    zIndex: 1000
                }}
            >
                Uploading...
            </Box>
        ) : null}
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "primary.main",
                backgroundColor: "white",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer"
            }}
        >
            <label htmlFor="upload-image-new" style={{
                cursor: "pointer", width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <UpgradeIcon sx={{ fontSize: "40px" }} />
                <input
                    id="upload-image-new"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e, pictures.length)}
                    style={{ display: "none" }}
                />
            </label>
        </Box>
    </Box>
);
