import { useState, useEffect } from "react";
import axiosInstance from "../../../../axios/axiosInstance";
import { useShowAlert } from "../../../../hooks/useShowAlert";
const useConfiguration = () => {
const { showAlert } = useShowAlert();
  const [numberPopularProducts, setNumberPopularProducts] = useState(0);
  const [additionalFees, setAdditionalFees] = useState(0);
  const [tax, setTax] = useState(0);

  // get configuration values
  const getConfiguration = async () => {
    try {
      const res = await axiosInstance.get(`/configurations/additional-fee`);
      setAdditionalFees(res.data.additionalFees);
      setTax(res.data.tax);
    } catch (error) {
      showAlert("error", `Failed to fetch `);
    }
  };

  // update configuration values
  const updateConfiguration = async (name, value) => {
    try {
      const res = await axiosInstance.post('/configurations', {
        name,
        value,
        description: "test"
      });
      if (name === "additional fees") setAdditionalFees(res.data.value);
      else if (name === "tax") setTax(res.data.value);
      showAlert("success", `${name} updated successfully`);
    } catch (error) {
      showAlert("error", `Failed to update ${name}`);
    }
  };

  // get popular products count
  const getPopularProductsCount = async () => {
    try {
      const res = await axiosInstance.get('/configurations/popular-products-count');
      setNumberPopularProducts(res.data);
    } catch (error) {
      showAlert("error", "Failed to fetch number of popular products");
    }
  };

  // update popular products count
  const updatePopularProductsCount = async (value) => {
    try {
      const res = await axiosInstance.post('/configurations/popular-products-count', {
        value,
        description: "test"
      });
      setNumberPopularProducts(res.data.value);
      showAlert("success", "Number of popular products updated successfully");
    } catch (error) {
      showAlert("error", "Failed to update number of popular products");
    }
  };

  useEffect(() => {
    getPopularProductsCount();

    getConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    numberPopularProducts,
    additionalFees,
    tax,
    setNumberPopularProducts,
    setAdditionalFees,
    setTax,
    updateConfiguration,
    updatePopularProductsCount
  };
};

export default useConfiguration;