import * as Yup from "yup";
import { useTranslation } from "react-i18next";
export const useSendReferralCodeValidation = () => {
  const { t } = useTranslation("sendRefferal");
  const sendReferralCodeValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("formErrors.email.invalid")).required(t("formErrors.email.required")),
  });
  return { sendReferralCodeValidationSchema };
};

