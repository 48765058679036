import { Container, Typography } from '@mui/material'
import PopularProductsList from '../components/popular-products/PopularProductsList'
import { useTranslation } from 'react-i18next'
export const PopularProducts = () => {
    const { t } = useTranslation("popularProducts")
    return (
        <Container maxWidth="lg" sx={{ py: "100px" }}>
            <Typography variant="h3" align="center" sx={{ color: "primary.main", mb: "70px", fontWeight: "bold",fontFamily:"Poppins-Bold" }}>
                {t('title')}
            </Typography>
             <PopularProductsList/>
        </Container>

    )
}
