import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

export const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);

  // handle scroll to top
  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  
  useEffect(() => {
    // show scroll button when scrollY > 100
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setShowScroll(true);
      } else {
        setShowScroll(false);
      }
    });
  
  }, [showScroll]);
  return (
    <>
      {
        showScroll && (
          <Box
            sx={{
              position: "fixed",
              bottom: "100px",
              right: "20px",
              width: "40px",
              height: "40px",
              backgroundColor: "primary.main",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              border: "1px solid white",
            }}
            onClick={handleScroll}
          >
            <KeyboardArrowUpIcon sx={{ color: "white", fontSize: "30px" }} />
          </Box>
        )}
    </>

  );
};
