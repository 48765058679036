import { useEffect, useState } from "react";
import axiosInstance from "../../../axios/axiosInstance";

export const useFetchProducts = () => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const limit = 20;

    useEffect(() => {
        const fetchProducts = async () => {
            const response = await axiosInstance.get(`/products?page=${currentPage}&limit=${limit}`);
            const fetchedProducts = response.data.products;

            if (fetchedProducts.length > 0) {
                setProducts((prevProducts) => [...prevProducts, ...fetchedProducts]);
                setCurrentPage((prevPage) => prevPage + 1);
            } else {
                setHasMore(false);
            }
        };

        if (hasMore) {
            fetchProducts();
        }
    }, [currentPage, hasMore]);

    return products;
};
