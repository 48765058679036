import { Box } from "@mui/material"
import { AddRating } from "./AddRating"
import { AverageRating } from "./AverageRating"

const RatingContainer = () => {
  return (
    <Box>
        <AverageRating />
        <AddRating />
    </Box>
  )
}

export default RatingContainer