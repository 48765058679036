import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useResetPasswordValidation = () => {
  const { t } = useTranslation("resetPassword");

  const ResetPasswordValidationSchema = yup.object({
    resetToken: yup
      .string()
      .required(t("resetPassword.enterCode.formVlidation")),
    newPassword: yup
      .string()
      .required(t("resetPassword.enterNewPassword.formVlidation.required"))
      .min(8, t("resetPassword.enterNewPassword.formVlidation.min"))
      .matches(
        /^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/,
        t("resetPassword.enterNewPassword.formVlidation.matches")
      ),
  });

  return { ResetPasswordValidationSchema };
};
