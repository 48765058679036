import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCategory, setFilterKeys, setSearchByName } from "../../../../redux/slices/filterSlice";
import dayjs from "dayjs";

const useFilterFunctions = () => {
    const dispatch = useDispatch();
    const curruntFilter = useSelector((state) => state.filter.filterKeys);
    const [filtersKey, setFiltersKey] = useState({
        price: [
            curruntFilter.priceMin ? curruntFilter.priceMin : 0,
            curruntFilter.priceMax ? curruntFilter.priceMax : 10000,
        ],
        avilapility: {
            start: curruntFilter.startDate ? dayjs(curruntFilter.startDate) : null,
            end: curruntFilter.endDate ? dayjs(curruntFilter.endDate) : null,
        },
        condition: curruntFilter.condition ? curruntFilter.condition : "All",
        location: curruntFilter.location ? curruntFilter.location : null,
    });

    const handleFieldChange = (fieldName, newValue) => {
        setFiltersKey({
            ...filtersKey,
            [fieldName]: newValue,
        });
    };

    const updateFilterKey = () => {
        if (filtersKey.avilapility.start > filtersKey.avilapility.end) {
            return;
        }
        const finalFilter = {
            priceMin: filtersKey.price[0],
            priceMax: filtersKey.price[1],
            condition: filtersKey.condition === "All" ? null : filtersKey.condition,
            startDate: filtersKey.avilapility.start
                ? dayjs(filtersKey.avilapility.start).format("ddd, MMM DD, YYYY")
                : null,
            endDate: filtersKey.avilapility.end
                ? dayjs(filtersKey.avilapility.end).format("ddd, MMM DD, YYYY")
                : null,
            location: filtersKey.location,
        };
        dispatch(setFilterKeys(finalFilter));
    };

    const clearFilter = () => {
        setFiltersKey({
            price: [0, 10000],
            avilapility: {
                start: null,
                end: null,
            },
            condition: "All",
            location: null,
        });
        dispatch(
            setFilterKeys({
                priceMin: null,
                priceMax: null,
                condition: null,
                startDate: null,
                endDate: null,
                location: null,
                name: null,
            })
        );
        dispatch(setActiveCategory(null));
        dispatch(setSearchByName(null));
    };

    const handleLocationCheckboxChange = (event) => {
        if (event.target.checked) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    handleFieldChange("location", { lat: latitude, lng: longitude });
                },

            );
        } else {
            handleFieldChange("location", null);
        }
    };

    return { filtersKey, handleFieldChange, updateFilterKey, clearFilter, handleLocationCheckboxChange };
};

export default useFilterFunctions;
