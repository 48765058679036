import { GoogleOAuthProvider } from '@react-oauth/google';
import { LoginButton } from './LoginButton';
const GoogleLoginButton = () => {
const CLIENTID = import.meta.env.VITE_VERCEL_CLIENT_ID_GOOGLE
  return (
    <GoogleOAuthProvider clientId={CLIENTID}>
      <LoginButton />
    </GoogleOAuthProvider>

  );
};

export default GoogleLoginButton;
