import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axiosInstance from "../../../../axios/axiosInstance";
import { getUser } from "../../../../redux/slices/AuthUser";
import { useState } from "react";
import { useShowAlert } from "../../../../hooks/useShowAlert";
import { useTranslation } from "react-i18next";
import { useShowEmailPopup } from "../../../../hooks/useShowEmailPopup";
export const useSubmit = (product) => {
    const checkEmail = useShowEmailPopup();
    const { showAlert } = useShowAlert();
    const { t } = useTranslation("product-details");
    const navigate = useNavigate();
    const authUser = useSelector(getUser); // get userid from redux
    const [loading, setLoading] = useState(false);

    // handle submit
    const onSubmit = (values) => {
        setLoading(true);
        // check if user rent his own product or not
        if (authUser) {
            if (authUser?.id === product.userID._id) {
                showAlert("error", t("rentDate.rentYourself"));
                return;
            }
        }
        // check user have email or not
        // Check if user email is not updated or not exist
        if (checkEmail("renter")) {
            return; // Exit the function if checkEmail returns true
        }
        // convert date to string
        const fromDate = dayjs(values.From).format("ddd, MMM DD, YYYY");
        const toDate = dayjs(values.To).format("ddd, MMM DD, YYYY");
        const finalValues = {
            startDate: fromDate,
            endDate: toDate,
            productID: product._id,
            userID: authUser?.id,
        };
        // send request to the server to create transaction
        axiosInstance
            .post(
                "/transactions/create",
                finalValues
            )
            .then((res) => {
                showAlert(
                    "success",
                    res.data.message
                );
            })
            .catch((err) => {
                showAlert("error", err.response.data.error);
            }).finally(() => {
                setLoading(false);
            });
    };

    const GoChatPage = (user) => {
        navigate(`/contactWithUser/${user?._id}/${user?.displayName}`);
    };

    return { onSubmit, GoChatPage, loading };
};
