import { Box, Container, Typography, Grid } from '@mui/material';
import ProductDisplay from '../components/add-edit-comment-review/ProductDisplay';
import { useTranslation } from 'react-i18next';
import EditCommentForm from '../components/add-edit-comment-review/EditCommentForm';
export const EditComment = () => {
  const { t } = useTranslation("comments-reviews");
  return (
    <Box sx={{ pt: 8 }}>
      <Typography
        variant="h4"
        sx={{ color: 'primary.main', fontWeight: 'bold', textAlign: 'center', mb: 8 }}
      >
        {t("comment.edit.title")}
      </Typography>
      <Box sx={{ backgroundColor: 'text.main', py: 5 }}>
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={7}>

              <ProductDisplay />

            </Grid>
            <Grid item xs={12} sm={5}>
              <EditCommentForm />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
