import { Button, FormControl, IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axiosInstance from '../../../axios/axiosInstance';
import { useShowAlert } from '../../../hooks/useShowAlert';
const EditPassword = () => {
    const { showAlert } = useShowAlert();
    const [showPassword, setShowPassword] = useState(false);
    const { userID } = useParams()
    const [password, setPassword] = useState("")

    // handle toggle password
    const handleTogglePassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    // handle change password
    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    };
    const handleEditPassword = () => {
        // handle validation password 
        if (password.length < 8) {
            showAlert("error", "Password must be at least 6 characters")

        }
        else {

            if (!password.match(/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/)) {
                showAlert("error", "Password must contain at least one letter and one number")

            }
        }
        axiosInstance.put(`/admin/user/${userID}`, { password }).then(() => {
            setPassword("")
            showAlert("success", "Password changed successfully")

        }).catch((err) => {
            showAlert("error", err.response.data.error)

        })
    };

    const getPasswordInputAdornment = () => {
        return (
            <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        );
    };
    return (
        <Stack spacing={3} py={3}>
            <FormControl fullWidth margin="normal">
                <TextField
                    onChange={handleChangePassword}
                    id="outlined-basic"
                    label="Change Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment:
                            getPasswordInputAdornment(handleTogglePassword),
                    }}
                />
            </FormControl>
            <Button variant="contained" onClick={handleEditPassword}>Change Password</Button>
        </Stack>
    )
}
export default EditPassword