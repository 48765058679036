import { Box, Container, Grid, Typography } from '@mui/material'
import about from '../../assets/images/about.webp'
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
const imageStyle = {
    width: "100%", height: "500px", objectFit: "contain"
}
export const WhoWe = () => {
    const { t } = useTranslation('about');
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        setImageLoaded(true);
    }, [imageLoaded]);

    return (
        <Box py={5} /* sx={{height:"100vh"}} */>
            <Container>
                <Grid container spacing={3} alignItems="center">

                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography variant='h3' component="h2" sx={{ color: "primary.main", fontWeight: "bold" }} >
                            {t("whoWeAre.title")}
                            </Typography>
                            <Typography variant='body1' sx={{ color: "secondary.main", mt: 2 }}>
                            {t("whoWeAre.description")}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            {
                                !imageLoaded ? (
                                    <Box sx={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: "center", }}>

                                    </Box>
                                ) : (

                                    <img
                                        src={`${about}?${Date.now()}`}
                                        alt="about"
                                        style={imageStyle}

                                    />
                                )
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
