import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, Stack, Typography, Checkbox } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { usePay } from './hooks/pay';
import { usePaymentValidation } from '../../validation/paymentValidation';
import BaseForm from "../../formik/BaseForm";
import { Field, useFormikContext } from 'formik';

const PaymentFormContent = ({ status, loading, success }) => {
    const { errors, touched } = useFormikContext();
    const theme = useTheme();
    const { t } = useTranslation("payment");

    return (
        <Stack spacing={2}>
            <FormControl component="fieldset">
                <FormLabel component="legend">{t("paymentMethod.title")}</FormLabel>
                <Field name="paymentMethod">
                    {({ field }) => (
                        <RadioGroup {...field} row>
                            <FormControlLabel value="Credit Card" control={<Radio />} label={t("paymentMethod.creditCard")} />
                            <FormControlLabel value="Cash On Delivery" control={<Radio />} label={t("paymentMethod.cash")} />
                        </RadioGroup>
                    )}
                </Field>
            </FormControl>
            <Box>
                <Typography variant="subtitle2" component="p">
                    {t("paymentMethod.note")}
                </Typography>
            </Box>
            <Stack direction="row" sx={{ alignItems: "center" }}>
                <Field name="acceptPrivacyPolicy">
                    {({ field }) => (
                        <Checkbox {...field} sx={{ p: 0, pr: 1 }} />
                    )}
                </Field>
                {t("acceptPolicy.title")} <Link to="/terms-of-service" style={{ paddingLeft: "3px", color: theme.palette.primary.main }}>
                    {t("acceptPolicy.link")}
                </Link>
            </Stack>
            {errors.acceptPrivacyPolicy && touched.acceptPrivacyPolicy && (
                <Typography color="error">{errors.acceptPrivacyPolicy}</Typography>
            )}
            <Box sx={{ px: 8 }}>
                <Button fullWidth variant="contained" color="secondary" type="submit" disabled={status === "Ongoing" || success || loading}>
                    {t('pay')}
                </Button>
            </Box>
        </Stack>
    );
};

export const PaymentForm = ({ transactionID, status }) => {
    const validationSchema = usePaymentValidation();
    const { handleSubmit, loading, success } = usePay({ transactionID });
    const initialValues = {
        paymentMethod: 'Credit Card',
        acceptPrivacyPolicy: false,
    };

    return (
        <Box sx={{ py: 2 }}>
            <BaseForm
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                <PaymentFormContent status={status} loading={loading} success={success} />
            </BaseForm>
        </Box>
    );
};