import { Container, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
export const ResetPassword = () => {
    const { t } = useTranslation("resetPassword")
  return (
    <Container maxWidth="md" sx={{  minHeight: "50vh", mt: 4 }}>
        <Typography variant="h4" align="center" sx={{ mt: 2, color: "primary.main", fontWeight: "bold" }}>
            {t("title")}
        </Typography>
        <Outlet />
    </Container>
  )
}

