import { useSelector } from "react-redux";
import axiosInstance from "../../../axios/axiosInstance";
import { getUser } from "../../../redux/slices/AuthUser";
import { useNavigate, useParams } from "react-router-dom";
import { useShowAlert } from "../../../hooks/useShowAlert";
import {useState} from "react";
export const useAddComment = () => {
    const { showAlert } = useShowAlert();
    const {productID} = useParams()
    const navigate = useNavigate();
    const userId = useSelector(getUser)?.id;
    const [loading, setLoading] = useState(false);
    const handleSubmit = (values, { resetForm }) => {
        setLoading(true);
        const commentDetails = {
          content: values.comment,
          userId,
          productId: productID,
        };
        axiosInstance
          .post('/comments', commentDetails)
          .then((res) => {
            showAlert('success', res.data.message);
            navigate(`/product-detail/${productID}`);
          })
          .catch((err) => {
            showAlert('error', err.response.data.error);
          })
          .finally(() => setLoading(false));
        resetForm();
      };
      return { handleSubmit, loading };
}