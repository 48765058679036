import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { states } from "../../../../helper/HandelStates";
import { useSubmitForm } from './helper/submitForm';
import { countrys } from '../../../../helper/Countrys';
export const InputForm = ({ setUser, user, userID }) => {
    const handleSubmit = useSubmitForm(user, userID);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setUser((prevValues) => ({
            ...prevValues,
            address: {
                ...prevValues.address,
                [name]: value,
            },
        }));
    };
    return (
        <>
            <TextField
                name="username"
                label="User Name"
                variant="outlined"
                size="medium"
                value={user.username}
                onChange={handleChange}
            />
            <TextField
                name="firstName"
                label="First Name"
                variant="outlined"
                size="medium"
                value={user.firstName}
                onChange={handleChange}
            />
            <TextField
                name="lastName"
                label="Last Name"
                variant="outlined"
                size="medium"
                value={user.lastName}
                onChange={handleChange}
            />
            <TextField
                name="phoneNumber"
                label="Phone Number"
                variant="outlined"
                size="medium"
                value={user.phoneNumber}
                onChange={handleChange}
            />
            <TextField
                name="email"
                label="Email"
                variant="outlined"
                size="medium"
                value={user.email}
                onChange={handleChange}
            />

            <Box sx={{ py: "2px" }}>
                <Typography
                    variant="body1"
                    gutterBottom
                    component="div"
                    sx={{ fontWeight: "bold" }}
                >
                    Address
                </Typography>

                <Stack sx={{ direction: ["column", "row"] }} spacing={2}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"> Country </InputLabel>
                        <Select
                            fullWidth
                            value={
                                user.address
                                    ? user.address.country
                                        ? user.address.country
                                        : ""
                                    : ""
                            }
                            onChange={handleAddressChange}
                            name="country"
                            label="Country"
                            size="medium"
                        >
                            {countrys.map((country, i) => {
                                return (
                                    <MenuItem key={i} value={country}>
                                        {country}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"> State </InputLabel>
                        <Select
                            fullWidth
                            value={
                                user.address
                                    ? user.address.state
                                        ? user.address.state
                                        : ""
                                    : ""
                            }
                            onChange={handleAddressChange}
                            name="state"
                            label="State"
                            size="medium"
                        >
                            {states(user.address.country).map((state, i) => {
                                return (
                                    <MenuItem key={i} value={state}>{state}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <TextField
                            name="zipCode"
                            label="Zip Code"
                            variant="outlined"
                            size="medium"
                            value={
                                user.address
                                    ? user.address.zipCode
                                        ? user.address.zipCode
                                        : ""
                                    : ""
                            }
                            onChange={handleAddressChange}
                        />
                    </FormControl>
                </Stack>
            </Box>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Save
            </Button>
        </>
    )
}
