import { Box, Container, Grid, Paper } from '@mui/material'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../axios/axiosInstance'
import { PaymentInfo } from '../components/Payment/PaymentInfo'
import { PaymentForm } from '../components/Payment/PaymentForm'
import { PaymentSkeleton } from '../components/general/loading/PaymentSkeleton'

export const Payment = () => {
  const { transactionID } = useParams()
  const [transaction, setTransaction] = useState({})
  const [loading, setLoading] = useState(false)
  // get the transaction details from the server
  useEffect(() => {
    setLoading(true)
    axiosInstance.get(`/transactions/${transactionID}`).then((response) => {
      setTransaction(response.data.transaction)
    }).finally(() => {
      setLoading(false)
    })
  }, [transactionID])

  return (

    loading ? (
      <PaymentSkeleton />
    ) : (
      <Box py={5}>
        <Container >
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Paper elevation={3} sx={{ p: 2, borderRadius: "10px" }}>
                <img src={transaction.productID?.pictures[0]} alt={transaction.productID?.name} style={{ width: "100%", height: "300px" }} />
              </Paper>
            </Grid>
            <Grid item xs={12} md={7}>
              <PaymentInfo transaction={transaction} />
              <PaymentForm status={transaction.status} transactionID={transaction._id} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    )

  )
}

