import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const useSendMessageValidation = () => {
  const { t } = useTranslation("sendMessage");
  return yup.object({
    message: yup.string().required(t("formErrors.message.required")),
  });
};

