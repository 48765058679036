import { useShowAlert } from "../../../hooks/useShowAlert";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useSendMail = () => {
    const [loading, setLoading] = useState(false)
    const { showAlert } = useShowAlert()
    const { t } = useTranslation("contactUs")

    const sendMail = async (values, { resetForm }) => {
        setLoading(true);
        const data = {
            service_id: import.meta.env.VITE_VERCEL_EMAIL_SERVICE_ID,
            template_id: import.meta.env.VITE_VERCEL_EMAIL_TEMPLATE_ID,
            user_id: import.meta.env.VITE_VERCEL_EMAIL_USER_ID,
            template_params: {
                'to_name': import.meta.env.VITE_VERCEL_RECEIVER_EMAIL_NAME,
                'from_name': values.name,
                'from_email': values.email,
                "replay_to": values.email,
                'phone': values.phone,
                'message': values.message
            }
        };

        return axios.post('https://api.emailjs.com/api/v1.0/email/send', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            if (response.status === 200) {
                showAlert("success", t("contactUs.formSuccess"));
                resetForm();
            }
        }).catch(() => {
            showAlert("error", t("contactUs.formError"));
        }).finally(() => {
            setLoading(false);
        });
    };

    return { sendMail, loading }
}
