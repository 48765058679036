import { useDispatch } from "react-redux";
import { fetchUser } from "../../../redux/slices/AuthUser";
import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useSaveAddress = () => {
    const dispatch = useDispatch();
    const { showAlert } = useShowAlert();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // handle save
    const handleSave = async (values, id) => {
        const { country, address, apartment, city, governorate, state, zipCode } = values;
        const user = {
            address: {
                country,
                address,
                apartment,
                city,
                governorate,
                state,
                zipCode
            },
            location: values.location
        }
        setLoading(true);
        axiosInstance.put(`/user/${id}`, user
        ).then((res) => {
            showAlert("success", res.data.message)
            dispatch(fetchUser());  // fetch new user data after update
            navigate(-1);
        }).catch(err => {
            showAlert("error", err.response.data.error)

        }).finally(() => {
            setLoading(false)
        });
    }
    return { handleSave, loading }
}
