import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUser, getUser } from "../../redux/slices/AuthUser";
import axiosInstance from "../../axios/axiosInstance";
import { useTranslation } from "react-i18next";
import { useShowAlert } from "../../hooks/useShowAlert";
export const ControleBlockUser = () => {
  const { showAlert } = useShowAlert();
  const { t } = useTranslation("rentProfile");
  const dispatch = useDispatch();

  const { userId } = useParams()
  const authUser = useSelector(getUser); // get user id from redux
  const [Blocked, setBlocked] = useState(false);

  useEffect(() => {
    // check if user is blocked
    setBlocked(authUser?.user?.blockedUsers.includes(userId));
  }, [authUser?.user?.blockedUsers, userId]);

  // block user
  const handleBlockUser = () => {
    axiosInstance
      .put(
        `/user/block/${authUser?.id}/${userId}`,
        {}
      )
      .then((res) => {
        showAlert("success", res.data.message);
        setBlocked(true);
        // after blocking user fetch user details
        dispatch(fetchUser());
      })
      .catch((error) => {
        showAlert("error", error.response.data.error);

      });
  };
  // unblock user
  const handleUnblockUser = () => {
    if (!authUser?.id) { // if user not login show alert
      showAlert("error", "Please login to unblock user");

      return;
    }
    axiosInstance
      .put(
        `/user/unblock/${authUser?.id
        }/${userId}`,
        {}
      )
      .then((res) => {
        showAlert("success", res.data.message);
        setBlocked(false);
        // after unblocking user fetch user details
        dispatch(fetchUser());
      })
      .catch((error) => {
        showAlert("error", error.response.data.error);

      });
  };
  // control block button action

  return (
    <>
      {Blocked ? (
        <Button
          onClick={handleUnblockUser}
          fullWidth
          variant="contained"
          size="large"
          color="primary"
        >
          {t("unblock")}
        </Button>
      ) : (
        <Button
          onClick={handleBlockUser}
          fullWidth
          variant="contained"
          size="large"
          color="primary"
        >
          {t("block")}
        </Button>
      )}
    </>
  );
};
