import { Box, Button, Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSendCodeReferral } from '../components/auth/hooks/sendCodeReferral';
import SendCodeForm from '../components/auth/send-referralCode/SendCodeForm';

export const SendReferralsCode = () => {
    const { handleSubmit, loading, sended } = useSendCodeReferral()
    const { t } = useTranslation("sendRefferal")
    return (
        <Box my={8}>
            <Container maxWidth="sm">
                <Box sx={{ backgroundColor: "secondary.main", borderRadius: "20px", p: 8 }}>
                    {
                        sended ? (
                            <Box sx={{ py: 5 }}>
                                <Typography variant="h5" component="h2" sx={{ fontFamily: "Poppins-Bold", color: "primary.main", fontSize: ["25px"], textAlign: "center" }}>
                                    {t("success.title")}
                                </Typography>
                                <Typography variant="body1" component="p" sx={{ color: "white", fontFamily: "Poppins", fontWeight: "500", fontSize: "16px", textAlign: "center", marginTop: "20px" }}>
                                    {t("success.subtitle.1")}
                                    <br />
                                    {t("success.subtitle.2")}
                                </Typography>

                                <Button component={Link} to="/" fullWidth variant="contained" sx={{ backgroundColor: "primary.main", color: "white", fontFamily: "Poppins-Bold", fontSize: "16px", borderRadius: "10px", padding: "10px", mt: 4 }}>
                                    {t("success.button")}
                                </Button>

                            </Box>
                        ) : (
                            <Box>

                                <Typography variant='h4' component="h3" sx={{ color: "white", textAlign: "center", fontFamily: "Poppins-Bold" }}>
                                    {t("title.1")}
                                    <br />
                                    {t("title.2")}
                                </Typography>
                                <Typography variant='body2' sx={{ color: "white", textAlign: "center", my: 2 }}>
                                    {t("subtitle")}
                                </Typography>
                                <SendCodeForm handleSubmit={handleSubmit} loading={loading} />
                            </Box>

                        )
                    }
                </Box>
            </Container>

        </Box>
    )
}