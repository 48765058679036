import { Button, FormControl, MenuItem, Select, Container, Grid, InputLabel, TextField } from "@mui/material";
import useSearch from "./hooks/useSearch"; 

const statusOptions = [
  "All",
  "Pending",
  "Approved",
  "Rejected",
  "Ongoing",
  "Completed",
  "Cancelled",

];

export const SearchByNameOrStatus = () => {
  const { searchKeys, handleChangeField, sendSearchKeys, handleKeyDown } = useSearch();

  return (
    <Container>
      <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center", py: 3 }}>
        <Grid item xs={6} md={3}>
          <TextField
            fullWidth
            name="name"
            label="Search by name"
            onChange={handleChangeField}
            value={searchKeys.name}
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <FormControl fullWidth>
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              fullWidth
              name="status"
              value={searchKeys.status}
              onChange={handleChangeField}
              label="Status"
            >
              {statusOptions.map((status, index) => (
                <MenuItem key={index} value={status} sx={{ fontSize: ["15px", "18px"] }}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={2}>
          <Button
            fullWidth
            variant="contained"
            onClick={sendSearchKeys}
            sx={{ py: 2 }}
          >
            Search
          </Button>
        </Grid>
        
      </Grid>
    </Container>
  );
};
