import { Grid, Box } from "@mui/material"
import { AddressInput } from "./AddressInput";
import { useTranslation } from "react-i18next";
import { SelectLocation } from "../../general/SelectLocation";
import BaseForm from "../../../formik/BaseForm";
import { useSelector } from "react-redux";
import { getUser } from "../../../redux/slices/AuthUser";
import { useSaveAddressValidation } from "../../../validation/saveAddressValidation";
import SubmitButton from "../../../formik/SubmitButton";
import { useSaveAddress } from "../hooks/saveAddress";
import { ErrorMessage, useFormikContext } from 'formik';

const FormContent = ({loading, location}) => {
	const { setFieldValue, values } = useFormikContext();
	const { t } = useTranslation("settings");

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<AddressInput />
			</Grid>
			<Grid item xs={12} sm={6}>
				<SelectLocation
					location={values.location || location}
					onLocationChange={(location) => {
						setFieldValue('location', location);
					}}
					mapId="e8b1b7b3b3b4b3b3hgc"
				/>
				<ErrorMessage name="location" component={Box} sx={{ color: "red" }} />
				<Box sx={{ mt: 2, px: 5 }}>
					<SubmitButton sx={{ mt: 2 }} disabled={loading}>
						{t("save-address.save")}
					</SubmitButton>
				</Box>
			</Grid>
		</Grid>
	);
};

export const SaveAdress = () => {
	const { handleSave, loading } = useSaveAddress();
	const { saveAddressValidationSchema } = useSaveAddressValidation();
	const {user,id} = useSelector(getUser)
	const initialValues = {
		country: user?.address?.country || "",
		address: user?.address?.address || "",
		apartment: user?.address?.apartment || "",
		city: user?.address?.city || "",
		governorate: user?.address?.governorate || "",
		state: user?.address?.state || "",
		zipCode: user?.address?.zipCode || "",
		location: user?.location || {
            lat: 0,
            lng: 0,
        },
	};

	return (
		<Box>
			<BaseForm
				initialValues={initialValues}
				validationSchema={saveAddressValidationSchema}
				onSubmit={(values) => {
					handleSave(values, id);
				}}
			>
				<FormContent loading={loading} location={user.location} />
			</BaseForm>
		</Box>
	);
};

