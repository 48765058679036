import NotificationList from "../NotificationList";
export const Rejected = () => {
  return (
    <NotificationList
      type="transaction rejected"
      limit={10}
      noMoreText="rejected.noMore"
      fetchUrl="/notifications"
    />
  );
};
