import axiosInstance from "../../../axios/axiosInstance";
import { useState, useEffect } from "react";

export const useGetFeesAndTax = () => {
  const [additionalFees, setAdditionalFees] = useState(0);
  const [tax, setTax] = useState(0);

  useEffect(() => {
    const getConfiguration = async () => {
      try {
        const res = await axiosInstance.get(`/configurations/additional-fee`);
        setAdditionalFees(res.data.additionalFees);
        setTax(res.data.tax);
      } catch (_) {/** */}
    };
    getConfiguration();
  }, []);

  return { additionalFees, tax };
}