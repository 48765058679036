import { useState } from "react";
import { useField } from "formik";
import { Box, Typography } from "@mui/material";
import { uploadImage } from "../helper/UploadImage";
import { useShowAlert } from "../hooks/useShowAlert";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
const CustomUploadImage = ({ name, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const { setValue } = helpers;
    const { showAlert } = useShowAlert();
    const [imageUploading, setImageUploading] = useState(false);

    const handleImageChange = async (event) => {
        try {
            setImageUploading(true);
            const selectedImage = event.target.files[0];

            if (!selectedImage.type.startsWith("image/")) {
                showAlert("error", "Unsupported file format, only images are allowed");
                return;
            }
            const imageUrl = await uploadImage(selectedImage);
            if (!imageUrl) {
                showAlert("error", "Image upload failed");
            } else {
                setValue(imageUrl);
            }
        } catch (err) {
            showAlert('error', err.message);
        } finally {
            setImageUploading(false);
        }
    };

    const handleRemoveImage = () => {
        setValue('');
    };

    return (
        <Box sx={{ width: '100%', height: "150px", border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
            {field.value ? (
                <>
                    <img src={field.value} alt="uploaded" style={{ position: 'absolute', width: '100%', height: '100%', objectFit: 'cover' }} />
                    <IconButton
                        onClick={handleRemoveImage}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            color: "white",
                            backgroundColor: 'primary.main',
                            '&:hover': {
                                backgroundColor: 'primary.light',
                            }
                        }}
                    >
                        <ClearIcon />
                    </IconButton>
                </>
            ) : (
                <Box variant="outlined" component="label" htmlFor={`file-upload-${name}`} sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer" }}>
                    <input
                        id={`file-upload-${name}`}
                        type="file"
                        onChange={handleImageChange}
                        hidden
                        {...props}
                    />
                    <Typography>{imageUploading ? 'Uploading...' : 'Upload Image'}</Typography>
                </Box>
            )}
            {meta.error && meta.touched && (
                <Typography color="error" variant="caption" sx={{ position: 'absolute', bottom: -20 }}>
                    {meta.error}
                </Typography>
            )}
        </Box>
    );
};

export default CustomUploadImage;