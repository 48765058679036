import { Box, Container, Paper, Typography } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import { FieldInput } from './FiledInput'; 
import { useTranslation } from 'react-i18next';
import { useReferralSubmit } from '../hooks/referralSubmit';
import BaseForm from '../../../formik/BaseForm';
import { useSignUpReferralValidation } from '../../../validation/signUpWithReferralValidationSchema';
export const SignUpWithReferral = () => {
    const { referralSubmit, loading } = useReferralSubmit();
    const { signUpWithReferralValidationSchema } = useSignUpReferralValidation()
    const { t } = useTranslation("referralSignup");
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get('referral'); // get referral code from url
    const theme = useTheme();
    const initialValues = {
        username: "",
        email: "",
        password: "",
        repeatPassword: "",
        referral: referralCode || ""
    }

    return (
        <Box sx={{ pt: 8 }}>
            <Typography variant="h2" align="center" sx={{ color: "primary.main", fontWeight: "bold", mb: 8, fontSize: ["35px", "60px"] }}>
                {t("title")}
            </Typography>

            <Box sx={{ backgroundColor: "text.main", py: "100px" }}>
                <Container maxWidth="sm">
                    <Paper elevation={3} sx={{ p: 4, borderRadius: 2, backgroundColor: "secondary.main", px: 5 }}>
                        <Typography variant="h4" align="center" sx={{ color: "white", fontWeight: "bold", textTransform: "capitalize" }}>
                            {t("subtitle.1")}
                            <br />
                            {t("subtitle.2")}
                        </Typography>
                        <Typography variant="body2" align="center" sx={{ color: "white", my: 2 }}>
                            {t("subtitle.3")}
                        </Typography>
                        <BaseForm
                            initialValues={initialValues}
                            validationSchema={signUpWithReferralValidationSchema}
                            onSubmit={referralSubmit}
                        >
                            <FieldInput loading={loading} />
                        </BaseForm>

                        <Typography variant="body2" sx={{ textAlign: "center", mt: 2, color: "white" }}>
                            {t("agreement.1")}
                            <Link to="/" style={{ color: "white", paddingLeft: "5px" }}>{t("agreement.2")}</Link>.
                        </Typography>

                        <Typography variant="body2" sx={{ textAlign: "center", mt: 3, color: "white" }}>
                            {t("haveAccount.1")}{" "}
                            <Link
                                to="/login"
                                style={{ color: theme.palette.primary.main, marginLeft: "3px" }}
                            >
                                {t("haveAccount.2")}
                            </Link>
                        </Typography>
                    </Paper>
                </Container>
            </Box>
        </Box>
    )
}