import NotificationList from "../NotificationList";

export const Comments = () => {
  return (
    <NotificationList
      type="comment"
      limit={10}
      noMoreText="comment.noMore"
      fetchUrl="/notifications"
    />
  );
};
