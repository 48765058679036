import { Box, Button, Container, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
export const EarnMoney = () => {
  const { t } = useTranslation('about');
 const navigate = useNavigate();
  // go to rent page
  const GoToRentPage = () => {
    // get token from local storage
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/add-new-product");
    } else {
      navigate("/login");
    }
  }
  return (
    <Box sx={{ backgroundColor: "secondary.main", py: 5 }}>
      <Container>
        <Stack spacing={2} justifyContent="center" alignItems="center" sx={{ textAlign: "center" }}>
          <Typography variant="h6" component="h3" sx={{ color: "text.main" }}>
          {t("earnMoney.title")}
          </Typography>
          <Button variant="contained" sx={{ my: "10px", borderRadius: "20px", width: "fit-content", textTransform: "capitalize", px: 5, py: 1, fontSize: "20px" }} onClick={GoToRentPage}>
          {t("earnMoney.button")}
          </Button>
        </Stack>
      </Container>
    </Box>
  )
}
