import one from "../../..//assets/images/1.webp"
import two from "../../..//assets/images/2.webp"
import three from "../../..//assets/images/3.webp"

export const ForRenter = [
    {
        imgUrl: one,
        title: "Howitworks.stepsForRenters.0.title1",
        description: "Howitworks.stepsForRenters.0.description1",
    },
    {
        imgUrl: two,
        title: "Howitworks.stepsForRenters.1.title2",
        description: "Howitworks.stepsForRenters.1.description2",
    },
    {
        imgUrl: three,
        title: "Howitworks.stepsForRenters.2.title3",
        description: "Howitworks.stepsForRenters.2.description3",
    },
]

export const ForOwner = [
    {
        imgUrl: one,
        title: "Howitworks.stepsForWoners.0.title1",
        description: "Howitworks.stepsForWoners.0.description1",
    },
    {
        imgUrl: two,
        title: "Howitworks.stepsForWoners.1.title2",
        description: "Howitworks.stepsForWoners.1.descriptio2",
    },
    {
        imgUrl: three,
        title: "Howitworks.stepsForWoners.2.title3",
        description: "Howitworks.stepsForWoners.2.description3",
    },
]