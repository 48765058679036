import { useParams } from "react-router-dom";
import axiosInstance from "../../../axios/axiosInstance"
import { useShowAlert } from "../../../hooks/useShowAlert";
export const useUpdateProduct = () => {
    const { id } = useParams();
    const { showAlert } = useShowAlert();
    const handleSubmit = (values) => {
       // convert available to boolean
        values.available = values.available === "Open";
        axiosInstance
            .put(
                `/products/${id}`,
                values
            )
            .then((res) => {
                showAlert("success", res.data.message);

            })
            .catch((err) => {
                showAlert("error", err.response.data.error);

            });

    };
    return { handleSubmit };
}