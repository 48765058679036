import { Box, Container, Grid, Typography } from "@mui/material"
import { UserAvatar,  } from "../components/Settings/UserAvatar"
import { LogOut } from "../components/Settings/LogOut"
import { ListSettings } from "../components/Settings/ListSettings"
import { useTranslation } from "react-i18next"
export const Settings = () => {
    const { t } = useTranslation("settings")
    return (
        <Box py={8}>
            <Container maxWidth="lg">
                <Typography variant="h4" align="center" sx={{ color: "primary.main", fontWeight: "bold" }}>
                    {t("title")}
                </Typography>
                <Grid container spacing={8} py={5}>
                    <Grid item xs={12} md={6}>
                        <UserAvatar />
                        <LogOut/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <ListSettings/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
