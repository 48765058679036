import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
export const SkeletonLoadingProducts = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", height: 300, margin: 1 }}>
      <Skeleton variant="rectangular" sx={{ height: "200px", width: "100%" }} />
      <Skeleton variant="text" sx={{ width: "100%" }} />
      <Skeleton variant="text" sx={{ width: "100%" }} />
      <Skeleton variant="text" sx={{ width: "100%" }} />
    </Box>
  );
}