import { Box, } from "@mui/material";
import { useSelector } from "react-redux";
import { ProductsList } from "./ProductList";
import { ProductsListByCategories } from "./ProductsListByCategories";
import { useEffect, useState } from "react";
const ProductsListContainer = () => {
  const filterKeys = useSelector((state) => state.filter.filterKeys);
  const [renderFilter, setRenderFilter] = useState(false);
  const apiProductsUrl =
    "/products";
  const apiFilterUrl =
    "/products/search";
  useEffect(() => {
    // Check if any key from category, price, avilapility, or condation has changed
    if (
      filterKeys.priceMin ||
      filterKeys.priceMax ||
      filterKeys.condition ||
      filterKeys.name||
      filterKeys.startDate||
      filterKeys.endDate ||
      filterKeys.location
    ) {
      // Render the ProductsListByCategories component when any key changes
      setRenderFilter(true);
    }
   
  }, [ filterKeys]);

  return (
    <Box sx={{ py: 5, position: "relative" }}>
      {renderFilter || filterKeys.category ? (
        <ProductsListByCategories
          activeFilter={filterKeys}
          apiFilterUrl={apiFilterUrl}
        />
      ) : (
        <ProductsList
          apiProductsUrl={apiProductsUrl}
        />
      )}
    </Box>
  );
};
export default ProductsListContainer;