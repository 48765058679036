import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import ProductDetails from "../../../assets/images/product-detailss.webp"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
export const StartRent = () => {
    const { t } = useTranslation("home")
    const navigat = useNavigate()
    // GO TO List Items page 
    const GoListItems = () => {
        navigat("/shop")
    }
    return (
        <Box>
            <Container >
                <Grid container spacing={5} alignItems="center" >
                    <Grid item xs={12} sm={6} >
                        <Box sx={{ width: '100%', height: "100%" }}>
                            <img src={ProductDetails} alt="Earning" loading='lazy' style={{ width: '100%', height: "100%" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Stack spacing={2} my={5}  >
                            <Typography variant="h6" sx={{ color: "primary.main", fontWeight: "bold", fontFamily: "Poppins-Bold" }}>
                                {t("start-borrowing.title")}
                            </Typography>
                            <Typography variant="body1" sx={{ color: "#003846", }}>
                                {t("start-borrowing.description.1")}
                            </Typography>
                            <Typography variant="body1" sx={{ color: "#003846", }}>
                                {t("start-borrowing.description.2")}
                            </Typography>

                            <Button variant="contained" sx={{ my: "50px", borderRadius: "20px", width: " fit-content", textTransform: "capitalize", px: 5 }} onClick={GoListItems}>
                                {t("start-borrowing.get-started")}
                            </Button>
                        </Stack>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}
