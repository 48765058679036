import axiosInstance from "../../../axios/axiosInstance";
import { useShowAlert } from "../../../hooks/useShowAlert";
import useSendTokenToContact from "./SendToken";
import { useState } from "react";
import { getUser } from "../../../redux/slices/AuthUser";
import { useSelector } from "react-redux";
export const useReferralSubmit = () => {
    const AuthUser = useSelector(getUser); //get user from redux
    const [loading, setLoading] = useState(false);
    const { showAlert } = useShowAlert();
    const { sendTokenToEmail } = useSendTokenToContact();
    const referralSubmit = (values) => {
        // if the user had already login in-app, it shows a message you should log out before creating a new login 
        if (AuthUser?.user) {
            showAlert("error", "you are already login in the app and should log out before making a new login")
            return 0;
        }
        setLoading(true);
        // Remove repeatPassword from values object
        // eslint-disable-next-line no-unused-vars
        const { repeatPassword, ...payload } = values;
        axiosInstance
            .post("/user/signup", payload)
            .then(() => {
                sendTokenToEmail(values.email)
            })
            .catch((err) => {
                if (err) {
                    showAlert("error", err.response.data.error);
                }
            }).finally(() => {
                setLoading(false);
            });
    };

    return { referralSubmit, loading };
};
