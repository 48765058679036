import { Skeleton, Stack } from "@mui/material"
export const UserAvatarSkeleton = () => {
    return (
        <Stack direction="row" sx={{ width: "100%" }}>
            <Skeleton variant="circular" sx={{ width: 80, height: 80 }} />
            <Stack sx={{ pl: 2, mt: 2, width: "100%" }}>
                <Skeleton variant="text" sx={{ width: "100%", height: 25 }} />

                <Skeleton variant="text" sx={{ width: "100%", height: 25, mt: 1 }} />
            </Stack>
        </Stack>


    )
}
