import { Stack, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import useLogout from '../../hooks/useLogOut';
import { useTranslation } from 'react-i18next';
export const LogOut = () => {
    const { t } = useTranslation("settings");
    const logout = useLogout();
    const handleLogout = () => {
        logout("You have successfully logged out.", true);
    };
    return (
        <Stack direction="row" justifyContent="space-between" sx={{ backgroundColor: "rgb(247,247,247)", mt: 5, p: 2, cursor: "pointer" }} onClick={handleLogout}>
            <Typography variant="body1" align="center" sx={{ color: "primary.main", fontWeight: "bold" }}>
                {t("log-out")}
            </Typography>
            <KeyboardArrowRightIcon sx={{ color: "primary.main", fontSize: "30px", cursor: "pointer" }} />

        </Stack>
    )
}
