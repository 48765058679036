import { Skeleton, Stack } from '@mui/material'
export const UserProfileInfoSkeleton = () => {
  return (
    <Stack spacing={3} sx={{width:"100%"}}> 
    <Skeleton variant="rectangular" width={"100%"} height={40} />
    <Skeleton variant="rectangular" width={"100%"} height={40} />
    <Skeleton variant="rectangular" width={"100%"} height={40} />
    <Skeleton variant="rectangular" width={"100%"} height={40} />
    </Stack>
  )
}
