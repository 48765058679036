import { Container, Stack, } from '@mui/material'
import { useTranslation } from 'react-i18next'
import BaseForm from '../../../formik/BaseForm'
import { useValidationForSendMailResetPassword } from '../../../validation/validationForSendMailResetPassword'
import CustomFormikField from '../../../formik/CustomFormikField'
import SubmitButton from '../../../formik/SubmitButton'
import { useSendMailResetPassword } from '../hooks/sendMailResetPassword'
export const SendEmail = () => {
    const { SendToken, loading } = useSendMailResetPassword()
    const { validationSchema } = useValidationForSendMailResetPassword()
    const { t } = useTranslation("resetPassword")
    return (
        <Container maxWidth="sm">

            <BaseForm
                initialValues={{ email: "" }}
                onSubmit={SendToken}
                validationSchema={validationSchema}
            >
                <Stack spacing={2} sx={{ mt: 4, backgroundColor: "secondary.main", p: 4, borderRadius: 2 }}>
                    <CustomFormikField name="email" placeholder={t("enterEmail.label")} type="email" />
                    <SubmitButton disabled={loading} >
                        {t("enterEmail.button")}
                    </SubmitButton>
                </Stack>
            </BaseForm>

        </Container>


    )
}