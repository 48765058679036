import referrals1 from '../../../assets/images/referrals-1.png';
import referrals2 from '../../../assets/images/referrals-2.png';
import referrals3 from '../../../assets/images/referrals-3.png';
export const List = [
    {
        img: referrals1,
        title: "Referrals.steps.step1.title",
        description: "Referrals.steps.step1.description"
    },
    {
        img: referrals2,
        title: "Referrals.steps.step2.title",
        description: "Referrals.steps.step2.description"
    },
    {
        img: referrals3,
        title: "Referrals.steps.step3.title",
        description: "Referrals.steps.step3.description"
    }

]