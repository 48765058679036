import { Box, Button, Container, Grid, Paper, Typography, } from "@mui/material";
import { useGetCategories } from "../../../hooks/useGetCategories";
import { useDispatch } from "react-redux";
import { setActiveCategory } from "../../../redux/slices/filterSlice";
import { useNavigate } from "react-router-dom";
import { Search } from "./Search";
import { CategoriesSkeleton } from "../../general/loading/CategoriesSkeleton";
import { useTranslation } from "react-i18next";

export const CategoriesList = () => {
  const { t } = useTranslation("home");
  const {categories, loading} = useGetCategories();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // set active category in redux store when clicked on a category and redirect to shop page
  const handleItemClick = (category) => {
    dispatch(setActiveCategory(category));
    navigate("/shop");
  };
  // handle navigation to shop page
  const handleNavigation = () => {
    navigate("/categories");
  };
 
  return (
    <Box sx={{ py: 8 }}>
      <Container>
      <Search />
        <Grid container spacing={5} py={5}>
          {
            loading ?
              ["", "", "", "", "", "",  ].map((item, index) => (
                <Grid item xs={12} sm={6} md={4} key={index} >
                 <CategoriesSkeleton/>
                </Grid>
              )

              ) : categories.slice(0, 6).map((category) => (
                <Grid item xs={12} sm={6} md={4} key={category._id} onClick={() => handleItemClick(category)}>
                  <Paper sx={{ cursor: "pointer", color: "secondary.main", textAlign: "center", borderRadius: "20px" }}>
                  <img src={category.picture} alt={category.name} style={{ width: "100%", height: "200px", objectFit: "cover", borderRadius: "20px 20px 0px 0px" }} />
                  <Typography variant="body1" sx={{py:2,fontWeight:"bold",fontFamily:"Poppins-Bold"}}>{category.name}</Typography>
                    
                  </Paper>
                </Grid>
              ))
          }
        </Grid>
        <Button
        variant="contained"
        sx={{
          display: "block",
          margin: "auto",
          my: "50px",
          borderRadius: "20px",
        }}
        onClick={handleNavigation}
      >
        {t("categories.explore-all")}
      </Button>
      </Container>
    </Box>
  )
}
